import { Row, Col } from "react-bootstrap";
import { getCookie } from "typescript-cookie";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { Orion } from "@tailflow/laravel-orion/lib/orion";
import NavBarLayout from "./NavBarLayout";
import axios, { AxiosInstance } from "axios";
import { LOGOUT } from "../Routes/AppRoutes";
import fetchPermissions from "../Util/FetchPermissions";

function Layout() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const token = getCookie("authToken");

  if (!token) {
    return <Navigate to="/login" />;
  }

  const createAxiosInstance: any = (): AxiosInstance => {
    const instance = axios.create();

    instance.interceptors.request.use(
      (config) => {
        const token = getCookie("authToken");
        config.headers["Authorization"] = `Bearer ${
          token ? token.replace(/"/g, "") : ""
        }`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          navigate(LOGOUT);
        } else if (error.response && error.response.status === 403) {
          fetchPermissions(); // Call fetchPermissions after successful login
          navigate("/not-access");
        }
        return Promise.reject(error);
      },
    );

    return instance;
  };

  Orion.makeHttpClientUsing(createAxiosInstance);
  Orion.init(apiUrl);

  return (
    <>
      <NavBarLayout />
      {/* <Row className=""> */}
      <Row className="mx-4 mt-4">
        <Col md={12}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
}

export default Layout;
