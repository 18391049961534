import React, { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/esm/ListGroup";
import { setToast } from "../../../Util/Toast";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import AddUpdateModel from "./Modal";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { CounterPartyAddress } from "../../../Model/BuyersAndSuppliers/CounterPartyAddress/CounterPartyAddress";
import { Country } from "../../../Model/Setting/Country";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../Util/OrionList";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import {
  DELETE_COUNTER_PARTY_ADDRESS,
  RESTORE_COUNTER_PARTY_ADDRESS,
  UPDATE_COUNTER_PARTY_ADDRESS,
} from "../../../Util/PermissionList";
import CustomSkeleton from "../../../Components/CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";

interface AddressProps {
  id: any;
  handleOpen: () => void;
  show: boolean;
  handleClose: () => void;
  addressType: string;
  changeWarningStatus?: (
    type: string,
    fieldName: string,
    message: string,
  ) => void;
  isArchive?: boolean;
  permissions?: any;
}

const Address: React.FC<AddressProps> = ({
  id,
  handleOpen,
  show,
  handleClose,
  addressType,
  changeWarningStatus,
  isArchive,
  permissions,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [deliveryFormData, setDeliveryFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [limit, setLimit] = useState(20);
  const [page] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const [sortField] = useState<string>("id");
  const [sortOrder] = useState<string>("desc");
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [updateId, setUpdateId] = useState<string | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [showMoreStates, setShowMoreStates] = useState<any>({});
  const [includeWith] = useState(["country"]);

  const toggleShowMore = (index: any) => {
    setShowMoreStates((prevState: any) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  /// Company Address
  const deliveryFormFields = [
    {
      id: "inputAddressName",
      label: "Address Name*",
      type: "text",
      col: 12,
      name: "address_name",
      placeholder: "Enter Your Address Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Name is required",
        },
      ],
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 6,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is required",
        },
      ],
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 6,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "Pin Code*",
      type: "text",
      col: 6,
      name: "zip_code",
      placeholder: "Enter Your Pin Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Pin Code is required",
        },
      ],
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 6,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is required",
        },
      ],
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 6,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is required",
        },
      ],
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
    },
    {
      id: "inputEmail",
      label: "Email",
      type: "text",
      col: 6,
      name: "email",
      placeholder: "Enter Your Email",
      description:
        "Provide the email address associated with the company for communication.",
    },
    {
      id: "inputPhoneNumber",
      label: "Phone Number",
      type: "phone_no",
      col: 6,
      name: "phone_no",
      placeholder: "Enter Your Phone Number",
    },
    {
      id: "inputFaxNumber",
      label: "Fax Number",
      type: "text",
      col: 6,
      name: "fax_no",
      placeholder: "Enter Your Fax Number",
    },
  ];
  useEffect(() => {
    if (changeWarningStatus) {
      const type = "warning";
      const fieldName =
        addressType === "billing" ? "Billing Address" : "Delivery Address";
      const message = `Ensure ${
        addressType === "billing" ? "Billing Address" : "Delivery Address"
      } are filled in for document creation.`;
      if ((data?.length < 0 || data === undefined) && !loading && !isArchive) {
        changeWarningStatus(type, fieldName, message);
      } else if ((data?.length > 0 || data !== undefined) && !isArchive) {
        changeWarningStatus(type, fieldName, "");
      }
    }
  }, [loading, data, isArchive, addressType]); // eslint-disable-line

  const handleDeliveryAddressCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const {
      address_name,
      address_line1,
      address_line2,
      city,
      state,
      zip_code,
      country_id,
      email,
      phone_no,
      fax_no,
    } = formData;

    const neededData: any = {
      address_name,
      address_line1,
      ...(address_line2 !== undefined ? { address_line2 } : {}),
      ...(email !== undefined ? { email } : {}),
      ...(phone_no !== undefined ? { phone_no } : {}),
      ...(fax_no !== undefined ? { fax_no } : {}),
      city,
      state,
      zip_code,
      country_id,
    };
    const countryIdNumber = parseInt(neededData.country_id.value);
    neededData.country_id = countryIdNumber;
    neededData.address_type = addressType;
    neededData.counter_party_company_id = id;

    try {
      setFormError(null);
      if (formData.id) {
        await CounterPartyAddress.$query().update(formData.id, neededData);
        setToast("success", ` Successfully Updated`);
        fetchCounterPartyAddData();
        setDeliveryFormData([]);
        handleClose();
      } else {
        await CounterPartyAddress.$query().store(neededData);
        fetchCounterPartyAddData();
        setDeliveryFormData([]);
        handleClose();
        setToast("success", ` Successfully Added`);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  // @ts-ignore
  const fetchCounterPartyAddData = async () => {
    let inputValues: any = {
      address_type: { value: addressType },
    };

    if (id) {
      inputValues["counter_party_company_id"] = { value: id };
    }
    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new CounterPartyAddress(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setToast("error", error.response.data.message);
    }
  };

  useEffect(() => {
    fetchCounterPartyAddData();
  }, [id, isArchive, limit]); // eslint-disable-line

  const fetchMoreItems = () => {
    setLimit(limit + 10);
  };

  if (loading) {
    return (
      <CustomSkeleton
        height={198}
        customOuterStyle={{ padding: "0" }}
        customInnerStyle={{ borderRadius: "0 0 9px 9px" }}
        containerClassName={"d-grid"}
      />
    );
  }

  return (
    <>
      <div
        style={{
          maxHeight: "180px",
          overflowY: "auto",
          fontSize: "14px",
          padding: "10px 20px 10px",
        }}
      >
        <ListGroup as="ol" numbered>
          {data?.map((item: any, index: number) => (
            <ListGroup.Item
              key={index}
              as="li"
              className="d-flex justify-content-between align-items-start border-0 p-0 mb-2"
            >
              <div className="ms-2 me-auto">
                <div className="">
                  <p className="mb-0 fw-bold">{item.address_name} </p>
                  <span className="mb-0">
                    {item.address_line1}, {item.address_line2 + ", "}
                  </span>
                  <span className="mb-0 me-2">
                    {item.city}, {item.state}, {item.country?.name} -
                    {item.zip_code}
                  </span>
                  {(item.email || item.phone_no || item.fax_no) && (
                    <span
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleShowMore(item.id)}
                    >
                      {showMoreStates[item.id] ? "" : "Expand Details"}
                    </span>
                  )}
                  {showMoreStates[item.id] && (
                    <div className="mb-0">
                      {item.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: 500 }}>Email: </span>
                          {item.email}
                        </p>
                      )}
                      {item.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: 500 }}>
                            Phone Number:{" "}
                          </span>
                          {item.phone_no}
                        </p>
                      )}
                      {item.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: 500 }}>Fax Number: </span>
                          {item.fax_no}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {!isArchive ? (
                <>
                  {permissions[UPDATE_COUNTER_PARTY_ADDRESS] && (
                    <CustomEditButton
                      onClick={async () => {
                        let deliveryId = item.id;
                        setUpdateId(deliveryId);
                        const getDeliveryAddressFormData = async () => {
                          const item = await CounterPartyAddress.$query()
                            .with(["country"])
                            .find(deliveryId);
                          return item.$attributes;
                        };

                        try {
                          const itemData = await getDeliveryAddressFormData();
                          let data = itemData;
                          if (data["country_id"]) {
                            data["country_id"] = {
                              label: itemData.country["name"],
                              value: itemData.country["id"],
                            };
                          }

                          if (data["address_type"]) {
                            data["address_type"] = {
                              label: CapitalizeAndRemoveUnderscore(
                                itemData.address_type,
                              ),
                              value: itemData.address_type,
                            };
                          }
                          setDeliveryFormData(data);
                          handleOpen();
                        } catch (error: any) {
                          setToast("error", error.response.data.message);
                        }
                      }}
                      id={0}
                    />
                  )}
                  {permissions[DELETE_COUNTER_PARTY_ADDRESS] && (
                    <CustomArchiveButton
                      id={item.id}
                      onClick={handleRowArchiveButtonClick}
                    />
                  )}
                </>
              ) : (
                <>
                  {permissions[RESTORE_COUNTER_PARTY_ADDRESS] && (
                    <CustomRestoreIconButton
                      id={item.id}
                      onClick={handleRowRestoreButtonClick}
                    />
                  )}
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
        {data?.length !== totalRows && (
          <p
            style={{
              cursor: "pointer",
              textAlign: "end",
              marginBottom: "-1rem",
            }}
            className="text-primary me-3 mt-1"
            onClick={fetchMoreItems}
          >
            Show More...
          </p>
        )}
      </div>
      <AddUpdateModel
        title={
          addressType === "billing" ? "Billing Address" : "Delivery Address"
        }
        show={show}
        handleClose={() => {
          setFormError(null);
          setDeliveryFormData([]);
          handleClose();
        }}
        widget={
          <AddUpdateForm
            formFields={deliveryFormFields}
            model={CounterPartyAddress}
            title={
              addressType === "billing" ? "Billing Address" : "Delivery Address"
            }
            customTitle=" "
            customFormSubmit={handleDeliveryAddressCustomSubmit}
            dynamicFormData={deliveryFormData}
            isBackBtnDisable={true}
            dynamicFormError={formError}
            dynamicId={updateId}
            isNotUpdate={
              Object.keys(deliveryFormData).length > 0 ? false : true
            }
            customSubmitting={customSubmitting}
          />
        }
      />
      <CustomArchiveModal
        title={
          addressType === "billing" ? "Billing Address" : "Delivery Address"
        }
        model={CounterPartyAddress}
        getData={fetchCounterPartyAddData}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={
          addressType === "billing" ? "Billing Address" : "Delivery Address"
        }
        model={CounterPartyAddress}
        getData={fetchCounterPartyAddData}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
    </>
  );
};

export default Address;
