import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

const Alerts = (data: any) => {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    setShowAlert(data.data.length > 0);
  }, [data]);
  return (
    <>
      {showAlert && (
        <div className="w-100 my-2" key={data.alertsType}>
          <Alert variant={data.alertsType}>
            {data.data.map((item: any, index: any) => {
              return (
                <div className="my-1 text-center" key={index}>
                  <span>{item.message}</span>
                </div>
              );
            })}
          </Alert>
        </div>
      )}
    </>
  );
};

export default Alerts;
