import { Model } from "@tailflow/laravel-orion/lib/model";

export class CounterPartyPerson extends Model<{
  counter_party_company_id: number;
  name: any;
  email: any;
  phone_no: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "counter-party-people";
  }
}
