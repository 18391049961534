import { Model } from "@tailflow/laravel-orion/lib/model";

export class Store extends Model<{
  tax_type_id: any;
  country_id: any;
  name: string;
  tax_number: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zipcode: string;
  is_default: boolean;
  created_at: string;
  updated_at: string;
  country?: any;
  tax_type?: any;
}> {
  public $resource(): string {
    return "stores";
  }
}
