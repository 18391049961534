// src/Pages/Reports/Reports.js
import { RouteObject } from "react-router-dom";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PURCHASE,
  ADD_REPORTS,
  DELETE_PURCHASE,
  DELETE_REPORTS,
  RESTORE_PURCHASE,
  RESTORE_REPORTS,
  UPDATE_PURCHASE,
  UPDATE_REPORTS,
  VIEW_PURCHASE,
  VIEW_REPORTS,
} from "../../Util/PermissionList";
import SalesReport from "../../Pages/Reports/SalesReport";
import PurchaseReport from "../../Pages/Reports/PurchaseReport";

export const REPORTS_TITLE = "Reports";

export const REPORTS_SALES = "/reports/sales-report";
export const REPORTS_SALES_TITLE = "Sales Reports";

export const REPORTS_PURCHASE = "/reports/purchase-report";
export const REPORTS_PURCHASE_TITLE = "Purchase Reports";

const Reports: RouteObject[] = [
  {
    path: REPORTS_SALES,
    element: (
      <AuthorizedRoute
        permission={VIEW_REPORTS}
        checkPermisson={{
          addPermission: ADD_REPORTS,
          updatePermission: UPDATE_REPORTS,
          deletePermission: DELETE_REPORTS,
          viewPermission: VIEW_REPORTS,
          restorePermission: RESTORE_REPORTS,
        }}
        element={<SalesReport />}
      />
    ),
  },
  {
    path: REPORTS_PURCHASE,
    element: (
      <AuthorizedRoute
        permission={VIEW_PURCHASE}
        checkPermisson={{
          addPermission: ADD_PURCHASE,
          updatePermission: UPDATE_PURCHASE,
          deletePermission: DELETE_PURCHASE,
          viewPermission: VIEW_PURCHASE,
          restorePermission: RESTORE_PURCHASE,
        }}
        element={<PurchaseReport />}
      />
    ),
  },
];

export default Reports;
