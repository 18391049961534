import { RouteObject } from "react-router-dom";
import CustomViewComponent from "../../Components/CustomViewComponent";

export const CustomView = "/:model/:field/view/:id";

const CustomViewComponentRoute: RouteObject[] = [
  { path: CustomView, element: <CustomViewComponent /> },
];

export default CustomViewComponentRoute;
