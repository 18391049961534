import { Model } from "@tailflow/laravel-orion/lib/model";

export class BankDetail extends Model<{
  bank_name: string;
  account_number: string;
  account_name: string;
  branch: string;
  swift_code: string;
  micr: string;
  address: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "bank-details";
  }
}
