import React, { useEffect, useState } from "react";
import { fetchData } from "../../../Util/OrionList";
import { useLocation, useNavigate } from "react-router-dom";
import { setToast } from "../../../Util/Toast";
import { OrganizationAddress } from "../../../Model/Setting/OrganizationAddress";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  ADDRESS_ADD,
  ADDRESS_ARCHIVE,
  ADDRESS_EDIT,
  ADDRESS_LIST,
  ADDRESS_TITLE,
} from "../../../Routes/Setting/Address";
import CustomButton from "../../../Components/Button/CustomButton";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";
import { InputValues } from "../../../Components/CustomFilter";
import { Card } from "react-bootstrap";

interface AddressItem {
  id: number;
  address_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip_code: string;
  address_type: string;
  phone_no: string;
  country: any;
}

function List({ permissions }: any) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AddressItem[]>([]);
  const [limit, setLimit] = useState(100);
  const navigate = useNavigate();
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === ADDRESS_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);
  const [inputValues] = useState<InputValues>({});
  const [includeWith] = useState(["country"]);

  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(ADDRESS_ARCHIVE);
  };

  useEffect(() => {
    fetchAddress(1);
  }, [limit]); // eslint-disable-line

  useEffect(() => {
    if (initialFetchDone) {
      fetchAddress(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArchive]);

  useEffect(() => {
    setIsArchive(location.pathname === ADDRESS_ARCHIVE);
    setInitialFetchDone(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleGoBack = () => {
    navigate(ADDRESS_LIST);
  };

  const fetchAddress = async (page: number | undefined) => {
    setLoading(true);
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        0,
        navigate,
        new OrganizationAddress(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
      setLimit(limit);
      setLoading(false);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };
  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(ADDRESS_EDIT.replace(":id", id.toString()));
  };

  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h3 className="mx-3">{ADDRESS_TITLE}</h3>
        </Col>
        <Col className="text-end mx-3">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              {permissions.add && (
                <CustomButton
                  label="Add"
                  variant="success"
                  onClick={() => navigate(ADDRESS_ADD)}
                />
              )}
              {permissions.delete && (
                <CustomButton
                  label={`Archive`}
                  variant="danger"
                  onClick={handleArchiveButtonClick}
                  customeClass="ms-2"
                />
              )}
            </>
          )}
        </Col>
      </Row>
      {loading ? (
        <p className="mx-3">Loading...</p>
      ) : (
        <Row>
          <Col md={6} className="mb-3">
            <h5 className="text-center">
              Delivery Addresses {isArchive ? "Archive" : "List"}
            </h5>
            {data
              ?.filter((address) => address.address_type === "delivery")
              ?.map((address, index) => (
                <Card key={address.id} style={{ margin: "1rem" }}>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>{address.address_name}</span>
                    <div>
                      {isArchive ? (
                        permissions.restore && (
                          <CustomRestoreIconButton
                            id={address.id}
                            onClick={handleRowRestoreButtonClick}
                          />
                        )
                      ) : (
                        <>
                          {permissions.update && (
                            <CustomEditButton
                              onClick={handleEdit}
                              id={address.id}
                            />
                          )}
                          {permissions.delete && (
                            <CustomArchiveButton
                              id={address.id}
                              onClick={handleRowArchiveButtonClick}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>{address.address_line1}</Card.Text>
                    <Card.Text>{address.address_line2}</Card.Text>
                    <Card.Text>
                      {address.city}, {address.state} - {address.zip_code}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
          </Col>
          <Col md={6}>
            <h5 className="text-center">
              Billing Addresses {isArchive ? "Archive" : "List"}
            </h5>
            {data
              ?.filter((address) => address.address_type === "billing")
              ?.map((address, index) => (
                <Card key={address.id} style={{ margin: "1rem" }}>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span>{address.address_name}</span>
                    <div>
                      {isArchive ? (
                        permissions.restore && (
                          <CustomRestoreIconButton
                            id={address.id}
                            onClick={handleRowRestoreButtonClick}
                          />
                        )
                      ) : (
                        <>
                          {permissions.update && (
                            <CustomEditButton
                              onClick={handleEdit}
                              id={address.id}
                            />
                          )}
                          {permissions.delete && (
                            <CustomArchiveButton
                              id={address.id}
                              onClick={handleRowArchiveButtonClick}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>{address.address_line1}</Card.Text>
                    <Card.Text>{address.address_line2}</Card.Text>
                    <Card.Text>
                      {address.city}, {address.state} - {address.zip_code}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
          </Col>
          <CustomArchiveModal
            title={ADDRESS_TITLE}
            model={OrganizationAddress}
            getData={fetchAddress}
            ArchiveItemId={ArchiveItemId}
            setArchiveItemId={setArchiveItemId}
            showArchiveModal={showArchiveModal}
            setshowArchiveModal={setshowArchiveModal}
          />
          <CustomRestoreModal
            title={ADDRESS_TITLE}
            model={OrganizationAddress}
            getData={fetchAddress}
            RestoreItemId={restoreItemId}
            showRestoreModal={showRestoreModal}
            setRestoreItemId={setRestoreItemId}
            setshowRestoreModal={setshowRestoreModal}
          />
        </Row>
      )}
    </>
  );
}

export default List;
