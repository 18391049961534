import React from "react";
import { Button } from "react-bootstrap";
import CustomSpinner from "../../Util/Spinner";

interface CustomButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: any;
  variant?: string;
  style?: React.CSSProperties;
  width?: string;
  size?: any;
  type?: "button" | "submit" | "reset" | undefined;
  customeClass?: string;
  customeStyle?: any;
  disableStatus?: any;
  spinnerStatus?: any;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  label,
  variant,
  type,
  width,
  size,
  customeClass,
  customeStyle,
  disableStatus,
  spinnerStatus,
}) => {
  const buttonStyle = {
    height: "37px",
    borderRadius: "0.1",
    width: width,
    backgroundColor: "#1F79B4",
  };
  return (
    <>
      <Button
        variant={variant}
        type={type}
        onClick={onClick}
        style={customeStyle ? customeStyle : !variant ? buttonStyle : {}}
        className={`btn btn-sm ${customeClass} ms-2`}
        size={size && size}
        disabled={type === "submit" && disableStatus}
      >
        {spinnerStatus ? (
          <>
            {label} <CustomSpinner />
          </>
        ) : (
          label
        )}
      </Button>
    </>
  );
};

export default CustomButton;
