import "bootstrap/dist/css/bootstrap.min.css";

import AddUpdateForm from "../../../Components/AddUpdateForm";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import FieldDescription from "../../../Components/FieldDescription";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";
interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 6,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a descriptive name for the unit of measurement. (Example: 'Kilogram(kg)', 'Meter(m)', 'Piece' etc.)",
    },
    {
      id: "inputDescription",
      label: "Description",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter Description",
      description:
        "Provide a brief description outlining the characteristics and usage of the unit of measurement. (Example: 'Weight unit for mass measurement'.)",
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const [dynamicFormFields, setDynamicFormFields] = useState([]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  useEffect(() => {
    DynamicFormFields(formFields, "unit_of_measurement").then(
      (updatedFormFields) => {
        setDynamicFormFields(updatedFormFields);
      },
    );
  }, []); // eslint-disable-line

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={dynamicFormFields}
          model={UnitOfMeasurement}
          title={title}
          listRoute={listRoute}
          entityName={"unit-of-measurements"}
          entityType={"unit_of_measurement"}
          isDynamicFormFields={true}
          isNotUpdate={isNotUpdate}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
