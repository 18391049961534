import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Card, Table } from "react-bootstrap";
import { Projects } from "../../Model/Projects/Projects";
import { DateWithoutTime } from "../../Util/DateFormat";
import { Download } from "react-bootstrap-icons";
import { useApi } from "../../Controller/ApiController";
import { VIEW_PROJECT_MANUFACTURES_QUOTATION_DATA } from "../../Util/PermissionList";
import { setToast } from "../../Util/Toast";
import CustomSkeleton from "../../Components/CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";
import CustomButton from "../../Components/Button/CustomButton";

function ViewProject({ permissions }: any) {
  const { id }: any = useParams();
  const { apiGet }: any = useApi();
  const [project, setProject] = useState<any>(null);
  const [allQuotation, setAllQuotation] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [loadingForQuotationDetails, setLoadingForQuotationDetails] =
    useState(true);
  const [page, setPage] = useState(1);
  const [hideShowMore, setHideShowMore] = useState(false); // To control the visibility of "Show More"

  const navigate = useNavigate();

  const getQuotation = async (id: any, pageNumber: any) => {
    try {
      const item = await apiGet(
        `projects/${id}/quotations-data?include=country,project,projectManufactures,quotation,organization&page=${pageNumber}`,
      );
      item && setLoadingForQuotationDetails(false);
      // Set hideShowMore based on whether there are more pages
      setHideShowMore(item.meta.current_page >= item.meta.last_page);
      return item.data;
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  const getProject = async (itemId: any) => {
    try {
      const item = await Projects.$query()
        .with([
          "buyer",
          "productManufactures",
          "products",
          "productManufactures.supplier",
          "organization",
          "salesOwner",
        ])
        .find(itemId);
      return item.$attributes;
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };

  const loadMoreQuotations = async () => {
    const nextPage = page + 1;
    setPage(nextPage);

    const moreQuotations = await getQuotation(id, nextPage);
    setAllQuotation([...allQuotation, ...moreQuotations]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data: any = await getProject(id);
      data && setLoading(false);
      setProject(data);
      const quotationData: any = await getQuotation(id, page);
      setAllQuotation(quotationData);
    };
    fetchData();
    //eslint-disable-next-line
  }, [id]);

  const GoBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h2>View Project</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton label={`Back`} variant="secondary" onClick={GoBack} />
        </div>
      </div>
      <div>
        <Card className="mb-3">
          <Card.Body className="p-0">
            <Card.Header>Project Details</Card.Header>
            {loading ? (
              <CustomSkeleton
                height={192}
                containerClassName={"d-grid"}
                customOuterStyle={{ padding: 0, height: "192px" }}
                customInnerStyle={{ borderRadius: "0 0 0.375rem 0.375rem" }}
              />
            ) : (
              <div className="p-3 d-flex">
                <div style={{ marginRight: "220px" }}>
                  <p>
                    <span style={{ fontWeight: "700" }}> Title: </span>{" "}
                    {project?.title}
                  </p>
                  <p>
                    <span style={{ fontWeight: "700" }}> Description: </span>{" "}
                    {project?.description}
                  </p>
                  <p>
                    <span style={{ fontWeight: "700" }}> Project Value: </span>{" "}
                    {project?.project_value}
                  </p>
                  <p>
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      Expected Closed Date:{" "}
                    </span>{" "}
                    {DateWithoutTime(project?.expected_closed_date)}
                  </p>
                </div>
                <div>
                  <p className="d-flex">
                    <span style={{ fontWeight: "700" }} className="pt-1">
                      Status:{" "}
                    </span>{" "}
                    <span className="ms-2">
                      <h5>
                        <Badge pill style={{ padding: "7px 10px" }}>
                          {project?.project_status &&
                            typeof project.project_status === "string" &&
                            CapitalizeAndRemoveUnderscore(
                              project.project_status,
                            )}
                        </Badge>
                      </h5>
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: "700" }}>Buyer/Supplier: </span>{" "}
                    {project?.buyer.company_name}
                  </p>
                  <p>
                    <span style={{ fontWeight: "700" }}>Sales Owner: </span>{" "}
                    {project?.sales_owner.first_name +
                      " " +
                      project?.sales_owner.last_name}
                  </p>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>

        {/* Products Details */}
        <Card className="mb-3">
          <Card.Body className="p-0">
            <Card.Header>Products Details</Card.Header>
            {loading ? (
              <CustomSkeleton
                height={100}
                containerClassName={"d-grid"}
                customOuterStyle={{ padding: 0, height: "100px" }}
                customInnerStyle={{ borderRadius: "0 0 0.375rem 0.375rem" }}
              />
            ) : (
              <Table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Ingredients</th>
                    <th>Dosage Form</th>
                    <th>Filling</th>
                    <th>Type of Filling</th>
                    <th>Concentration</th>
                    <th>Packaging Material</th>
                  </tr>
                </thead>
                <tbody>
                  {project?.products.map((product: any, index: number) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{product.ingredients}</td>
                      <td>{product.dosage_form}</td>
                      <td>{product.filling}</td>
                      <td>{product.type_of_filling}</td>
                      <td>{product.concentration}</td>
                      <td>
                        <a
                          href={product.packaging_material}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Download style={{ fill: "green" }} />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>

        {/* Manufacturers Details */}
        <Card className="mb-3">
          <Card.Body className="p-0">
            <Card.Header>Manufacturers Details</Card.Header>
            {loading ? (
              <CustomSkeleton
                height={100}
                containerClassName={"d-grid"}
                customOuterStyle={{ padding: 0, height: "100px" }}
                customInnerStyle={{ borderRadius: "0 0 0.375rem 0.375rem" }}
              />
            ) : (
              <Table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Supplier</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {project?.product_manufactures.map(
                    (manufacture: any, index: number) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{manufacture.supplier.company_name}</td>
                        <td>
                          {manufacture.project_manufactures_status
                            .split("_")
                            .map(
                              (word: any) =>
                                word.charAt(0).toUpperCase() + word.slice(1),
                            )
                            .join(" ")}
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
        {permissions[VIEW_PROJECT_MANUFACTURES_QUOTATION_DATA] && (
          <Card className="mb-5">
            <Card.Body className="p-0">
              <Card.Header>View All Quotation Details</Card.Header>
              {loadingForQuotationDetails ? (
                <CustomSkeleton
                  height={100}
                  containerClassName={"d-grid"}
                  customOuterStyle={{ padding: 0, height: "100px" }}
                  customInnerStyle={{ borderRadius: "0 0 0.375rem 0.375rem" }}
                />
              ) : (
                <>
                  <Table className="table table-borderless w-max-content mb-2 bg-transparent p-3">
                    <thead className="bg-transparent">
                      <tr className="text-center">
                        <th>#</th>
                        <th>Company Name</th>
                        <th>Company Country</th>
                        <th>Pack Size</th>
                        <th>MOQ</th>
                        <th>Price</th>
                        <th>Lead Time</th>
                        <th>Formula</th>
                      </tr>
                    </thead>
                    <tbody className="bg-transparent">
                      {allQuotation.length > 0 ? (
                        allQuotation.map((quotation: any, index: any) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <input
                                style={{
                                  border: 0,
                                  borderBottom: "1px solid grey",
                                }}
                                type="text"
                                value={quotation.manuf_company_name}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                style={{
                                  border: 0,
                                  borderBottom: "1px solid grey",
                                }}
                                type="text"
                                value={quotation?.country?.name}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                style={{
                                  border: 0,
                                  borderBottom: "1px solid grey",
                                }}
                                type="text"
                                value={quotation.pack_size}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                style={{
                                  border: 0,
                                  borderBottom: "1px solid grey",
                                }}
                                type="text"
                                value={quotation.MOQ}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                style={{
                                  border: 0,
                                  borderBottom: "1px solid grey",
                                }}
                                type="text"
                                value={quotation.price}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                style={{
                                  border: 0,
                                  borderBottom: "1px solid grey",
                                }}
                                type="text"
                                value={quotation.lead_time}
                                readOnly
                              />
                            </td>
                            <td>
                              <a
                                href={quotation.formula}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Download style={{ fill: "green" }}></Download>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} className="text-center">
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {!hideShowMore && allQuotation.length > 0 && (
                    <p
                      style={{
                        cursor: "pointer",
                        textAlign: "end",
                      }}
                      className="text-primary me-3 mt-1"
                      onClick={loadMoreQuotations}
                    >
                      Show More...
                    </p>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        )}
      </div>
    </div>
  );
}

export default ViewProject;
