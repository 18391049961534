import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LineController,
  BarController,
} from "chart.js";
import { useApi } from "../../Controller/ApiController";
import {
  startOfMonth,
  endOfMonth,
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  subDays,
  startOfYear,
  endOfYear,
} from "date-fns";
import Select from "react-select";
import { Modal, Button, Table, Card } from "react-bootstrap";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";
// import { CustomView } from "../../Routes/Setting/CustomViewComponentRoute";
import { TAX_INVOICE_VIEW } from "../../Routes/SalesAndPurchase/TaxInvoice";
// import "chartjs-adapter-date-fns";
import { setToast } from "../../../src/Util/Toast";

ChartJS.register(
  BarController, // Register BarController
  LineController, // Register LineController
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
);

const SalesChart = () => {
  const [invoiceData, setInvoiceData] = useState<any>(null);
  const [countryChartData, setCountryChartData] = useState<any>(null);
  const [productChartData, setProductChartData] = useState<any>(null);
  const [reportType, setReportType] = useState<any>({
    label: "Day",
    value: "day",
  });
  const [reportDate, setReportDate] = useState<any>({
    label: "Current week",
    value: "current_week",
  });
  const [fromDate, setFromDate] = useState(() =>
    format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
  );
  const [toDate, setToDate] = useState(() =>
    format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
  );
  const [status, setStatus] = useState<any>({ label: "All", value: "" });
  const [modelData, setModelData] = useState<any>([]);
  const [allInvoiceData, setAllInvoiceData] = useState<any>([]);
  const [allProductInvoiceData, setProductAllInvoiceData] = useState<any>([]);
  const [allCountryInvoiceData, setCountryAllInvoiceData] = useState<any>([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState<any>(null);
  const [selectedToDate, setSelectedToDate] = useState<any>(null);

  const { apiGet } = useApi();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        let reportTypeValue = reportType.value === "month" ? "month" : "day";

        let url = `report/sales?is_report=${reportTypeValue}&from_date=${fromDate}&to_date=${toDate}`;
        if (status.value) {
          url += `&status=${status.value}`;
        }

        const item: any = await apiGet(url);
        const allInvoice = item.flatMap((data: any) => data?.sales || []);

        setAllInvoiceData(allInvoice);

        if (reportType.value === "month") {
          const labels = item.map((data: any) => `${data.year}-${data.month}`);
          const values = item.map((data: any) => parseFloat(data.total_amount));

          setInvoiceData({
            labels,
            datasets: [
              {
                type: "bar",
                label: "Total Amount",
                data: values,
                invoiceData: item,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
              {
                type: "line",
                label: "Total Amount Trend",
                data: values,
                invoiceData: item,
                backgroundColor: "rgba(153, 102, 255, 0.2)",
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 2,
                fill: false,
              },
            ],
          });
        } else {
          const labels = item.map((data: any) => data.date);
          const values = item.map((data: any) => parseFloat(data.total_amount));
          setInvoiceData({
            labels,
            datasets: [
              {
                type: "bar",
                label: "Total Amount",
                data: values,
                invoiceData: item,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
              },
              {
                type: "line",
                label: "Total Amount Trend",
                data: values,
                invoiceData: item,
                backgroundColor: "rgba(153, 102, 255, 0.2)",
                borderColor: "rgba(153, 102, 255, 1)",
                borderWidth: 2,
                fill: false,
              },
            ],
          });
        }
      } catch (error) {
        setToast("error", "Error fetching invoice data");
        // setInvoiceData([])
      }
    };
    fetchInvoiceData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, status]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        let url = `report/sales_by_product?from_date=${fromDate}&to_date=${toDate}`;
        if (status.value) {
          url += `&status=${status.value}`;
        }
        const item: any = await apiGet(url);

        let allInvoice: any[] =
          item?.products?.flatMap(
            (data: any) =>
              data?.sales?.map((sale: any) => ({
                ...sale,
                product_name: data.product_name,
              })) || [],
          ) || [];

        setProductAllInvoiceData(allInvoice);

        const labels = item?.products?.map(
          (product: any) => product.product_name,
        );
        const data = item?.products?.map(
          (product: any) => product.total_amount,
        );

        setProductChartData({
          labels,
          datasets: [
            {
              type: "bar",
              label: "Total Amount (Bar)",
              data,
              productData: item,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              borderColor: "rgba(153, 102, 255, 1)",
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Total Amount Trend (Line)",
              data,
              productData: item,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 2,
              fill: false,
            },
          ],
        });
      } catch (error) {
        setToast("error", "Error fetching product data");
        // setProductChartData([])
      }
    };
    fetchProductData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, status]);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        let url = `report/sales_by_country?from_date=${fromDate}&to_date=${toDate}`;
        if (status.value) {
          url += `&status=${status.value}`;
        }
        const item: any = await apiGet(url);

        let allInvoice: any[] =
          item?.countries?.flatMap(
            (data: any) =>
              data?.sales?.map((sale: any) => ({
                ...sale,
                country_name: data.name,
              })) || [],
          ) || [];

        setCountryAllInvoiceData(allInvoice);

        const labels = item?.countries?.map((country: any) => country.name);
        const data = item?.countries?.map(
          (country: any) => country.total_amount,
        );

        setCountryChartData({
          labels,
          datasets: [
            {
              type: "bar",
              label: "Total Amount (Bar)",
              data,
              countryData: item,
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              borderColor: "rgba(153, 102, 255, 1)",
              borderWidth: 1,
            },
            {
              type: "line",
              label: "Total Amount Trend (Line)",
              data,
              countryData: item,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 2,
              fill: false,
            },
          ],
        });
      } catch (error) {
        setToast("error", "Error fetching country data");
      }
    };
    fetchCountryData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, status]);

  // ... (rest of your component remains the same)

  const handleChartClick = (
    _event: any,
    elements: any[],
    chartType: string,
  ) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      if (chartType === "invoice") {
        let data = invoiceData.datasets[0].invoiceData[index].sales;
        setModelData({ data, type: "invoice" });
      } else if (chartType === "country") {
        let data =
          countryChartData.datasets[0].countryData.countries[index].sales;
        setModelData({ data, type: "country" });
      } else if (chartType === "product") {
        let productInvoiceData: any[] = [];
        productChartData.datasets[0].productData.products[index].sales.forEach(
          (data: any) => {
            // Calculate product_total_amount for matched products_id
            let product_total_amount = data.items.reduce(
              (sum: number, item: any) => {
                if (
                  item.products_id ===
                  productChartData.datasets[0].productData.products[index].id
                ) {
                  const totalPrice = parseFloat(item.total_price);
                  const totalTax = parseFloat(item.total_tax) || 0;
                  return sum + (totalPrice + totalTax);
                }
                return sum;
              },
              0,
            );

            data.product_total_amount = product_total_amount;

            productInvoiceData.push(data);
          },
        );
        setModelData({ data: productInvoiceData, type: "product" });
      }
      setModalShow(true);
    }
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const navigateToViewPage: any = (id: any) => {
    let url = TAX_INVOICE_VIEW.replace(":id", id.toString());
    window.open(url, "_blank");
  };

  const convertToCSV = (
    data: any[],
    forProduct?: boolean,
    forCountry?: boolean,
  ) => {
    const headers = [
      "Title",
      "Status",
      "Document Number",
      "Document Date",
      "Buyer",
      "Supplier",
      "Payment Date",
      "Order Confirmation Date",
      "Order Confirmation Number",
      "Currency",
      "Total Tax",
      "Invoice Total Amount",
      "Kind Attention",
      "Delivery Note",
      "Comments",
      "Created Date",
    ];
    if (forProduct) {
      headers.splice(1, 0, "Product Name");
    }

    const rows = data?.map((item) => {
      const row = [
        item.title,
        item.status,
        item.document_number,
        item.document_date,
        item.buyer_billing_address?.counter_party_company?.company_name || "",
        item.supplier_billing_address?.organization?.company_name || "",
        item.payment_date,
        item.oc_date,
        item.oc_number,
        item.currency.symbol,
        item.total_tax,
        item.total_amount,
        item.kind_attention,
        item.delivery_note,
        item.comments,
        item.created_at,
      ];

      if (forProduct) {
        row.splice(1, 0, item.product_name || ""); // assuming item.product_name holds the product name
      }
      if (forCountry) {
        row.splice(1, 0, item.country_name || ""); // assuming item.product_name holds the product name
      }

      return row;
    });
    const csvContent = [headers, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    // Add BOM to the beginning of the CSV content
    const bom = "\uFEFF";
    const csvContentWithBOM = bom + csvContent;

    return csvContentWithBOM;
  };

  const handleExportCSV = (
    allInvoiceData: any,
    forProduct?: boolean,
    forCountry?: boolean,
  ) => {
    const csvData = allInvoiceData
      ? convertToCSV(allInvoiceData, forProduct, forCountry)
      : convertToCSV(modelData.data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    if (forProduct) {
      link.setAttribute(
        "download",
        `product_invoice_data_${new Date().toISOString()}.csv`,
      );
    } else if (forCountry) {
      link.setAttribute(
        "download",
        `country_invoice_data_${new Date().toISOString()}.csv`,
      );
    } else {
      link.setAttribute(
        "download",
        `invoice_data_${new Date().toISOString()}.csv`,
      );
    }
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const calculateDateRange = () => {
      let newFromDate;
      let newToDate = format(new Date(), "yyyy-MM-dd");
      switch (reportDate.value) {
        case "current_week":
          newFromDate = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
          newToDate = format(
            endOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
          break;
        case "last_week":
          newFromDate = format(
            startOfWeek(subWeeks(new Date(), 1)),
            "yyyy-MM-dd",
          );
          newToDate = format(endOfWeek(subWeeks(new Date(), 1)), "yyyy-MM-dd");
          break;
        case "current_month":
          newFromDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
          newToDate = format(endOfMonth(new Date()), "yyyy-MM-dd");
          break;
        case "last_month":
          newFromDate = format(
            startOfMonth(subWeeks(new Date(), 4)),
            "yyyy-MM-dd",
          );
          newToDate = format(endOfMonth(subWeeks(new Date(), 4)), "yyyy-MM-dd");
          break;
        case "last_30_days":
          newFromDate = format(subDays(new Date(), 30), "yyyy-MM-dd");
          break;
        case "current_year":
          newFromDate = format(startOfYear(new Date()), "yyyy-MM-dd");
          newToDate = format(endOfYear(new Date()), "yyyy-MM-dd");
          setReportType({
            label: "Month",
            value: "month",
          });
          break;

        default:
          newFromDate = format(
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
          newToDate = format(
            endOfWeek(new Date(), { weekStartsOn: 1 }),
            "yyyy-MM-dd",
          );
      }
      setFromDate(newFromDate);
      setToDate(newToDate);
    };

    calculateDateRange();
  }, [reportDate]);

  const reportTypeChange = (report_type: any) => {
    setReportType(report_type);
    if (report_type.value === "month") {
      setReportDate({ label: "Current month", value: "current_month" });
    } else {
      setReportDate({ label: "Current week", value: "current_week" });
    }
  };

  const reportDateOptions =
    reportType.value === "day"
      ? [
          { label: "Current week", value: "current_week" },
          { label: "Last week", value: "last_week" },
          { label: "Current month", value: "current_month" },
          { label: "Last month", value: "last_month" },
          { label: "Last 30 days", value: "last_30_days" },
          { label: "Current year", value: "current_year" },
          { label: "Custom Date Range", value: "custom_date_range" },
        ]
      : [
          { label: "Current month", value: "current_month" },
          { label: "Last month", value: "last_month" },
          { label: "Current year", value: "current_year" },
          { label: "Custom Month Range", value: "custom_date_range" },
        ];

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <div style={{ marginRight: "10px" }}>
          <label>Report Type: </label>
          <Select
            options={[
              { label: "Day", value: "day" },
              { label: "Month", value: "month" },
            ]}
            placeholder="Select options..."
            onChange={(selectedOption: any) => reportTypeChange(selectedOption)}
            value={reportType}
            className="p-0"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "120px",
              }),
            }}
          />
        </div>

        <div style={{ marginRight: "10px" }}>
          <label>Report Date: </label>
          <Select
            options={reportDateOptions}
            placeholder="Select options..."
            onChange={(selectedOption: any) => setReportDate(selectedOption)}
            value={reportDate}
            className="p-0"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "120px",
              }),
            }}
          />
        </div>
        {reportDate.value === "custom_date_range" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <label>From: </label>
              <input
                className="form-control"
                type={reportType.value === "day" ? "date" : "month"}
                value={selectedFromDate}
                onChange={(e) => {
                  const selectedValue: any = e.target.value;
                  setSelectedFromDate(selectedValue);
                  if (reportType.value === "month") {
                    const startOfMonthDate = format(
                      startOfMonth(new Date(selectedValue)),
                      "yyyy-MM-dd",
                    );
                    setFromDate(startOfMonthDate);
                  } else {
                    setFromDate(selectedValue);
                  }
                }}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
            <div>
              <label>To: </label>
              <input
                className="form-control"
                type={reportType.value === "day" ? "date" : "month"}
                value={selectedToDate}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                  const selectedValue: any = e.target.value;
                  setSelectedToDate(selectedValue);
                  if (reportType.value === "month") {
                    const endOfMonthDate = format(
                      endOfMonth(new Date(selectedValue)),
                      "yyyy-MM-dd",
                    );
                    setToDate(endOfMonthDate);
                  } else {
                    setToDate(selectedValue);
                  }
                }}
              />
            </div>
          </div>
        )}

        <div>
          <label style={{ marginRight: "10px" }}>Status: </label>
          <Select
            options={[
              { label: "All", value: "" },
              { label: "Draft", value: "draft" },
              { label: "Sent", value: "sent" },
              { label: "Paid", value: "paid" },
              { label: "Partially-Paid", value: "partially-paid" },
              { label: "Refunded", value: "refunded" },
              { label: "Partially-Refunded", value: "partially-refunded" },
            ]}
            placeholder="Select options..."
            onChange={(selectedOption: any) => setStatus(selectedOption)}
            value={status}
            className="p-0"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: "120px",
              }),
            }}
          />
        </div>
      </div>
      {invoiceData ? (
        <div className="d-flex" style={{ height: "67vh" }}>
          <div style={{ width: "50%" }}>
            <h4 className="">Sales By Invoice</h4>
            <Bar
              data={invoiceData}
              options={{
                maintainAspectRatio: false,
                onClick: (event, elements) =>
                  handleChartClick(event, elements, "invoice"),
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: reportType.value === "month" ? "Month" : "Date",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Total Amount",
                    },
                  },
                },
              }}
            />
          </div>

          <div style={{ width: "50%", marginLeft: "25px" }}>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header className="d-flex">
                <h5>Invoice Details</h5>
                {allInvoiceData?.length > 0 && (
                  <div className="ms-2">
                    <Button
                      onClick={() => {
                        handleExportCSV(allInvoiceData);
                      }}
                      variant="primary"
                      size="sm"
                    >
                      Export CSV
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body style={{ overflowY: "scroll" }}>
                <Table striped bordered size={"sm"}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Document Number</th>
                      <th>Document Date</th>
                      <th>Status</th>
                      <th>Buyer</th>
                      <th>Supplier</th>
                      <th>Payment Date</th>
                      {modelData.type === "product" && <th>Product Amount</th>}
                      <th>Invoice Total Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allInvoiceData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={modelData.type === "product" ? 10 : 9}
                          className="text-center"
                          style={{ height: "200px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            There are no records to display
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allInvoiceData?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.document_number}</td>
                          <td>{item.document_date}</td>
                          <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                          <td>
                            {item.buyer_billing_address?.counter_party_company
                              ?.company_name || ""}
                          </td>
                          <td>
                            {item.supplier_billing_address?.organization
                              ?.company_name || ""}
                          </td>
                          <td>{item.payment_date}</td>
                          {modelData.type === "product" && (
                            <td>{item.product_total_amount?.toFixed(2)}</td>
                          )}
                          <td>{item.total_amount}</td>
                          <td
                            onClick={() => {
                              navigateToViewPage(item.id);
                            }}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      {productChartData ? (
        <div className="d-flex" style={{ height: "67vh", marginTop: "40px" }}>
          <div style={{ width: "50%" }}>
            <h4 className="">Sales By Products</h4>
            <Bar
              data={productChartData}
              options={{
                maintainAspectRatio: false,
                onClick: (event, elements) =>
                  handleChartClick(event, elements, "product"),
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Product",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Total Amount",
                    },
                  },
                },
              }}
            />
          </div>
          <div style={{ height: "67vh", width: "50%", marginLeft: "25px" }}>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header className="d-flex">
                <h5>Product Invoice Details</h5>
                {allProductInvoiceData?.length > 0 && (
                  <div className="ms-2">
                    <Button
                      onClick={() => {
                        handleExportCSV(allProductInvoiceData, true);
                      }}
                      variant="primary"
                      size="sm"
                    >
                      Export CSV
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body style={{ overflowY: "scroll" }}>
                <Table striped bordered size={"sm"}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Name</th>
                      <th>Document Number</th>
                      <th>Document Date</th>
                      <th>Status</th>
                      <th>Buyer</th>
                      <th>Supplier</th>
                      <th>Payment Date</th>
                      {modelData.type === "product" && <th>Product Amount</th>}
                      <th>Invoice Total Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allProductInvoiceData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={modelData.type === "product" ? 11 : 10}
                          className="text-center"
                          style={{ height: "200px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            There are no records to display
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allProductInvoiceData?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.product_name}</td>
                          <td>{item.document_number}</td>
                          <td>{item.document_date}</td>
                          <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                          <td>
                            {item.buyer_billing_address?.counter_party_company
                              ?.company_name || ""}
                          </td>
                          <td>
                            {item.supplier_billing_address?.organization
                              ?.company_name || ""}
                          </td>
                          <td>{item.payment_date}</td>
                          {modelData.type === "product" && (
                            <td>{item.product_total_amount?.toFixed(2)}</td>
                          )}
                          <td>{item.total_amount}</td>
                          <td
                            onClick={() => {
                              navigateToViewPage(item.id);
                            }}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      {countryChartData ? (
        <div className="d-flex" style={{ height: "67vh", marginTop: "40px" }}>
          <div style={{ width: "50%" }}>
            <h4 className="">Sales By Country</h4>
            <Bar
              data={countryChartData}
              options={{
                maintainAspectRatio: false,
                onClick: (event, elements) =>
                  handleChartClick(event, elements, "country"),
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "country",
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: "Total Amount",
                    },
                  },
                },
              }}
            />
          </div>
          <div style={{ height: "67vh", width: "50%", marginLeft: "25px" }}>
            <Card style={{ maxHeight: "100%" }}>
              <Card.Header className="d-flex">
                <h5>Country Invoice Details</h5>
                {allCountryInvoiceData?.length > 0 && (
                  <div className="ms-2">
                    <Button
                      onClick={() => {
                        handleExportCSV(allCountryInvoiceData, false, true);
                      }}
                      variant="primary"
                      size="sm"
                    >
                      Export CSV
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body style={{ overflowY: "scroll" }}>
                <Table striped bordered size={"sm"}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Country Name</th>
                      <th>Document Number</th>
                      <th>Document Date</th>
                      <th>Status</th>
                      <th>Buyer</th>
                      <th>Supplier</th>
                      <th>Payment Date</th>
                      <th>Invoice Total Amount</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCountryInvoiceData?.length === 0 ? (
                      <tr>
                        <td
                          colSpan={modelData.type === "country" ? 11 : 10}
                          className="text-center"
                          style={{ height: "200px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            There are no records to display
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allCountryInvoiceData?.map((item: any, index: any) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.country_name}</td>
                          <td>{item.document_number}</td>
                          <td>{item.document_date}</td>
                          <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                          <td>
                            {item.buyer_billing_address?.counter_party_company
                              ?.company_name || ""}
                          </td>
                          <td>
                            {item.supplier_billing_address?.organization
                              ?.company_name || ""}
                          </td>
                          <td>{item.payment_date}</td>
                          <td>{item.total_amount}</td>
                          <td
                            onClick={() => {
                              navigateToViewPage(item.id);
                            }}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            View
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}

      <Modal show={modalShow} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="d-flex">
            {modelData.type === "product"
              ? "Product Invoice Details"
              : modelData.type === "country"
              ? "Country Invoice Details"
              : "Invoice Details"}
            {modelData?.data?.length > 0 && (
              <div className="ms-2">
                <Button
                  onClick={() => {
                    handleExportCSV(
                      null,
                      modelData.type === "product",
                      modelData.type === "country",
                    );
                  }}
                  variant="primary"
                  size="sm"
                >
                  Export CSV
                </Button>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {modelData?.data?.length > 0 ? (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Document Number</th>
                  <th>Document Date</th>
                  <th>Status</th>
                  <th>Buyer</th>
                  <th>Supplier</th>
                  <th>Payment Date</th>
                  {modelData.type === "product" && <th>Product Amount</th>}
                  <th>Invoice Total Amount</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {modelData?.data?.map((item: any, index: any) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.document_number}</td>
                    <td>{item.document_date}</td>
                    <td>{CapitalizeAndRemoveUnderscore(item.status)}</td>
                    <td>
                      {item.buyer_billing_address?.counter_party_company
                        ?.company_name || ""}
                    </td>
                    <td>
                      {item.supplier_billing_address?.organization
                        ?.company_name || ""}
                    </td>
                    <td>{item.payment_date}</td>
                    {modelData.type === "product" && (
                      <td>{item.product_total_amount.toFixed(2)}</td>
                    )}
                    <td>{item.total_amount}</td>
                    <td
                      onClick={() => {
                        navigateToViewPage(item.id);
                      }}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      View
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div
              style={{ textAlign: "center", color: "gray", marginTop: "20px" }}
            >
              <p>No data available</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SalesChart;
