import { RouteObject, useRoutes } from "react-router-dom";
import SignIn from "../Pages/Auth/SignIn";
import Dashboard from "../Pages/Dashboard";
import Layout from "../Components/Layout";
import AuthLayout from "../Components/AuthLayout";
import NotFound from "../Components/NotFound";
import Signout from "../Pages/Auth/SignOut";
import Role from "./Setting/Role";
import Permission from "./Setting/Permission";
import Attribute from "./Setting/Attributes";
import RolePermission from "./Setting/RolePermission";
import TaxType from "./Setting/TaxType";
import Country from "./Setting/Country";
import UserType from "./Setting/UserType";
import Currency from "./Setting/Currency";
import PaymentTerms from "./Setting/PaymentTerms";
import BankDetail from "./Setting/BankDetails";
import Designation from "./Setting/Designation";
import Store from "./Setting/Store";
import User from "./Setting/User";
import LogisticDetail from "./Setting/LogisticDetail";
import TermsAndCondition from "./Setting/TermsAndCondition";
// import Approvals from "./Setting/Approvals";
import Product from "./Inventory/Product";
import UnitOfMeasurement from "./Setting/UnitOfMeasurement";
import Category from "./Inventory/Category";
import Tax from "./Setting/Tax";
// import Material from "./Inventory/Material";
import Profile from "./Profile/Profile";
import BuyersAndSuppliers from "./BuyersAndSuppliers/BuyersAndSuppliers";
import DocumentNumberFormat from "./Setting/DocumentNumberFormat";
import CounterPartyTag from "./Setting/CounterPartyTag";
// import Document from "./Setting/Document";
import ErrorHandlerScreen from "./Errorhandler/ErrorHandler";
import ProductionProcessType from "./Setting/ProductionProcessType";
import Purchase from "./SalesAndPurchase/Purchase";
import BatchManagement from "./SalesAndPurchase/BatchManagement/BatchManagement";
import InwardDocument from "./SalesAndPurchase/InwardDocument";
import ProformaInvoices from "./SalesAndPurchase/ProformaInvoices";
import TaxInvoice from "./SalesAndPurchase/TaxInvoice";
import SalesQuotation from "./SalesAndPurchase/SalesQuotation";
import Address from "./Setting/Address";
import Organization from "./Organization/Organization";
import Projects from "./Project/Project";
import QuotationFillupForm from "../Pages/Project/QuotationFillupForm";
import StockInventory from "./Inventory/StockInventory";
import UserGroup from "./Setting/UserGroup";
import CustomViewComponentRoute from "./Setting/CustomViewComponentRoute";
import NotAccess from "../Components/NotAccess";
import SalesReturn from "./SalesAndPurchase/SalesReturn";
import Payment from "./Payment/Payment";
import Reports from "./Reports/Reports";
export const LOGOUT = "/logout";
export const LOGIN = "/login";

// Routes for authentication-related pages (Login, Logout)
const authRoutes: RouteObject[] = [
  {
    path: LOGIN,
    element: <SignIn />,
  },
];

// Routes for main application pages (Dashboard, Roles, Permissions..)
const mainRoutes: RouteObject[] = [
  // Index route for the dashboard
  { index: true, element: <Dashboard /> },
  ...Role,
  ...Permission,
  ...Attribute,
  ...RolePermission,
  ...TaxType,
  ...Country,
  ...UserType,
  ...Currency,
  ...PaymentTerms,
  ...BankDetail,
  ...Designation,
  ...CustomViewComponentRoute,
  ...Store,
  ...User,
  ...LogisticDetail,
  ...TermsAndCondition,
  // ...Approvals,
  ...Product,
  ...UnitOfMeasurement,
  ...Category,
  ...Tax,
  // ...Material,
  ...Profile,
  ...BuyersAndSuppliers,
  ...DocumentNumberFormat,
  ...CounterPartyTag,
  ...ErrorHandlerScreen,
  // ...Document,
  ...ProductionProcessType,
  ...Purchase,
  ...InwardDocument,
  ...BatchManagement,
  ...ProformaInvoices,
  ...TaxInvoice,
  ...SalesQuotation,
  ...Address,
  ...Organization,
  ...Projects,
  ...StockInventory,
  ...UserGroup,
  ...SalesReturn,
  ...Payment,
  ...Reports,
];

// Complete route configuration for the application
const routes: RouteObject[] = [
  // Authentication-related routes
  {
    path: "/project-quotation-public/:token",
    element: <QuotationFillupForm />,
  },
  {
    path: LOGIN,
    element: <AuthLayout />,
    children: authRoutes,
  },
  {
    path: LOGOUT,
    element: <Signout />,
  },
  // Main application routes
  {
    path: "/",
    element: <Layout />,
    children: mainRoutes,
  },
  {
    path: "/not-access",
    element: <NotAccess />,
    // children: mainRoutes,
  },
  // Route for handling unknown paths (404 Not Found)
  { path: "*", element: <NotFound /> },
];

/**
 * AppRoutes component manages the application's routing logic.
 * It defines routes for authentication, main application pages, and handles unknown paths.
 * @returns {React.ReactElement} The rendered React element for the routes.
 */
const AppRoutes = () => {
  const element = useRoutes(routes);
  return <div>{element}</div>;
};

export default AppRoutes;
