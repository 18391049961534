import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { COLORS } from "../../Util/colors";
import { HEADINGS } from "../../Util/Constants";
import useAuthController from "../../Controller/AuthController";
import CustomButton from "../../Components/Button/CustomButton";
import { ClipLoader } from "react-spinners";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleLogin, handleResendOtp } = useAuthController();
  const apiUrl = process.env.REACT_APP_API_URL as string;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Set loading state to true
    setLoading(true);

    try {
      const result = await handleLogin(email, showOtpField, otp, apiUrl);
      if (result && result.showOtpField) {
        setShowOtpField(result.showOtpField);
      }
    } finally {
      // Set loading state back to false after the API call is complete
      setLoading(false);
    }
  };

  const handleResendClick = async () => {
    setLoading(true); // Set loading state to true when resending OTP

    try {
      await handleResendOtp(email, apiUrl);
    } finally {
      setLoading(false); // Set loading state back to false after the API call is complete
    }
  };

  return (
    <div className="white-box" style={{ minWidth: "350px" }}>
      <center>
        <img
          src="/images/svg/ic_logo.svg"
          alt="Prismatek Logo"
          className="logo"
          height={35}
        />
      </center>
      <div style={{ border: "0px solid #ccc", padding: "20px" }}>
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <button style={activeTabButtonStyle}>{HEADINGS.SIGN_IN}</button>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email" className="pt-1">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => {
                  if (!e.target.value && showOtpField) {
                    setShowOtpField(false);
                  }
                  return setEmail(e.target.value);
                }}
                required
              />
            </Form.Group>

            {showOtpField && (
              <Form.Group controlId="otp" className="pt-3">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </Form.Group>
            )}

            {showOtpField && (
              <p className="pt-3" style={{ fontSize: 13, textAlign: "center" }}>
                Didn’t Receive OTP ?<br />
                <span
                  onClick={handleResendClick}
                  style={{
                    fontSize: 8,
                    color: COLORS.primary,
                    cursor: "pointer",
                  }}
                >
                  RESEND OTP
                </span>
              </p>
            )}
            <div
              className="pt-3"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {loading ? (
                <ClipLoader
                  loading={true}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <CustomButton label="Sign In" type="submit" width="100%" />
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const activeTabButtonStyle = {
  backgroundColor: "#FFF",
  borderTop: "0px white",
  borderLeft: "0px white",
  borderRight: "0px white",
  borderBottom: "1px solid #1F79B4",
  padding: "10px 20px",
  margin: "0 5px",
  cursor: "pointer",
};

export default SignIn;
