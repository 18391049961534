import { RouteObject } from "react-router-dom";
import List from "../../Pages/SalesAndPurchase/InwardDocument/List";
import AddUpdate from "../../Pages/SalesAndPurchase/InwardDocument/AddUpdate";
import View from "../../Pages/SalesAndPurchase/InwardDocument/View";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_INWARD_DOCUMENT,
  DELETE_INWARD_DOCUMENT,
  UPDATE_INWARD_DOCUMENT,
  VIEW_INWARD_DOCUMENT,
} from "../../Util/PermissionList";

export const INWARD_DOCUMENT_TITLE = "Inward Document";
export const INWARD_DOCUMENT_LIST = "/inward-document";
export const INWARD_DOCUMENT_DASHBOARD_LIST = "/inward-document/:date";
export const INWARD_DOCUMENT_ADD = "/inward-document/add-document";
export const INWARD_DOCUMENT_EDIT = "/inward-document/:id/edit-document";
export const INWARD_DOCUMENT_VIEW = "/inward-document/:id/view-document";
export const INWARD_DOCUMENT_FOR_PURCHASE_ORDER =
  "/purchase-order/:purchase_order_id/inwards";
export const INWARD_DOCUMENT_FOR_PURCHASE_ORDER_ADD =
  "/purchase-order/:purchase_order_id/inwards/add";
export const INWARD_DOCUMENT_FOR_PURCHASE_ORDER_VIEW =
  "/purchase-order/:purchase_order_id/inwards/:inward_id/view";

const InwardDocument: RouteObject[] = [
  {
    path: INWARD_DOCUMENT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_FOR_PURCHASE_ORDER,
    element: (
      <AuthorizedRoute
        element={<List />}
        permission={VIEW_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_FOR_PURCHASE_ORDER_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
      />
    ),
  },
  {
    path: INWARD_DOCUMENT_FOR_PURCHASE_ORDER_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_INWARD_DOCUMENT}
        checkPermisson={{
          addPermission: ADD_INWARD_DOCUMENT,
          updatePermission: UPDATE_INWARD_DOCUMENT,
          deletePermission: DELETE_INWARD_DOCUMENT,
          viewPermission: VIEW_INWARD_DOCUMENT,
        }}
      />
    ),
  },
];

export default InwardDocument;
