import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import AddUpdateForm from "../../Components/AddUpdateForm";
import { Payment } from "../../Model/Setting/Payment";
import FieldDescription from "../../Components/FieldDescription";
import { TaxInvoice } from "../../Model/SalesAndPurchase/TaxInvoice/TaxInvoice";
import { useDateFormat } from "../../Util/InputDateFormate";
import { useApi } from "../../Controller/ApiController";
import { BuyersAndSuppliers } from "../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { PAYMENT_LIST, PAYMENT_TITLE } from "../../Routes/Payment/Payment";
import { setToast } from "../../Util/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormat, DateString, DateWithoutTime } from "../../Util/DateFormat";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";

const AddUpdate = () => {
  let { id }: any = useParams();
  let { invoiceid }: any = useParams();
  const [isShows, setIsShows] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const dateFormat: any = useDateFormat();
  const navigate = useNavigate();
  const { apiGet } = useApi();
  // const [selectedPaymentType, setSelectedPaymentType] = useState<any>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [formError, setFormError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [includeWith] = useState([
    "buyerBillingAddress.counterPartyCompany",
    "supplierBillingAddress.organization",
  ]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const formFields = [
    {
      id: "inputinvoice_id",
      label: "Invoice*",
      type: "select",
      col: 6,
      name: "tax_invoice_id",
      placeholder: "Select an Invoice",
      searchField: "document_number",
      model: TaxInvoice,
      addNewBtnStatus: false,
      disabled: id || invoiceid,
      isNeedFullData: true,
      includeWith: includeWith,
      customFilter: [
        {
          field: "status",
          operator: "not in",
          value: ["draft"],
        },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Invoice is required",
        },
      ],
      description: "Select the invoice related to this payment.",
    },
    {
      id: "inputbuyer_id",
      label: "Buyer*",
      type: "select",
      col: 6,
      name: "buyer_id",
      disabled: true,
      placeholder: "Select a Buyer",
      searchField: "company_name",
      model: BuyersAndSuppliers,
      addNewBtnStatus: false,
      customFilter: [
        {
          field: "company_type",
          operator: "in",
          value: ["buyer", "both"],
        },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Buyer is required",
        },
      ],
      description: "Select the buyer who is purchasing your goods.",
    },
    // {
    //   id: "inputsupplier_id",
    //   label: "Supplier",
    //   type: "select",
    //   col: 6,
    //   name: "supplier_id",
    //   placeholder: "Select a Supplier",
    //   searchField: "company_name",
    //   model: Organization,
    //   addNewBtnStatus: false,
    //   disabled: true,
    //   // customValue: "",
    //   // customValue: { label: "", value: "" },
    //   description: "",
    // },
    {
      id: "inputPaymentType",
      label: "Payment Type*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "payment_type",
      disabled: id,
      options: [
        { label: "Credit", value: "credit" },
        { label: "Debit", value: "debit" },
      ],
      placeholder: "Payment Type is required",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Payment Type is required",
        },
      ],
      description: "Please select the type of payment.",
    },
    {
      id: "inputDocumentNumber",
      label: "Document Number*",
      type: "text",
      col: 6,
      name: "document_number",
      disabled: true,
      placeholder: "Enter Document Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Document Number is required",
        },
      ],
      description: "Enter the document number associated with the payment.",
    },
    {
      id: "inputPayableAmount",
      label: "Invoice Amount*",
      type: "number",
      col: 6,
      name: "payable_amount",
      disabled: true,
      placeholder: "Enter Invoice Amount",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Invoice is required",
        },
      ],
      description:
        "This field displays the total amount of the selected invoice.",
    },
    {
      id: "inputAmount",
      label: "Amount*",
      type: "number",
      col: 6,
      name: "amount",
      disabled: id,
      placeholder: "Enter Amount",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Amount is required",
        },
      ],
      description: "Enter the amount.",
    },
    {
      id: "inputPaymentReferenceNumber",
      label: "Payment Reference Number*",
      type: "text",
      col: 6,
      disabled: id,
      name: "payment_reference_number",
      placeholder: "Enter Payment Reference Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Payment Reference Number is required",
        },
      ],
      description: "Enter the reference number for the payment.",
    },
    {
      id: "inputPaymentMode",
      label: "Payment Mode*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      disabled: id,
      name: "payment_mode",
      options: [
        { label: "Cash", value: "cash" },
        { label: "Bank-Transfer", value: "bank-transfer" },
        { label: "Other", value: "other" },
      ],
      placeholder: "Select an Payment Mode",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Payment Mode is required",
        },
      ],
      description: "Select the mode of payment.",
    },
    {
      id: "inputStatus",
      label: "Status*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "status",
      disabled: id,
      options: [
        { label: "Paid", value: "paid" },
        { label: "Partially-Paid", value: "partially-paid" },
        { label: "Refunded", value: "refunded" },
        { label: "Partially-Refunded", value: "partially-refunded" },
      ],
      placeholder: "Select status",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Status is required",
        },
      ],
      description: "Select the status of the payment.",
    },
    {
      id: "payment_date",
      label: "Payment Date*",
      type: "datePicker",
      datePicker: true,
      col: 6,
      name: "payment_date",
      disabled: id,
      placeholder: dateFormat.replace("dd", "DD"),
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Payment Date is required",
        },
      ],
      description: "Select the date of the payment.",
    },
    {
      id: "notes",
      label: "Note",
      type: "textarea",
      col: 12,
      name: "notes",
      rows: 5,
      placeholder: "Enter payment note",
      description: "Add note of the payment.",
    },
  ];

  const [modifiedfields, setModifiedfields] = useState(formFields);
  const [refreshKey, setRefreshKey] = useState(0);

  const getDocumentNumber = async (DocumentNumberType: any) => {
    const response = await apiGet(`next-document-number/${DocumentNumberType}`);
    return response?.data?.value;
  };

  const paymentTypeChange = async (selectedPaymentType: any) => {
    const updatedFields: any = [...formFields];

    const DocumentNumberType =
      selectedPaymentType.value === "credit"
        ? "payment_credit"
        : "payment_debit";
    const documentNumber = await getDocumentNumber(DocumentNumberType);

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      payment_type: selectedPaymentType,
      document_number: documentNumber,
    }));

    setModifiedfields(updatedFields);
    setRefreshKey((prev) => prev + 1);
  };

  useEffect(() => {
    const getInvloiceDataById = async () => {
      let item: any = await TaxInvoice.$query()
        .with(["buyerBillingAddress.counterPartyCompany"])
        .find(invoiceid);
      setFormData({
        ...formData,
        buyer_id: {
          label:
            item.$attributes.buyer_billing_address.counter_party_company
              ?.company_name,
          value:
            item.$attributes.buyer_billing_address.counter_party_company?.id,
        },
        tax_invoice_id: {
          value: item.$attributes.id,
          label: item.$attributes.document_number,
        },
        payable_amount: item.$attributes.total_amount,
      });
    };
    if (invoiceid) {
      getInvloiceDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceid]);

  const getFormData = async (itemId: string) => {
    const item: any = await Payment.$query()
      .with(["buyer", "supplier", "taxInvoice"])
      .find(itemId);
    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data = itemData;

          if (data["tax_invoice"]) {
            data["tax_invoice_id"] = {
              label: itemData.tax_invoice["document_number"],
              value: itemData.tax_invoice["id"],
            };
          }
          if (data["payment_type"]) {
            data["payment_type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.payment_type),
              value: itemData.payment_type,
            };
          }
          if (data["buyer"]) {
            data["buyer_id"] = {
              label: itemData.buyer.company_name,
              value: itemData.buyer.id,
            };
          }
          if (data["supplier"]) {
            data["supplier_id"] = {
              label: itemData.supplier.company_name,
              value: itemData.supplier.id,
            };
          }
          if (data["payment_mode"]) {
            data["payment_mode"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.payment_mode),
              value: itemData.payment_mode,
            };
          }
          if (data["status"]) {
            data["status"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.status),
              value: itemData.status,
            };
          }
          if (dateFormat && data["payment_date"]) {
            const date: any = DateString(
              DateWithoutTime(itemData.payment_date),
              dateFormat.split(" ")[0],
            );
            data["payment_date"] = date;
          }
          if (data["tax_invoice"]) {
            data["payable_amount"] = data["tax_invoice"].total_amount;
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          if (error.response?.data?.errors) {
            setToast("error", error.response.data.errors);
          } else if (error.response?.data?.message) {
            setToast("error", error.response.data.message);
          } else {
            setToast(
              "error",
              "An error occurred while processing your request",
            );
          }
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateFormat]);

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);
    inputData.buyer_id =
      inputData.buyer_id && parseInt(inputData.buyer_id?.value);
    inputData.supplier_id =
      inputData.supplier_id && parseInt(inputData.supplier_id?.value);
    inputData.amount = inputData.amount && Number(inputData.amount);
    inputData.tax_invoice_id = parseInt(inputData.tax_invoice_id?.value);
    inputData.payment_mode = inputData.payment_mode?.value;
    inputData.payment_type = inputData.payment_type?.value;
    inputData.status = inputData.status?.value;
    inputData.payment_date = DateFormat(
      inputData.payment_date,
      dateFormat.replace("dd", "DD"),
    );

    !inputData.buyer_id && delete inputData.buyer_id;
    !inputData.supplier_id && delete inputData.supplier_id;
    delete inputData.payable_amount;
    delete inputData.tax_invoice;
    delete inputData.buyer;
    delete inputData.supplier;

    try {
      if (inputData.id) {
        let item: any = await Payment.$query().find(inputData.id);

        item.$attributes = {
          id: item.$attributes.id,
          notes: inputData.notes,
        };
        delete item.$attributes.document_number;
        delete item.$attributes.tax_invoice_id;

        item = await item.$save();
        setToast("success", `Payment Successfully Updated`);
      } else {
        await Payment.$query().store(inputData);
        setToast("success", `Payment Successfully Added`);
      }
      navigate(PAYMENT_LIST);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={modifiedfields}
          refreshKey={refreshKey}
          model={Payment}
          title={PAYMENT_TITLE}
          listRoute={PAYMENT_LIST}
          dynamicFormData={formData}
          customFormSubmit={handleCustomSubmit}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          isNotUpdate={undefined}
          loadingStatus={loading}
          onChangeModalStatus={undefined}
          onOptionChange={(selectedOption: any, field: any, formData: any) => {
            if (field === "payment_type") {
              setFormData(formData);
              paymentTypeChange(selectedOption);
            }
            if (field === "tax_invoice_id") {
              setFormData({
                ...formData,
                buyer_id: {
                  label:
                    selectedOption.data.buyer_billing_address
                      ?.counter_party_company?.company_name,
                  value:
                    selectedOption.data.buyer_billing_address
                      ?.counter_party_company?.id,
                },
                supplier_id: {
                  label:
                    selectedOption.data.supplier_billing_address?.organization
                      ?.company_name,
                  value:
                    selectedOption.data.supplier_billing_address?.organization
                      ?.id,
                },
                tax_invoice_id: selectedOption,
                payable_amount: selectedOption.data?.total_amount,
              });
            }
          }}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={PAYMENT_TITLE}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
