import { BuyersAndSuppliers } from "../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { CounterPartyAddress } from "../Model/BuyersAndSuppliers/CounterPartyAddress/CounterPartyAddress";
import { Category } from "../Model/Inventory/Category";
import { Product } from "../Model/Inventory/Product";
import { BankDetail } from "../Model/Setting/BankDetails";
import { Country } from "../Model/Setting/Country";
import { Currency } from "../Model/Setting/Currency";
import { LogisticDetail } from "../Model/Setting/LogisticDetail";
import { Organization } from "../Model/Setting/Organization";
import { OrganizationAddress } from "../Model/Setting/OrganizationAddress";
import { PaymentTerms } from "../Model/Setting/PaymentTerms";
import { Store } from "../Model/Setting/Store";
import { Tax } from "../Model/Setting/Tax";
import { TaxType } from "../Model/Setting/TaxType";
import { TermsAndCondition } from "../Model/Setting/TermsAndCondition";
import { UnitOfMeasurement } from "../Model/Setting/UnitOfMeasurement";
import { UserType } from "../Model/Setting/UserType";

export function AuditLogIdFieldDetails(model: any) {
  switch (model) {
    case "products":
      return {
        model: Product,
        needFormFields: [
          "id",
          "category.name",
          "change_quantity",
          "concentration",
          "country.name",
          "created_at",
          "current_stock",
          "dealer_price",
          "default_price",
          "deleted_at",
          "description",
          "distributor_price",
          "dosage_form",
          "filling",
          // "hsn_code",
          "ingredients",
          "max_stock_level",
          "min_stock_level",
          "packaging_material",
          "product_id",
          "product_name",
          "regular_buying_price",
          "regular_selling_price",
          "reject_stock",
          "stock_status",
          "type",
          "type_of_filling",
          "unit_of_measurement.name",
          "updated_at",
          "wholesale_buying_price",
          "wholesale_selling_price",
        ],
        includes: ["unitOfMeasurement", "category", "country"],
        header: "Product",
      };
    case "category":
      return {
        model: Category,
        needFormFields: [
          "id",
          "name",
          "parent_id.name",
          "created_at",
          "updated_at",
        ],
        includes: ["parentId"],
        header: "Category",
      };
    case "parent":
      return {
        model: Category,
        needFormFields: [
          "id",
          "name",
          "parent_id.name",
          "created_at",
          "updated_at",
        ],
        includes: ["parentId"],
        header: "Category",
      };
    case "unit_of_measurement":
      return {
        model: UnitOfMeasurement,
        needFormFields: [
          "id",
          "name",
          "eway_bill_uom",
          "description",
          "created_at",
          "updated_at",
        ],
        header: "Unit Of Measurement",
      };
    case "bank_detail":
      return {
        model: BankDetail,
        needFormFields: [
          "id",
          "bank_name",
          "account_number",
          "account_name",
          "branch",
          "swift_code",
          "micr",
          "address",
          "created_at",
          "updated_at",
        ],
        header: "Bank",
      };
    case "terms_and_condition":
      return {
        model: TermsAndCondition,
        needFormFields: [
          "id",
          "name",
          "description",
          "created_at",
          "updated_at",
        ],
        header: "Terms And Condition",
      };
    case "logistic_detail":
      return {
        model: LogisticDetail,
        needFormFields: [
          "id",
          "name",
          "description",
          "created_at",
          "updated_at",
        ],
        header: "Logistic Detail",
      };
    case "tax":
      return {
        model: Tax,
        needFormFields: [
          "id",
          "tax_name",
          "rate",
          "tax_type.title",
          "created_at",
          "updated_at",
        ],
        includes: ["taxType"],
        header: "Tax",
      };
    case "country":
      return {
        model: Country,
        needFormFields: ["id", "name", "code", "created_at", "updated_at"],
        header: "Country",
      };
    case "tax_type":
      return {
        model: TaxType,
        needFormFields: ["id", "title", "created_at", "updated_at"],
        header: "Tax Type",
      };
    case "currency":
      return {
        model: Currency,
        needFormFields: [
          "id",
          "symbol",
          "code",
          "country.name",
          "created_at",
          "updated_at",
        ],
        includes: ["country"],
        header: "Currency",
      };
    case "store":
    case "stores":
      return {
        model: Store,
        needFormFields: [
          "id",
          "address_line_1",
          "address_line_2",
          "city",
          "country.name",
          "created_at",
          "deleted_at",
          "tax_type.title",
          "is_default",
          "name",
          "state",
          "tax_number",
          "updated_at",
          "zipcode",
        ],
        header: "Store",
        includes: ["country", "taxType"],
      };
    case "payment_term":
      return {
        model: PaymentTerms,
        needFormFields: [
          "id",
          "name",
          "days",
          "description",
          "created_at",
          "updated_at",
        ],
        header: "Payment Term",
      };
    case "user_type":
      return {
        model: UserType,
        needFormFields: [
          "id",
          "name",
          "description",
          "created_at",
          "updated_at",
        ],
        header: "User Type",
      };
    case "counter_party_company":
      return {
        model: BuyersAndSuppliers,
        needFormFields: [
          "id",
          "company_name",
          "company_type",
          "email",
          "tax_number",
          "fax_no",
          "address_line1",
          "address_line2",
          "phone_no",
          "zip_code",
          "city",
          "state",
          "company_tcs_setting",
          "country.name",
          "tax_type.title",
          "created_at",
          "updated_at",
        ],
        header: "Counter Party Company",
        includes: ["country", "taxType"],
      };
    case "counter-party-address":
      return {
        model: CounterPartyAddress,
        needFormFields: [
          "id",
          "address_line1",
          "address_line2",
          "address_name",
          "address_type",
          "city",
          "counter_party_company.company_name",
          "country.name",
          "created_at",
          "deleted_at",
          "email",
          "fax_no",
          "phone_no",
          "state",
          "updated_at",
          "zip_code",
        ],
        header: "Counter Party Address",
        includes: ["country", "counterPartyCompany"],
      };
    case "organization-address":
      return {
        model: OrganizationAddress,
        needFormFields: [
          "id",
          "address_line1",
          "address_line2",
          "address_name",
          "address_type",
          "city",
          "country.name",
          "created_at",
          "deleted_at",
          "email",
          "fax_no",
          "phone_no",
          "state",
          "updated_at",
          "zip_code",
        ],
        header: "Organization Address",
        includes: ["country"],
      };
    case "organization":
      return {
        model: Organization,
        needFormFields: [
          "id",
          "company_name",
          "currency_decimal_delimiter",
          "currency_delimiter",
          "dateformat",
          "timezone",
          "created_at",
          "updated_at",
        ],
        header: "Organization",
      };
    default:
      return null;
  }
}
