import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/UnitOfMeasurement/List";
import AddUpdate from "../../Pages/Setting/UnitOfMeasurement/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_UNIT_OF_MEASUREMENT,
  DELETE_UNIT_OF_MEASUREMENT,
  RESTORE_UNIT_OF_MEASUREMENT,
  UPDATE_UNIT_OF_MEASUREMENT,
  VIEW_UNIT_OF_MEASUREMENT,
} from "../../Util/PermissionList";

export const UNIT_OF_MEASUREMENT_TITLE = "Unit Of Measurement";
export const UNIT_OF_MEASUREMENT_LIST = "/unit-of-measurements";
export const UNIT_OF_MEASUREMENT_ADD = "/unit-of-measurements/add";
export const UNIT_OF_MEASUREMENT_EDIT = "/unit-of-measurements/:id/edit";
export const UNIT_OF_MEASUREMENT_ARCHIVE = "/unit-of-measurements/archive";

const UnitOfMeasurement: RouteObject[] = [
  {
    path: UNIT_OF_MEASUREMENT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_UNIT_OF_MEASUREMENT}
        checkPermisson={{
          addPermission: ADD_UNIT_OF_MEASUREMENT,
          updatePermission: UPDATE_UNIT_OF_MEASUREMENT,
          deletePermission: DELETE_UNIT_OF_MEASUREMENT,
          restorePermission: RESTORE_UNIT_OF_MEASUREMENT,
          viewPermission: VIEW_UNIT_OF_MEASUREMENT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: UNIT_OF_MEASUREMENT_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={UNIT_OF_MEASUREMENT_TITLE}
            listRoute={UNIT_OF_MEASUREMENT_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_UNIT_OF_MEASUREMENT,
          updatePermission: UPDATE_UNIT_OF_MEASUREMENT,
          deletePermission: DELETE_UNIT_OF_MEASUREMENT,
          restorePermission: RESTORE_UNIT_OF_MEASUREMENT,
          viewPermission: VIEW_UNIT_OF_MEASUREMENT,
        }}
        permission={ADD_UNIT_OF_MEASUREMENT}
      />
    ),
  },
  {
    path: UNIT_OF_MEASUREMENT_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate
            title={UNIT_OF_MEASUREMENT_TITLE}
            listRoute={UNIT_OF_MEASUREMENT_LIST}
          />
        }
        checkPermisson={{
          addPermission: ADD_UNIT_OF_MEASUREMENT,
          updatePermission: UPDATE_UNIT_OF_MEASUREMENT,
          deletePermission: DELETE_UNIT_OF_MEASUREMENT,
          restorePermission: RESTORE_UNIT_OF_MEASUREMENT,
          viewPermission: VIEW_UNIT_OF_MEASUREMENT,
        }}
        permission={UPDATE_UNIT_OF_MEASUREMENT}
      />
    ),
  },
  {
    path: UNIT_OF_MEASUREMENT_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        permission={DELETE_UNIT_OF_MEASUREMENT}
        checkPermisson={{
          addPermission: ADD_UNIT_OF_MEASUREMENT,
          updatePermission: UPDATE_UNIT_OF_MEASUREMENT,
          deletePermission: DELETE_UNIT_OF_MEASUREMENT,
          restorePermission: RESTORE_UNIT_OF_MEASUREMENT,
          viewPermission: VIEW_UNIT_OF_MEASUREMENT,
        }}
      />
    ),
  },
];

export default UnitOfMeasurement;
