import { RouteObject } from "react-router-dom";
import List from "../../../Pages/SalesAndPurchase/BatchManagement/List";
import AddUpdate from "../../../Pages/SalesAndPurchase/BatchManagement/AddUpdate";
import AuthorizedRoute from "../../../Util/AuthorizedRoute";
import {
  ADD_BATCH,
  DELETE_BATCH,
  UPDATE_BATCH,
  VIEW_BATCH,
} from "../../../Util/PermissionList";
import View from "../../../Pages/SalesAndPurchase/BatchManagement/View";

export const BATCH_MANAGEMENT_TITLE = "Batch Management";
export const BATCH_MANAGEMENT_LIST = "/batch-management";
export const BATCH_MANAGEMENT_ADD = "/batch-management/add";
export const BATCH_MANAGEMENT_EDIT = "/batch-management/:id/edit";
export const BATCH_MANAGEMENT_VIEW = "/batch-management/:id/view";

const BatchManagement: RouteObject[] = [
  {
    path: BATCH_MANAGEMENT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_BATCH}
        checkPermisson={{
          addPermission: ADD_BATCH,
          updatePermission: UPDATE_BATCH,
          deletePermission: DELETE_BATCH,
          viewPermission: VIEW_BATCH,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: BATCH_MANAGEMENT_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_BATCH}
        checkPermisson={{
          addPermission: ADD_BATCH,
          updatePermission: UPDATE_BATCH,
          deletePermission: DELETE_BATCH,
          viewPermission: VIEW_BATCH,
        }}
      />
    ),
  },
  {
    path: BATCH_MANAGEMENT_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_BATCH}
        checkPermisson={{
          addPermission: ADD_BATCH,
          updatePermission: UPDATE_BATCH,
          deletePermission: DELETE_BATCH,
          viewPermission: VIEW_BATCH,
        }}
      />
    ),
  },
  {
    path: BATCH_MANAGEMENT_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        checkPermisson={{
          addPermission: ADD_BATCH,
          updatePermission: UPDATE_BATCH,
          deletePermission: DELETE_BATCH,
          viewPermission: VIEW_BATCH,
        }}
        permission={VIEW_BATCH}
      />
    ),
  },
];

export default BatchManagement;
