import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { setToast } from "../Util/Toast";

interface CustomArchiveModalProps {
  title: string;
  model: any;
  getData: (page: number) => void;
  ArchiveItemId: number | null;
  setArchiveItemId: React.Dispatch<React.SetStateAction<number | null>>;
  showArchiveModal: boolean;
  setshowArchiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleArchiveClick?: Function | null;
}

export default function CustomArchiveModal({
  title,
  model,
  getData,
  ArchiveItemId,
  setArchiveItemId,
  showArchiveModal,
  setshowArchiveModal,
  handleArchiveClick,
}: CustomArchiveModalProps) {
  const [inputValue, setInputValue] = useState("");
  const isArchiveEnabled = inputValue.toLowerCase() === "archive";

  const handleRowArchive = async () => {
    setshowArchiveModal(false);

    try {
      await model.$query().destroy(ArchiveItemId);
      getData(1);
      setArchiveItemId(null);
      setToast("success", title + " Successfully Archived");
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  useEffect(() => {
    if (showArchiveModal) {
      setInputValue("");
    }
  }, [showArchiveModal]);
  return (
    <>
      <Modal show={showArchiveModal} onHide={() => setshowArchiveModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="text"
              placeholder="Type 'Archive' to confirm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-100 p-2 rounded-2 border-secondary"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setshowArchiveModal(false)}
            className="btn-sm"
          >
            Cancel
          </Button>
          {isArchiveEnabled && (
            <Button
              variant="danger"
              onClick={() =>
                handleArchiveClick ? handleArchiveClick() : handleRowArchive()
              }
              className="btn-sm"
            >
              Archive
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
