import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Util/Toast";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import ActivityLogs from "../../../Components/ActivityLogs";
import { UserGroup } from "../../../Model/Setting/UserGroup";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { useApi } from "../../../Controller/ApiController";
import ConfirmationModal from "../../../Components/ConfirmationModel";
interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}
const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  let { id }: any = useParams();
  if (isNotUpdate) {
    id = null;
  }

  const { apiGet, apiDelete } = useApi();
  const [loading, setLoading] = useState(true);
  const [loadingForDatatable, setLoadingForDatatable] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("activityLogs");
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );

  const formFields = [
    {
      id: "inputtitle",
      label: "Title*",
      type: "text",
      col: 6,
      name: "title",
      placeholder: "Enter Title",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Title is required",
        },
      ],
      description: "Enter the Title.",
    },
    {
      id: "inputdescription",
      label: "Description*",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter Description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description: "Enter the Description.",
    },
  ];
  const handleConfirmation = async () => {
    try {
      setCustomSubmitting(true);
      await apiDelete(`user-groups/${id}/users/detach`, {
        resources: [selectedUserId],
      });
      fetchUserGroup();
      setShowConfirmationModal(false);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };
  const detachUserGroup = async (userId: any) => {
    setSelectedUserId(userId);
    setShowConfirmationModal(true);
  };
  const getFormData = async (itemId: string) => {
    const item: any = await UserGroup.$query().find(itemId);
    return item.$attributes;
  };

  const navigate = useNavigate();
  const handleCustomSubmit = async (formData: any) => {
    let inputData = { ...formData };
    try {
      if (inputData.id) {
        let item = await UserGroup.$query().find(inputData.id);

        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };
        item = await item.$save();

        setToast("success", `${title} Successfully Updated`);
      } else {
        await UserGroup.$query().store(inputData);
        setToast("success", `${title} Successfully Added`);
      }
      if (onChangeModalStatus) {
        onChangeModalStatus(false);
      } else {
        navigate(`${listRoute.toLowerCase().replace(" ", "-")}`);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const itemData = await getFormData(id);
          let data = itemData;
          setFormData(data);
        } catch (error: any) {
          setToast("error", error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchUserGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  const fetchUserGroup = async () => {
    try {
      setLoadingForDatatable(true);
      const response = await apiGet(
        `user-groups/${id}/users?limit=${limit}&page=${page}`,
      );
      navigate(`?page=${page}&perPage=${limit}`);
      setData(response.data);
      setTotalRows(response.meta?.total);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setToast("error", error.response.data.errors);
      } else if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoadingForDatatable(false);
    }
  };

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Id",
      cell: (row: any) => row.id,
    },
    {
      name: "First Name",
      cell: (row: any) => row.first_name,
    },
    {
      name: "Last Name",
      cell: (row: any) => row.last_name,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Detach",
      cell: (row) => (
        <button
          className="btn btn-sm btn-danger"
          onClick={() => detachUserGroup(row.id)}
        >
          Detach
        </button>
      ),
    },
  ];
  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
  };

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={UserGroup}
          title={title}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={listRoute}
          dynamicFormError={formError}
          customGoBack={listRoute}
          isNotUpdate={isNotUpdate}
          loadingStatus={loading}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>

      {id && (
        <div className="py-4">
          <h2>Users List</h2>
          <Card>
            <Card.Body>
              <CustomDataTable
                columns={columns}
                data={data}
                progressPending={loadingForDatatable}
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                handleRowCreateButtonClick={handleRowCreateButtonClick}
                currentPages={page}
                currentRowsPerPage={limit}
                customSkeletonCount={5}
              />
            </Card.Body>
          </Card>
        </div>
      )}

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"USER_GROUPS"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title="Confirmation"
        message="Are you sure you want to detach this user?"
        onConfirm={handleConfirmation}
        confirmButtonColor="danger"
        spinnerStatus={customSubmitting ? customSubmitting : false}
      />
    </>
  );
};

export default AddUpdate;
