import { useNavigate } from "react-router-dom";
import { setToken, setUser } from "../Util/Auth";
import { setToast } from "../Util/Toast";
import fetchPermissions from "../Util/FetchPermissions";

const useAuthController = () => {
  const navigate = useNavigate();

  const handleLogin = async (
    email: string,
    showOtpField: boolean,
    otp: string,
    apiUrl: string,
  ) => {
    const loginUrl = apiUrl + "api/login";
    const loginVerifyUrl = apiUrl + "api/login-verify";

    const loginReqParams = { email };
    const loginVerifyReqParams = { email, otp };

    try {
      let response;
      if (email && !showOtpField && !otp) {
        response = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginReqParams),
        });
      } else {
        response = await fetch(loginVerifyUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginVerifyReqParams),
        });
      }

      if (response.ok) {
        if (email && !showOtpField && !otp) {
          const data = await response.json();
          const { message } = data;
          setToast(response.ok ? "success" : "error", message);
          return { showOtpField: true };
        } else {
          const res = await response.json();
          const { data } = res;
          const { token, user } = data;
          await Promise.all([setToken(token), setUser(user)]);
          await fetchPermissions(); // Call fetchPermissions after successful login
          navigate("/");
          // Successful login, redirect to /
        }
      } else {
        // Handle error cases
        const data = await response.json();
        const { message } = data;
        setToast("error", message);
      }
    } catch (error) {
      setToast("error", "Something went wrong");
    }
  };

  const handleResendOtp = async (email: string, apiUrl: string) => {
    const resendOtpUrl = apiUrl + "api/resend-otp";

    try {
      const response = await fetch(resendOtpUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }), // Send email for OTP resend
      });

      const data = await response.json();
      const { message } = data;
      setToast(response.ok ? "success" : "error", message);
    } catch (error) {
      setToast("error", "Something went wrong");
    }
  };

  return { handleLogin, handleResendOtp };
};

export default useAuthController;
