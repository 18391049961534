import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CustomSkeleton({
  count,
  height,
  width,
  center,
  baseColor,
  highlightColor,
  customOuterStyle,
  customInnerStyle,
  circle,
  containerClassName,
}: any) {
  const defaultOuterStyle = {
    padding: "25px",
    width: width || "100%",
    ...(center && { margin: "0 auto" }),
  };
  return (
    <div
      style={
        customOuterStyle
          ? {
              ...customOuterStyle,
              width: width || "100%",
              ...(center && { margin: "0 auto" }),
            }
          : defaultOuterStyle
      }
    >
      <Skeleton
        circle={circle}
        baseColor={baseColor}
        highlightColor={highlightColor}
        containerClassName={containerClassName}
        height={height || 1}
        style={customInnerStyle && customInnerStyle}
        count={count}
      />
    </div>
  );
}

export default CustomSkeleton;
