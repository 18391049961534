import React from "react";
import CustomSkeleton from "../../../Components/CustomSkeleton";

interface CompanyDetailsProps {
  data: any;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ data }) => {
  const isLoading = data === undefined || Object.keys(data).length === 0;

  if (isLoading) {
    // Display loading message or no data message
    return (
      <CustomSkeleton
        height={198}
        customOuterStyle={{ padding: "0" }}
        customInnerStyle={{ borderRadius: "0 0 9px 9px" }}
        containerClassName={"d-grid"}
      />
    );
  }

  return (
    <>
      <div
        style={{
          maxHeight: "173px",
          overflowY: "auto",
          fontSize: "14px",
          padding: "10px 20px 10px",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          {data.company_name}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "7px",
          }}
        >
          <div style={{ width: "48%" }}>
            <p className="mb-0">
              <span style={{ fontWeight: 500 }}> Company Type </span>:{" "}
              {data.company_type.label}
            </p>
            <p className="mb-0">
              <span style={{ fontWeight: 500 }}> Tax Type </span>:{" "}
              {data.tax_type.title}
            </p>
            <p className="mb-0">
              <span style={{ fontWeight: 500 }}> Tax: </span> {data.tax_number}
            </p>
            <p className="mb-0" style={{ width: "max-content" }}>
              <span style={{ fontWeight: 500 }}>
                {" "}
                Company Tax Collection At Source Setting:{" "}
              </span>{" "}
              {data.company_tcs_setting.label}
            </p>
          </div>
          <div style={{ width: "48%" }}>
            <p className="mb-0" style={{ wordWrap: "break-word" }}>
              <span style={{ fontWeight: 500 }}> Email:</span> {data.email}
            </p>
            <p className="mb-0">
              <span style={{ fontWeight: 500 }}> Phone Number:</span>{" "}
              {data.phone_no}
            </p>
            <p className="mb-0">
              <span style={{ fontWeight: 500 }}> Fax Number:</span>{" "}
              {data.fax_no}
            </p>
          </div>
        </div>

        <div style={{ width: "100%", marginTop: "10px" }}>
          <span style={{ fontWeight: 500 }}> Address : </span>
          <span className="mb-0">
            {data.address_line1}, {data.address_line2 + ", "}
          </span>
          <span className="mb-0">
            {data.city}, {data.state}, {data.country_id.label}-{data.zip_code}
          </span>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
