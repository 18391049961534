import { Model } from "@tailflow/laravel-orion/lib/model";

export class SalesReturns extends Model<{
  supplier_id: any;
  transporter_name: string;
  document_number: string;
  document_date: string;
  amendment: number;
  delivery_date: string;
  tax_invoice_id: string;
  tax_invoice_date: string;
  vehicle_number: string;
  transporter_doc_name: string;
  transportation_doc_date: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}> {
  public $resource(): string {
    return "sales-returns";
  }
}
