import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSkeleton from "../../../Components/CustomSkeleton";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import { setToast } from "../../../Util/Toast";
import { DateWithoutTime } from "../../../Util/DateFormat";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { StockInventory } from "../../../Model/Inventory/StockInventory";
import { StockInventoryItem } from "../../Inventory/StockInventory/List";
import CustomButton from "../../../Components/Button/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import {
  STOCK_INVENTORY_ID_VIEW,
  STOCK_INVENTORY_TITLE,
} from "../../../Routes/Inventory/StockInventory";

function View() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const { id }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingForInvoiceData, setLoadingForInvoiceData] = useState(false);
  const [batchData, setBatchData] = useState<any>(null);
  const [invoiceData, setInvoiceData] = useState<any>([]);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );

  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [totalRows, setTotalRows] = useState(0);
  const [includeWith] = useState(["product", "batch"]);
  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);
  const getBatch = async (itemId: any) => {
    try {
      const item = await BatchManagement.$query()
        .with(["product", "country"])
        .find(itemId);
      return item.$attributes;
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };
  useEffect(() => {
    getInvoice();
  }, [limit, page]); // eslint-disable-line

  const getInvoice = async () => {
    try {
      await fetchData(
        page,
        limit,
        { batches_id: { value: id } },
        setLoadingForInvoiceData,
        setInvoiceData,
        setTotalRows,
        navigate,
        new StockInventory(),
        sortField,
        sortOrder,
        false,
        includeWith,
      );
      setLimit(limit);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const fetchData2 = async () => {
    const data: any = await getBatch(id);
    data && setLoading(false);
    setBatchData(data);
  };
  useEffect(() => {
    fetchData2();
    //eslint-disable-next-line
  }, [id]);

  const GoBack = () => {
    navigate(-1);
  };

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    document_number: string,
  ) => {
    e.preventDefault();
    window.open(
      STOCK_INVENTORY_ID_VIEW.replace(
        ":document_number",
        document_number.toString(),
      ),
      "_blank",
    );
  };

  const columns: TableColumn<StockInventoryItem>[] = [
    {
      name: "Document Number",
      cell: (row) => (
        <CustomButton
          label={
            <>
              {row.document_number}{" "}
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </>
          }
          variant="link"
          customeClass="text-decoration-none p-0 m-0"
          onClick={(e) =>
            handleRowViewButtonClick(e, row?.document_number?.toString())
          }
        />
      ),
    },
    {
      name: "Document Type",
      cell: (row) => <span> {row.document_type}</span>,
    },
    {
      name: "Product",
      cell: (row) => <span> {row.product?.product_name}</span>,
    },
    {
      name: "Previous Quantity",
      cell: (row) => row.old_quantity_by_product,
    },
    {
      name: "Change Quantity",
      cell: (row: any) => (
        <span
          style={{ fontWeight: "500" }}
          className={
            row.change_quantity_by_product < 0 ? "text-danger" : "text-success"
          }
        >
          {row.change_quantity_by_product}
        </span>
      ),
    },
    {
      name: "New Quantity",
      cell: (row) => row.new_quantity_by_product,
    },
    {
      name: "Created At",
      cell: (row) => <span> {row.created_at}</span>,
    },
  ];
  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
  };
  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <h2>View Batch</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton
            label={`Back`}
            customeClass="mb-2"
            variant="secondary"
            onClick={GoBack}
          />
        </div>
      </div>

      <div>
        <Card className="mb-3">
          <Card.Body className="p-0">
            <Card.Header style={{ fontSize: "20px", fontWeight: 500 }}>
              Batch Details
            </Card.Header>
            {loading ? (
              <CustomSkeleton
                height={192}
                containerClassName={"d-grid"}
                customOuterStyle={{ padding: 0, height: "192px" }}
                customInnerStyle={{ borderRadius: "0 0 0.375rem 0.375rem" }}
              />
            ) : (
              <div>
                <div className="p-3 pb-0 d-flex">
                  <div style={{ width: "33%" }}>
                    <p>
                      <span style={{ fontWeight: "700" }}> Batch Number: </span>{" "}
                      {batchData?.batch_number}
                    </p>
                    <p>
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        Current Stock:{" "}
                      </span>{" "}
                      {batchData?.current_stock}
                    </p>
                    <p>
                      <span style={{ fontWeight: "700" }}> Pack Size: </span>{" "}
                      {batchData?.pack_size}
                    </p>
                  </div>

                  <div style={{ width: "33%" }}>
                    <p>
                      <span style={{ fontWeight: "700" }}> Product Name: </span>{" "}
                      {batchData?.product?.product_name}
                    </p>
                    <p>
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        Change Quantity:{" "}
                      </span>{" "}
                      <span
                        style={{ fontWeight: "500" }}
                        className={
                          batchData?.change_quantity < 0
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {batchData?.change_quantity}
                      </span>
                    </p>
                    <p>
                      <span style={{ fontWeight: "700" }}> Country: </span>{" "}
                      {batchData?.country?.name}
                    </p>
                  </div>

                  <div style={{ width: "33%" }}>
                    <p>
                      <span style={{ fontWeight: "700" }}> Expiry Date: </span>{" "}
                      {DateWithoutTime(batchData?.expiry_date)}
                    </p>
                    <p>
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        Concentration:{" "}
                      </span>{" "}
                      {batchData?.concentration}
                    </p>
                    <p>
                      <span style={{ fontWeight: "700" }}> Created Date: </span>{" "}
                      {DateWithoutTime(batchData?.created_at)}
                    </p>
                  </div>
                </div>
                <p className="pt-0 p-3">
                  <span style={{ fontWeight: "700" }}> Description: </span>{" "}
                  {batchData?.description}
                </p>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <div>
        <Card className="mb-3">
          <Card.Body className="p-0">
            <Card.Header style={{ fontSize: "20px", fontWeight: 500 }}>
              {STOCK_INVENTORY_TITLE}
            </Card.Header>
            <CustomDataTable
              columns={columns}
              data={invoiceData}
              progressPending={loadingForInvoiceData}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              handleRowCreateButtonClick={handleRowCreateButtonClick}
              currentPages={page}
              currentRowsPerPage={limit}
              customSkeletonCount={5}
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default View;
