import React, { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";

interface FieldDescriptionProps {
  title: string;
  customTitle?: string;
  formFields: any;
  isShows: boolean;
}

function FieldDescription({
  title,
  formFields,
  isShows,
  customTitle,
}: FieldDescriptionProps) {
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isShows && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isShows]);

  return (
    <div className="mb-5" ref={cardRef}>
      <Card
        style={{
          backgroundColor: "#e4e4e4",
          display: isShows ? "block" : "none",
        }}
      >
        <Card.Header>
          <h5 style={{ margin: "0.2rem 0" }}>
            {customTitle ? customTitle : "Help For Adding a New " + title}
          </h5>
        </Card.Header>
        <Card.Body>
          <div>
            {formFields
              .filter((field: any) => field.description)
              .map((field: any, index: any) => (
                <p key={index}>
                  {field.label && <strong>{field.label}: </strong>}
                  <span
                    dangerouslySetInnerHTML={{ __html: field.description }}
                  />
                </p>
              ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FieldDescription;
