import CustomSkeleton from "./CustomSkeleton";

function FormSkeleton() {
  const skeletonStyle = { marginBottom: "10px" };

  return (
    <div
      className="pt-2 pb-2"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div style={{ width: "48%" }}>
        {[...Array(3)].map((_, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <CustomSkeleton
              count={1}
              height={20}
              width={"50%"}
              customOuterStyle={{ ...skeletonStyle }}
            />
            <CustomSkeleton
              count={1}
              height={40}
              customOuterStyle={{ ...skeletonStyle, width: "100%" }}
            />
          </div>
        ))}
      </div>
      <div style={{ width: "48%" }}>
        {[...Array(3)].map((_, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <CustomSkeleton
              count={1}
              height={20}
              width={"50%"}
              customOuterStyle={{ ...skeletonStyle }}
            />
            <CustomSkeleton
              count={1}
              height={40}
              customOuterStyle={{ ...skeletonStyle, width: "100%" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FormSkeleton;
