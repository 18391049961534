import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Country/List";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import { VIEW_COUNTRY } from "../../Util/PermissionList";

export const COUNTRIES_TITLE = "Countries";
export const COUNTRIES_LIST = "/countries";

const CountryRoutes: RouteObject[] = [
  {
    path: COUNTRIES_LIST,
    element: <AuthorizedRoute permission={VIEW_COUNTRY} element={<List />} />,
  },
];

export default CountryRoutes;
