import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Designation/List";
import AddUpdate from "../../Pages/Setting/Designation/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_DESIGNATION,
  DELETE_DESIGNATION,
  RESTORE_DESIGNATION,
  UPDATE_DESIGNATION,
  VIEW_DESIGNATION,
} from "../../Util/PermissionList";

export const DESIGNATIONS_TITLE = "Designation";
export const DESIGNATIONS_LIST = "/designations";
export const DESIGNATIONS_ADD = "/designations/add";
export const DESIGNATIONS_EDIT = "/designations/:id/edit";
export const DESIGNATIONS_ARCHIVE = "/designations/archive";

const Designation: RouteObject[] = [
  {
    path: DESIGNATIONS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_DESIGNATION}
        checkPermisson={{
          addPermission: ADD_DESIGNATION,
          updatePermission: UPDATE_DESIGNATION,
          deletePermission: DELETE_DESIGNATION,
          restorePermission: RESTORE_DESIGNATION,
          viewPermission: VIEW_DESIGNATION,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: DESIGNATIONS_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_DESIGNATION}
        checkPermisson={{
          addPermission: ADD_DESIGNATION,
          updatePermission: UPDATE_DESIGNATION,
          deletePermission: DELETE_DESIGNATION,
          restorePermission: RESTORE_DESIGNATION,
          viewPermission: VIEW_DESIGNATION,
        }}
      />
    ),
  },
  {
    path: DESIGNATIONS_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_DESIGNATION}
        checkPermisson={{
          addPermission: ADD_DESIGNATION,
          updatePermission: UPDATE_DESIGNATION,
          deletePermission: DELETE_DESIGNATION,
          restorePermission: RESTORE_DESIGNATION,
          viewPermission: VIEW_DESIGNATION,
        }}
      />
    ),
  },
  {
    path: DESIGNATIONS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_DESIGNATION,
          updatePermission: UPDATE_DESIGNATION,
          deletePermission: DELETE_DESIGNATION,
          restorePermission: RESTORE_DESIGNATION,
          viewPermission: VIEW_DESIGNATION,
        }}
        permission={DELETE_DESIGNATION}
      />
    ),
  },
];

export default Designation;
