import { setToast } from "./Toast";

export function showErrorsInToasts(errors: any) {
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      errors[key].forEach((errorMessage: any) => {
        setToast("error", errorMessage);
      });
    }
  }
}
