import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BuyersAndSuppliers } from "../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { setToast } from "../../Util/Toast";
import { Country } from "../../Model/Setting/Country";
import { TaxType } from "../../Model/Setting/TaxType";
import {
  BUYERS_AND_SUPPLIERS_LIST,
  BUYERS_AND_SUPPLIERS_TITLE,
} from "../../Routes/BuyersAndSuppliers/BuyersAndSuppliers";
import Card from "./Components/Card";
import CompanyDetails from "./Components/CompanyDetails";
import Address from "./Components/Address";
import AdditionalUser from "./Components/AdditionalUser";
import AddUpdateModel from "./Components/Modal";
import AddUpdateForm from "../../Components/AddUpdateForm";
import CompanyTags from "./Components/CompanyTags";
import Notes from "../../Components/Notes";
import Attachments from "../../Components/Attachments";
import Alerts from "../../Components/Alerts";
import ActivityLogs from "../../Components/ActivityLogs";
import {
  ADD_COUNTER_PARTY_ADDRESS,
  ADD_COUNTER_PARTY_PERSON,
  ADD_COUNTER_PARTY_TAG,
  DELETE_COUNTER_PARTY_ADDRESS,
  UPDATE_COUNTER_PARTY_PERSON,
  VIEW_COUNTER_PARTY_ADDRESS,
  VIEW_COUNTER_PARTY_PERSON,
  VIEW_COUNTER_PARTY_TAG,
} from "../../Util/PermissionList";
import ConfirmationModal from "../../Components/ConfirmationModel";
import AddUpdateTaxType from "../../Pages/Setting/TaxType/AddUpdate";
import { TAX_TYPE_LIST, TAX_TYPE_TITLE } from "../../Routes/Setting/TaxType";
import CustomSkeleton from "../../Components/CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";
import BalanceSheet from "./Components/BalanceSheet";
import CustomButton from "../../Components/Button/CustomButton";

const AddUpdate = ({ permissions }: any) => {
  const navigate = useNavigate();
  const { id }: any = useParams();
  const [companyFormData, setCompanyFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [warning, setWarning] = useState<any>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  /// Company Details
  const [taxTypeModalStatus, setTaxTypeModalStatus] = useState(false);
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };
  const handleConfirmDelete = async () => {
    try {
      await BuyersAndSuppliers.$query().destroy(id);
      setToast("success", `${BUYERS_AND_SUPPLIERS_TITLE} Successfully Deleted`);
      navigate(`${BUYERS_AND_SUPPLIERS_LIST.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
    setShowConfirmation(false);
  };
  const handleCompanyCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    // Creating a new object with the updated values
    const updatedData = {
      ...formData,
      tax_type_id: parseInt(formData.tax_type_id.value),
      country_id: parseInt(formData.country_id.value),
      company_type: formData.company_type.value,
      company_tcs_setting: formData.company_tcs_setting.value,
    };

    try {
      if (formData.id) {
        let item = await BuyersAndSuppliers.$query().find(formData.id);
        item.$attributes = {
          ...item.$attributes,
          ...updatedData,
        };

        item = await item.$save();
        setToast(
          "success",
          `${BUYERS_AND_SUPPLIERS_TITLE} Successfully Updated`,
        );
        fetchData();
      } else {
        await BuyersAndSuppliers.$query().store(updatedData);
        setToast("success", `${BUYERS_AND_SUPPLIERS_TITLE} Successfully Added`);
      }
      setShowCompanyDetails(false);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };
  const companyFormFields = [
    {
      id: "inputCompanyType",
      label: "Company Type*",
      type: "select",
      addNewBtnStatus: false,
      col: 4,
      name: "company_type",
      options: [
        { label: "Buyer", value: "buyer" },
        { label: "Supplier", value: "supplier" },
        { label: "Both", value: "both" },
      ],
      placeholder: "Select an Company Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Type is required",
        },
      ],
    },
    {
      id: "inputCompanyName",
      label: "Company Name*",
      type: "text",
      col: 4,
      name: "company_name",
      placeholder: "Enter Your Company Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Name is required",
        },
      ],
    },
    {
      id: "inputEmail",
      label: "Email*",
      type: "text",
      col: 4,
      name: "email",
      placeholder: "Enter Your Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Please enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is required",
        },
      ],
    },
    {
      id: "inputPhoneNumber",
      label: "Phone Number",
      type: "phone_no",
      col: 4,
      name: "phone_no",
      placeholder: "Enter Your Phone Number",
      description: "Enter Your Phone Number",
    },
    {
      id: "inputTaxNumber",
      label: "Tax Number*",
      type: "text",
      col: 4,
      name: "tax_number",
      placeholder: "Enter Your Tax Number",
      validate: [
        {
          rule: (value: any) => value,
          message: "The Tax number is Required.",
        },
      ],
    },
    {
      id: "inputFaxNumber",
      label: "Fax Number",
      type: "text",
      col: 4,
      name: "fax_no",
      placeholder: "Enter Your Fax Number",
      description: "Enter Your Fax Number",
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 4,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is required",
        },
      ],
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 4,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "zip Code*",
      type: "text",
      col: 4,
      name: "zip_code",
      placeholder: "Enter Your zip Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "zip Code is required",
        },
      ],
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 4,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is required",
        },
      ],
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 4,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is required",
        },
      ],
    },
    {
      id: "inputcompany_tcs_setting",
      label: "Company Tax Collection at Source Setting*",
      type: "select",
      addNewBtnStatus: false,
      col: 4,
      name: "company_tcs_setting",
      placeholder: "Select an Company Tax Collection At Source Setting",
      options: [
        { label: "None", value: "none" },
        { label: "Purchase", value: "purchase" },
        { label: "Sales ", value: "sales" },
        { label: "Both", value: "both" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Tax Collection At Source Setting is required",
        },
      ],
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 4,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
    },
    {
      id: "inputtax_types_id",
      label: "Tax Type*",
      type: "select",
      col: 4,
      name: "tax_type_id",
      placeholder: "Select an TaxType",
      searchField: "title",
      model: TaxType,
      addNewBtnStatus: true,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Type is required",
        },
      ],
      onChangeModalStatus: (status: boolean) => setTaxTypeModalStatus(status),
      modalStatus: taxTypeModalStatus,
      widget: (
        <AddUpdateTaxType
          title={TAX_TYPE_TITLE}
          listRoute={TAX_TYPE_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setTaxTypeModalStatus(status)
          }
        />
      ),
    },
  ];

  const getCompanyFormData = async (itemId: string) => {
    const item = await BuyersAndSuppliers.$query()
      .with(["country", "taxType", "counterPartyTags"])
      .find(itemId);
    return item.$attributes;
  };

  const fetchData = async () => {
    if (id) {
      try {
        const itemData: any = await getCompanyFormData(id);
        let data = itemData;
        if (data["country_id"]) {
          data["country_id"] = {
            label: itemData.country["name"],
            value: itemData.country["id"],
          };
        }
        if (data["tax_type_id"]) {
          data["tax_type_id"] = {
            label: itemData.tax_type["title"],
            value: itemData.tax_type["id"],
          };
        }
        if (data["company_type"]) {
          data["company_type"] = {
            label: CapitalizeAndRemoveUnderscore(itemData.company_type),
            value: itemData.company_type,
          };
        }
        if (data["company_tcs_setting"]) {
          data["company_tcs_setting"] = {
            label: CapitalizeAndRemoveUnderscore(itemData.company_tcs_setting),
            value: itemData.company_tcs_setting,
          };
        }
        setCompanyFormData(data);
      } catch (error: any) {
        setToast("error", error.response.data.message);
      }
    } else {
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [showBillingAddress, setShowBillingAddress] = useState(false);

  const handleCloseBillingAddress = () => setShowBillingAddress(false);
  const handleShowBillingAddress = () => setShowBillingAddress(true);

  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);

  const handleCloseDeliveryAddress = () => setShowDeliveryAddress(false);
  const handleShowDeliveryAddress = () => setShowDeliveryAddress(true);

  const [showAdditionalUser, setShowAdditionalUser] = useState(false);
  const [activeTab, setActiveTab] = useState("notes");

  const handleAdditionalUserClose = () => setShowAdditionalUser(false);
  const handleAdditionalUserShow = () => setShowAdditionalUser(true);

  const [showCompanyDetails, setShowCompanyDetails] = useState(false);

  const handleCompanyDetailsClose = () => setShowCompanyDetails(false);
  const handleCompanyDetailsShow = () => setShowCompanyDetails(true);
  const [isDeliveryAddArchive, setIsDeliveryAddArchive] = useState(false);
  const [isBillingAddArchive, setIsBillingAddArchive] = useState(false);

  const warningStatus = (type: string, fieldName: string, message: string) => {
    if (message) {
      setWarning((prevState: any) =>
        prevState.some((warning: any) => warning.fieldName === fieldName)
          ? prevState
          : [...prevState, { type, fieldName, message }],
      );
    } else {
      setWarning((prevState: any) =>
        prevState.filter(
          (warning: any) =>
            warning.fieldName !== fieldName || warning.type !== type,
        ),
      );
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3>Buyers & Suppliers</h3>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-danger me-2 btn-sm"
            onClick={() => {
              setShowConfirmation(true);
            }}
          >
            Remove Connection
          </button>
          <CustomButton
            label={`Back`}
            variant="secondary"
            onClick={() => {
              setShowConfirmation(true);
            }}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Alerts data={warning} alertsType="warning" />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          <Card
            title="Company Details"
            onEdit={handleCompanyDetailsShow}
            buttonTitle={id ? "Edit" : "Add"}
            isHideArchiveBtn={true}
          >
            <CompanyDetails data={companyFormData} />
          </Card>
          {id && permissions[VIEW_COUNTER_PARTY_PERSON] && (
            <Card
              title="Additional User"
              onEdit={handleAdditionalUserShow}
              buttonTitle="Add"
              isHideEditBtn={!permissions[ADD_COUNTER_PARTY_PERSON] && true}
              isHideArchiveBtn={true}
              permissions={permissions}
            >
              <AdditionalUser
                id={id}
                handleOpen={handleAdditionalUserShow}
                show={showAdditionalUser}
                handleClose={handleAdditionalUserClose}
                isHideEditBtn={
                  !permissions[UPDATE_COUNTER_PARTY_PERSON] && true
                }
              />
            </Card>
          )}
        </div>
      </div>
      {id && permissions[VIEW_COUNTER_PARTY_ADDRESS] && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <Card
              title={`Delivery Address ${
                isDeliveryAddArchive ? "Archive" : ""
              } List`}
              onEdit={handleShowDeliveryAddress}
              buttonTitle="Add"
              archiveBtnTitle="Archived List"
              onArchive={() => {
                setIsDeliveryAddArchive(!isDeliveryAddArchive);
              }}
              isHideArchiveBtn={!isDeliveryAddArchive}
              isHideEditBtn={
                isDeliveryAddArchive || !permissions[ADD_COUNTER_PARTY_ADDRESS]
              }
              showArchiveBtn={permissions[DELETE_COUNTER_PARTY_ADDRESS]}
            >
              <Address
                id={id}
                handleOpen={handleShowDeliveryAddress}
                show={showDeliveryAddress}
                handleClose={handleCloseDeliveryAddress}
                addressType="delivery"
                changeWarningStatus={warningStatus}
                isArchive={isDeliveryAddArchive}
                permissions={permissions}
              />
            </Card>
            <Card
              title={`Billing Address ${
                isBillingAddArchive ? "Archive" : ""
              } List`}
              onEdit={handleShowBillingAddress}
              buttonTitle="Add"
              archiveBtnTitle="Archived List"
              onArchive={() => {
                setIsBillingAddArchive(!isBillingAddArchive);
              }}
              isHideArchiveBtn={!isBillingAddArchive}
              isHideEditBtn={
                isBillingAddArchive || !permissions[ADD_COUNTER_PARTY_ADDRESS]
              }
              showArchiveBtn={permissions[DELETE_COUNTER_PARTY_ADDRESS]}
            >
              <Address
                id={id}
                handleOpen={handleShowBillingAddress}
                show={showBillingAddress}
                handleClose={handleCloseBillingAddress}
                addressType="billing"
                changeWarningStatus={warningStatus}
                isArchive={isBillingAddArchive}
                permissions={permissions}
              />
            </Card>
          </div>
        </div>
      )}
      {id && permissions[VIEW_COUNTER_PARTY_TAG] && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <Card
              title="Company Tags"
              onEdit={() => {}}
              buttonTitle="Add"
              height="150px"
              isHideArchiveBtn={true}
              isHideEditBtn={true}
            >
              <CompanyTags
                id={id}
                addNewBtnStatus={permissions[ADD_COUNTER_PARTY_TAG] || false}
              />
            </Card>
            <Card
              title="Balance"
              onEdit={() => {}}
              buttonTitle="Add"
              height="150px"
              isHideArchiveBtn={true}
              isHideEditBtn={true}
            >
              {companyFormData.balance || companyFormData.balance === 0 ? (
                <p
                  className={`${
                    companyFormData.balance <= 0
                      ? "text-danger"
                      : "text-success"
                  }`}
                  style={{
                    display: "flex",
                    marginLeft: "15px",
                    alignItems: "center",
                    height: "98px",
                    fontSize: "25px",
                  }}
                >
                  {companyFormData.balance}
                </p>
              ) : (
                <CustomSkeleton
                  height={99}
                  customOuterStyle={{ padding: "0" }}
                  customInnerStyle={{ borderRadius: "0 0 9px 9px" }}
                  containerClassName={"d-grid"}
                />
              )}
            </Card>
          </div>
        </div>
      )}
      <div
        className=""
        style={{
          paddingTop: "20px",
        }}
      >
        <BalanceSheet />
      </div>

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "attachments" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("attachments")}
            >
              Attachments
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && (
              <Notes id={id} model={BuyersAndSuppliers} />
            )}
            {activeTab === "activityLogs" && (
              <ActivityLogs
                auditable_type={"COUNTER_PARTY_COMPANIES"}
                id={id}
              />
            )}
            {activeTab === "attachments" && (
              <Attachments
                fieldType="counter_party_company_id"
                moduleType="counter_party_company"
                id={id}
              ></Attachments>
            )}
          </div>
        </div>
      )}

      <AddUpdateModel
        title={id ? "Edit Company Details" : "Add Company Details"}
        show={showCompanyDetails}
        size={"xl"}
        handleClose={() => {
          handleCompanyDetailsClose();
        }}
        widget={
          <AddUpdateForm
            formFields={companyFormFields}
            model={BuyersAndSuppliers}
            title="Buyers&Suppliers"
            customTitle=" "
            customFormSubmit={handleCompanyCustomSubmit}
            dynamicFormData={companyFormData}
            isBackBtnDisable={true}
            dynamicFormError={formError}
            customSubmitting={customSubmitting}
          />
        }
      />
      <ConfirmationModal
        show={showConfirmation}
        onHide={handleCancelDelete}
        title="Delete Note"
        message={`Are you sure you want to remove connection?`}
        onConfirm={handleConfirmDelete}
        confirmButtonColor="danger"
        spinnerStatus={customSubmitting ? customSubmitting : false}
      />
    </>
  );
};

export default AddUpdate;
