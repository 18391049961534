import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Badge, Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import CustomButton from "../../../Components/Button/CustomButton";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import CustomDataTable from "../../../Components/CustomDataTable";
import { TableColumn } from "react-data-table-component";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import {
  TAX_INVOICE_ADD,
  TAX_INVOICE_VIEW,
  TAX_INVOICE_TITLE,
  TAX_INVOICE_EDIT,
} from "../../../Routes/SalesAndPurchase/TaxInvoice";
import { TaxInvoice } from "../../../Model/SalesAndPurchase/TaxInvoice/TaxInvoice";
import CustomViewButton from "../../../Components/Button/CustomViewButton";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDownloadButton from "../../../Components/Button/CustomDownloadButton";
import { getCookie } from "typescript-cookie";
import { SALES_RETURN_LIST_BY_TAX_INVOICE } from "../../../Routes/SalesAndPurchase/SalesReturn";
import { VIEW_SALES_RETURN } from "../../../Util/PermissionList";
import { Plus } from "react-bootstrap-icons";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
interface TaxInvoiceItem {
  id: number;
  title: string;
  description: string;
  status: string;
  days: number;
  delivery_date: string;
  created_at: string;
  document_number: string;
  payment_date: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const { date } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<TaxInvoiceItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const params = date ? new URLSearchParams(date) : null;
  const fromDate = params?.get("fromDate");

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(TAX_INVOICE_ADD);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(TAX_INVOICE_EDIT.replace(":updateid", id.toString()));
  };
  const handleOpenSalesReturnListDocument = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any,
  ) => {
    e.preventDefault();
    navigate(
      SALES_RETURN_LIST_BY_TAX_INVOICE.replace(":tax_invoice_id", row.id),
    );
  };
  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(TAX_INVOICE_VIEW.replace(":id", id.toString()));
  };

  const columns: TableColumn<TaxInvoiceItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Invoice Number",
      cell: (row) => row.document_number,
    },
    {
      name: "Title",
      cell: (row) => row.title,
    },
    {
      name: "Status",
      cell: (row) => (
        <Badge
          pill
          bg={
            row.status === "draft"
              ? "secondary"
              : row.status === "sent"
              ? "warning"
              : row.status === "partially-paid" ||
                row.status === "partially-refunded"
              ? "info"
              : row.status === "paid"
              ? "success"
              : row.status === "refunded"
              ? "danger"
              : "success"
          }
          style={{ padding: "7px 10px", fontSize: "smaller" }}
        >
          {CapitalizeAndRemoveUnderscore(row.status)}
        </Badge>
      ),
    },
    {
      name: "Payment Due Date",
      cell: (row: any) => row.payment_date,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Download",
      button: true,
      cell: (row: any) => (
        <div>
          <CustomDownloadButton
            id={row.id}
            onClick={handlePDFDownloadButtonClick}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      button: true,
      wrap: true,
      minWidth: "15%",
      style: {
        "justify-content": "right !important",
      },
      omit:
        !permissions.update &&
        !permissions.delete &&
        !permissions[`${VIEW_SALES_RETURN}`],
      cell: (row: any) => (
        <div className="d-flex align-items-center">
          {permissions[`${VIEW_SALES_RETURN}`] && row.status !== "draft" && (
            <button
              className="btn btn-sm btn-success d-flex"
              onClick={(e: any) => {
                handleOpenSalesReturnListDocument(e, row);
              }}
            >
              Return
              <span style={{ marginTop: "-1px" }}>
                <Plus />
              </span>
            </button>
          )}
          <CustomViewButton
            id={row.id}
            onClick={(e) => {
              return handleRowViewButtonClick(e, row.id);
            }}
          />
          {permissions.update && (
            <CustomEditButton
              isDisabled={row.status !== "draft" && true}
              id={row.id}
              onClick={handleRowEditButtonClick}
            />
          )}
        </div>
      ),
    },
  ];
  const authToken = getCookie("authToken");
  const token = authToken ? authToken.replace(/["']/g, "") : "";
  const handlePDFDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + `api/tax-invoice/pdf/`;
      const response = await fetch(apiUrl + id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "purchase order";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    }
  };
  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "document_number",
      value: "document_number",
      label: "Invoice Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "title",
      value: "title",
      label: "Title",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "payment_date",
      value: "payment_date",
      label: "Payment Due Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "status",
      value: "status",
      label: "Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Draft: "draft",
        Sent: "sent",
        Paid: "paid",
        "Partially-Paid": "partially-paid",
        Refunded: "refunded",
        "Partially-Refunded": "partially-refunded",
      },
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const fetchTaxInvoice = useCallback(async () => {
    try {
      setLoading(true);
      const filters = {
        ...(fromDate
          ? { created_at: { filter_operation: ">=", value: fromDate } }
          : {}),
        ...(inputValues ? inputValues : {}),
      };

      await fetchData(
        page,
        limit,
        filters,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new TaxInvoice(),
        sortField,
        sortOrder,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page, limit, inputValues, sortField, sortOrder, navigate]);
  useEffect(() => {
    fetchTaxInvoice();
  }, [fetchTaxInvoice]);

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchTaxInvoice();
  }, [limit, page]); // eslint-disable-line
  const handleGotoDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h2>{TAX_INVOICE_TITLE} List</h2>
        <div>
          {fromDate && (
            <button
              type="button"
              className="btn btn-secondary btn-sm mb-1"
              onClick={handleGotoDashboard}
            >
              Back
            </button>
          )}
        </div>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchTaxInvoice}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add && (
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"TaxInvoice"} // Pass optional fields to CustomDataTable
        />
      </div>
    </>
  );
}

export default List;
