import React, { useEffect } from "react";
import { removeToken } from "../../Util/Auth"; // Update the path accordingly
import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../../Util/LocalStorage";

function Signout() {
  const navigate = useNavigate();
  useEffect(() => {
    // Call the removeToken function to sign out the user
    removeToken();
    clearLocalStorage();

    // Redirect to the login page after signing out
    navigate("/login");
  });

  return (
    <div>
      <h2>Sign Out</h2>
      <p>You have been signed out successfully.</p>
    </div>
  );
}

export default Signout;
