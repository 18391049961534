import { Model } from "@tailflow/laravel-orion/lib/model";

export class OrganizationAddress extends Model<{
  address_type: any;
  address_name: string;
  address_line1: string;
  address_line2: string;
  zip_code: string;
  city: string;
  state: string;
  country_id: any;
  organization_id: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  country?: any;
  fax?: string;
  contant_number?: string;
  email?: string;
}> {
  public $resource(): string {
    return "organization-addresses";
  }
}
