import { AmountFormat } from "./AmountFormat";

export const CalculateTotalBeforeTaxs = (product: any) => {
  const quantity = parseFloat((product?.quantity ?? "0").toString()) || 0;
  const price = parseFloat((product?.price ?? "0").toString()) || 0;
  const rowTotal = quantity * price;
  let discountAmount = 0;
  const discountPercentage =
    parseFloat((product?.discount ?? "0").toString()) || 0;
  const discountType = product.discount_type;

  if (discountType === "pct") {
    discountAmount = (rowTotal * discountPercentage) / 100;
  } else if (discountType === "flat") {
    discountAmount = parseFloat((product?.discount ?? "0").toString()) || 0;
  }

  const totalBeforeTax = rowTotal - discountAmount;
  return parseFloat(totalBeforeTax.toFixed(2));
};

export const CalculateRowTotalTaxs = (product: any) => {
  const taxRate = parseFloat((product?.tax?.rate ?? "0").toString()) || 0;
  const rowTotalBeforeTax = CalculateTotalBeforeTaxs(product);
  const totalrowwisetax = (taxRate / 100) * rowTotalBeforeTax;
  return parseFloat(totalrowwisetax.toFixed(2));
};

export const calculateExtraTotalTax = (expenses: any) => {
  const taxRate = parseFloat(expenses?.tax?.rate) || 0;
  const amountValue = parseFloat(expenses?.total) || 0;
  return ((taxRate / 100) * amountValue).toFixed(2);
};

export const calculateExtraTotal = (expenses: any) => {
  const calculatedTax = parseFloat(calculateExtraTotalTax(expenses));
  const amountValue = parseFloat(expenses?.total) || 0;
  return (amountValue + calculatedTax).toFixed(2);
};
export const calculateTotalAllRowsTax = (products: any) => {
  let totalAllRowsTax = 0;
  products.forEach((row: any) => {
    totalAllRowsTax += CalculateRowTotalTaxs(row);
  });
  return totalAllRowsTax;
};
export const totaltaxWithExtra = (products: any, extraExpenses: any) => {
  const totalRowTax = (
    calculateTotalAllRowsTax(products) +
    (extraExpenses.reduce(
      (acc: any, expense: any) =>
        acc + parseFloat(AmountFormat(calculateExtraTotalTax(expense)) || 0),
      0,
    ) || 0)
  ).toFixed(2);
  return totalRowTax;
};
export const calculateTotalAllRowsBeforeTax = (products: any) => {
  let totalAllRowsBeforeTax = 0;
  products.forEach((row: any) => {
    totalAllRowsBeforeTax += CalculateTotalBeforeTaxs(row);
  });
  return totalAllRowsBeforeTax;
};
export const totalWithExtra = (products: any, extraExpenses: any) => {
  const totalExtra = (
    calculateTotalAllRowsBeforeTax(products) +
    (extraExpenses.reduce(
      (acc: any, expense: any) => acc + (parseFloat(expense.total) || 0),
      0,
    ) || 0)
  ).toFixed(2);
  return totalExtra;
};

export const calculateGrandTotal = (products: any, extraExpenses: any) => {
  const totalBeforeTax = calculateTotalAllRowsBeforeTax(products);
  const totalTax = calculateTotalAllRowsTax(products);
  const grandTotal = (
    totalBeforeTax +
    totalTax +
    (extraExpenses.reduce(
      (acc: any, expense: any) => acc + (parseFloat(expense.total) || 0),
      0,
    ) || 0) +
    (extraExpenses.reduce(
      (acc: any, expense: any) =>
        acc + parseFloat(AmountFormat(calculateExtraTotalTax(expense)) || 0),
      0,
    ) || 0)
  ).toFixed(2);
  return grandTotal;
};
