import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";

const apiUrl = process.env.REACT_APP_API_URL as string;
const BASE_URL = apiUrl + "api/";

const handleResponse = (response: any, needError?: any) => {
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const useApi = () => {
  const navigate = useNavigate();
  const token = getCookie("authToken");
  const isPublicUrl = getCookie("publicRoute");

  const headers = {
    Authorization: `Bearer ${token ? token.replace(/"/g, "") : ""}`,
    "Content-Type": "application/json",
  };

  const apiGet = async (endpoint: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
        headers: headers,
      });
      return handleResponse(response);
    } catch (error: any) {
      throw error;
    }
  };

  const apiGetPublic = async (endpoint: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
      });
      return handleResponse(response);
    } catch (error: any) {
      throw error;
    }
  };

  const apiPostPublic = async (endpoint: any, data: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        body: data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const apiPostPublicCountry = async (endpoint: any, data?: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  };

  const apiPost = async (endpoint: any, data: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        headers: {
          ...headers,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  };

  const apiPut = async (endpoint: any, data: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "PUT",
        headers: {
          ...headers,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  };

  const apiDelete = async (endpoint: any, data?: any) => {
    try {
      const requestOptions: any = {
        method: "DELETE",
        headers: headers,
      };
      if (data) {
        requestOptions.body = JSON.stringify(data);
      }
      const response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  };

  const apiPatch = async (endpoint: any, data: any) => {
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: "PATCH",
        headers: {
          ...headers,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // Check if token is available, if not, redirect to login
    if (!isPublicUrl && !token) {
      navigate("/login");
    }
  }, [token, isPublicUrl, navigate]);

  return {
    apiGet,
    apiGetPublic,
    apiPostPublic,
    apiPostPublicCountry,
    apiPost,
    apiPut,
    apiDelete,
    apiPatch,
  };
};
