import { Model } from "@tailflow/laravel-orion/lib/model";

export class TaxType extends Model<{
  title: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "tax-types";
  }
}
