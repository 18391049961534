import "bootstrap/dist/css/bootstrap.min.css";
import { Category } from "../../../Model/Inventory/Category";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Util/Toast";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import Notes from "../../../Components/Notes";
import ActivityLogs from "../../../Components/ActivityLogs";

interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  const navigate = useNavigate();
  let { id }: any = useParams();

  if (isNotUpdate) {
    id = null;
  }
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 12,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a distinct and descriptive name for the category. (Example: 'Electronics', 'Clothing', 'Office Supplies' etc.)",
    },
    {
      id: "inputCategoryId",
      label: "Parent Category",
      type: "select",
      col: 12,
      name: "parent_id",
      placeholder: "Select an Category",
      searchField: "name",
      model: Category,
      description:
        "Choose the parent category or leave it blank if it's a top-level category.",
      addNewBtnStatus: false,
    },
  ];

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);
    if (inputData.parent_id) {
      const categoryIdNumber = parseInt(inputData.parent_id?.value);
      inputData.parent_id = categoryIdNumber;
    }
    try {
      if (inputData.id) {
        let item = await Category.$query().find(inputData.id);

        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", `${title} Successfully Updated`);
      } else {
        await Category.$query().store(inputData);
        setToast("success", `${title} Successfully Added`);
      }

      if (onChangeModalStatus) {
        onChangeModalStatus(false);
      } else {
        navigate(`${listRoute.toLowerCase().replace(" ", "-")}`);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string) => {
    const item: any = await Category.$query().with(["parentId"]).find(itemId);
    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data = itemData;
          if (data["parent_id"]) {
            data["parent_id"] = {
              label: itemData.parent_id["name"],
              value: itemData.parent_id["id"],
            };
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={Category}
          title={title}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={listRoute}
          dynamicFormError={formError}
          isNotUpdate={isNotUpdate}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>
      {id && !isNotUpdate && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={Category} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"CATEGORY"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
