import "bootstrap/dist/css/bootstrap.min.css";
import { Product } from "../../../Model/Inventory/Product";
import { setToast } from "../../../Util/Toast";
import { useNavigate, useParams } from "react-router-dom";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useEffect, useState } from "react";
import { Category } from "../../../Model/Inventory/Category";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import FieldDescription from "../../../Components/FieldDescription";
import { Button } from "react-bootstrap";
import { useApi } from "../../../Controller/ApiController";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";
import Notes from "../../../Components/Notes";
import Attachments from "../../../Components/Attachments";
import { Country } from "../../../Model/Setting/Country";
import ActivityLogs from "../../../Components/ActivityLogs";
import { getCookie } from "typescript-cookie";
import {
  CATEGORY_LIST,
  CATEGORY_TITLE,
} from "../../../Routes/Inventory/Category";
import AddUpdateCategory from "../../../Pages/Inventory/Category/AddUpdate";
import AddUpdateUOM from "../../../Pages/Setting/UnitOfMeasurement/AddUpdate";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../../../Routes/Setting/UnitOfMeasurement";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";

interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  isNotUpdate,
  title,
  listRoute,
  onChangeModalStatus,
}) => {
  const navigate = useNavigate();
  let { id }: any = useParams();
  if (isNotUpdate) {
    id = null;
  }
  const { apiGet } = useApi();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [dynamicFormFields, setDynamicFormFields] = useState([]);
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false);
  // New Start
  const [cateoryModalStatus, setCategoryModalStatus] = useState<boolean>(false);
  const [UMOModalStatus, setUMOModalStatus] = useState<boolean>(false);
  // New End
  const svgIcon = `<svg fill="none" viewBox="0 0 24 24" width="16" height="16" xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 2px;"><g fill="grey"><path d="m12.75 11c0-.4142-.3358-.75-.75-.75s-.75.3358-.75.75v6c0 .4142.3358.75.75.75s.75-.3358.75-.75z"/><path clip-rule="evenodd" d="m12 1.25c-5.93706 0-10.75 4.81294-10.75 10.75 0 5.9371 4.81294 10.75 10.75 10.75 5.9371 0 10.75-4.8129 10.75-10.75 0-5.93706-4.8129-10.75-10.75-10.75zm-9.25 10.75c0-5.10863 4.14137-9.25 9.25-9.25 5.1086 0 9.25 4.14137 9.25 9.25 0 5.1086-4.1414 9.25-9.25 9.25-5.10863 0-9.25-4.1414-9.25-9.25z" fill-rule="evenodd"/><path d="m13 8c0 .55228-.4477 1-1 1s-1-.44772-1-1 .4477-1 1-1 1 .44772 1 1z"/></g></svg>`;
  const formFields = [
    {
      id: "inputCategoryId",
      label: "Category*",
      type: "select",
      col: 6,
      name: "category_id",
      placeholder: "Select Category",
      searchField: "name",
      model: Category,
      addNewBtnStatus: true,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Category is required",
        },
      ],
      description:
        "Categorize the product into relevant product groups or categories. (Example: 'Electronics', 'Clothing', 'Office Supplies' etc.)",
      onChangeModalStatus: (status: boolean) => setCategoryModalStatus(status),
      modalStatus: cateoryModalStatus,
      widget: (
        <AddUpdateCategory
          title={CATEGORY_TITLE}
          listRoute={CATEGORY_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setCategoryModalStatus(status)
          }
        />
      ),
    },
    {
      id: "inputUOMId",
      label: "Unit of Measurement*",
      type: "select",
      col: 6,
      name: "unit_of_measurement_id",
      placeholder: "Select Unit of Measurement",
      searchField: "name",
      model: UnitOfMeasurement,
      addNewBtnStatus: true,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Unit of Measurement is required",
        },
      ],
      description:
        "Define the unit of measurement for the product. (Example: 'Each(EA)', 'Kilogram(KG)', 'Meter(M)' etc.)",
      onChangeModalStatus: (status: boolean) => setUMOModalStatus(status),
      modalStatus: UMOModalStatus,
      widget: (
        <AddUpdateUOM
          title={UNIT_OF_MEASUREMENT_TITLE}
          listRoute={UNIT_OF_MEASUREMENT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setUMOModalStatus(status)}
        />
      ),
    },
    {
      id: "inputProductID",
      label: "Product ID*",
      type: "text",
      col: 6,
      name: "product_id",
      placeholder: "Enter Product ID",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Product ID is required",
        },
      ],
      description:
        "Assign a unique identifier or code to distinguish the product.",
    },
    {
      id: "inputProductName",
      label: "Product Name*",
      type: "text",
      col: 6,
      name: "product_name",
      placeholder: "Enter Product Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Product Name is required",
        },
      ],
      description: "Enter a descriptive name for the product.",
    },
    {
      id: "inputProductType",
      label: "Product Type*",
      type: "select",
      col: 6,
      name: "type",
      addNewBtnStatus: false,
      isNotSearchable: true,
      options: [
        {
          label: `<span>Buy</span><br /><span>${svgIcon}</span><span style=color:grey;>Item will appear ONLY in Purchase Documents</span>`,
          value: "buy",
          dangerouslySetInnerHTML: true,
        },
        {
          label: `<span>Sell</span><br /><span>${svgIcon}</span><span style=color:grey;>Item will appear ONLY in Sales Documents</span>`,
          value: "sell",
          dangerouslySetInnerHTML: true,
        },
        {
          label: `<span>Both</span><br /><span>${svgIcon}</span><span style=color:grey;>Item will appear in BOTH Sales as well Purchase documents</span>`,
          value: "both",
          dangerouslySetInnerHTML: true,
        },
      ],
      placeholder: "Enter Product Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Product Type is required",
        },
      ],
      description:
        "Specify the type of product, such as 'Physical', 'Digital' or 'Service'.",
    },
    {
      id: "inputCurrentStock",
      label: "Current Stock",
      type: "number",
      col: 6,
      name: "current_stock",
      placeholder: "Enter Current Stock",
      disabled: id && true,
      description:
        "Indicate the current available stock quantity for the product.",
    },
    // {
    //   id: "inputHsnCode",
    //   label: "HSN Code",
    //   type: "text",
    //   col: 6,
    //   name: "hsn_code",
    //   placeholder: "Enter HSN Code",
    //   description:
    //     "Include the HSN code for proper classification in invoices and taxation.",
    // },
    {
      id: "inputDefaultPrice",
      label: "Default Price",
      type: "number",
      col: 6,
      name: "default_price",
      placeholder: "Enter Default Price",
      description: "The standard price of the product.",
    },
    {
      id: "inputregular_buying_price",
      label: "Regular Buying Price",
      type: "number",
      col: 6,
      name: "regular_buying_price",
      placeholder: "Enter Regular Buying Price",
      description: "The price at which the product is regularly purchased.",
    },
    {
      id: "inputwholesale_buying_price",
      label: "Wholesale Buying Price",
      type: "number",
      col: 6,
      name: "wholesale_buying_price",
      placeholder: "Enter Wholesale Buying Price",
      description: "The price when the product is purchased in bulk.",
    },
    {
      id: "inputregular_selling_price",
      label: "Regular Selling Price",
      type: "number",
      col: 6,
      name: "regular_selling_price",
      placeholder: "Enter Regular Selling Price",
      description: "The regular selling price to customers.",
    },
    {
      id: "inputwholesalesellingprice",
      label: "Wholesale Selling Price",
      type: "number",
      col: 6,
      name: "wholesale_selling_price",
      placeholder: "Enter Wholesale Selling Price",
      description:
        "The maximum price at which the product can be sold to the end consumer.",
    },
    {
      id: "inputdealer_price",
      label: "Dealer Price",
      type: "number",
      col: 6,
      name: "dealer_price",
      placeholder: "Enter Dealer Price",
      description: "The price offered to authorized dealers.",
    },
    {
      id: "inputdistributor_price",
      label: "Distributor Price",
      type: "number",
      col: 6,
      name: "distributor_price",
      placeholder: "Enter Distributor Price",
      description: "The price offered to authorized distributors.",
    },
    {
      id: "inputDescription",
      label: "Description*",
      type: "text",
      col: 6,
      name: "description",
      placeholder: "Enter Description",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Description is required",
        },
      ],
      description: "The description for product.",
    },
    {
      id: "inputIngredients",
      label: "Ingredients*",
      type: "text",
      col: 6,
      name: "ingredients",
      placeholder: "Enter Ingredients",
      description: "The ingredients for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Ingredients is required",
        },
      ],
    },
    {
      id: "inputDosageForm",
      label: "Dosage Form*",
      type: "text",
      col: 6,
      name: "dosage_form",
      placeholder: "Enter Dosage Form",
      description: "The dosage form for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Dosage Form is required",
        },
      ],
    },
    {
      id: "inputFilling",
      label: "Filling*",
      type: "text",
      col: 6,
      name: "filling",
      placeholder: "Enter Filling",
      description: "The filling for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Filling is required",
        },
      ],
    },
    {
      id: "inputTypeofFilling",
      label: "Type of Filling*",
      type: "text",
      col: 6,
      name: "type_of_filling",
      placeholder: "Enter Type of Filling",
      description: "The type of filling for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Type of Filling is required",
        },
      ],
    },
    {
      id: "inputConcentration",
      label: "Concentration*",
      type: "text",
      col: 6,
      name: "concentration",
      placeholder: "Enter Concentration",
      description: "The Concentration for product.",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Concentration is required",
        },
      ],
    },
    {
      id: "inputPackagingMaterial",
      label: "Packaging material*",
      type: "file",
      col: 6,
      name: "packaging_material",
      placeholder: "Enter Packaging Material",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Packaging Material is required",
        },
      ],
      description: "The Packaging Material for product.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Indicate the country in which the product is located.",
    },
  ];
  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const dynamicAttributesValues = dynamicFormFields
      .filter((field: any) => field.isDynamic)
      .reduce((result: any[], field: any) => {
        const { name, type } = field;
        if (type === "select") {
          let data: any = formData[name]?.value || formData[name] || "";
          result.push({
            attribute_id: field.attribute_id,
            type,
            data,
          });
        } else if (type === "lookup") {
          let selectedId: any = formData[name];
          result.push({
            attribute_id: field.attribute_id,
            type,
            data: selectedId?.value,
          });
        } else if (type === "multiselect") {
          let selectedIds: any = formData[name] || [];
          selectedIds = selectedIds.map((id: any) => id.value).join(",");

          result.push({
            attribute_id: field.attribute_id,
            type,
            data: selectedIds,
          });
        } else if (type === "checkbox") {
          let isCheckboxChecked;
          if (id) {
            isCheckboxChecked =
              formData[name] && formData[name].includes(field.optionId);
          } else {
            isCheckboxChecked = Number(formData[name]) === field.optionId;
          }

          if (isCheckboxChecked) {
            const selectedIds = Array.isArray(field.optionId)
              ? field.optionId
              : field.optionId
              ? [field.optionId]
              : [];

            const existingEntry = result.find(
              (entry) =>
                entry.attribute_id === field.attribute_id &&
                entry.type === type,
            );
            if (existingEntry) {
              existingEntry.data = existingEntry.data.concat(selectedIds);
            } else {
              result.push({
                attribute_id: field.attribute_id,
                type,
                data: selectedIds,
              });
            }
          }
        } else {
          const data = formData[name] || "";

          result.push({
            attribute_id: field.attribute_id,
            type,
            data,
          });
        }

        return result;
      }, [])
      .map((entry) => {
        return {
          ...entry,
          data: Array.isArray(entry.data) ? entry.data?.join(",") : entry.data,
        };
      });
    const attributesWithBlanks = dynamicAttributesValues.map((attr) => ({
      ...attr,
      data: attr.data === null ? "" : attr.data,
    }));

    let inputData = Object.assign({}, formData);
    const unitOfMeasurementIdNumber = parseInt(
      inputData.unit_of_measurement_id?.value,
    );
    const categoryIdNumber = parseInt(inputData.category_id?.value);
    const countryIdNumber = parseInt(inputData?.country_id?.value);
    const filteredAttributesValues = dynamicAttributesValues.filter((item) =>
      item.type === "boolean" ? true : item.data && item.data !== "",
    );
    // Updating the object with the converted values
    inputData.category_id = Number(categoryIdNumber);
    inputData.unit_of_measurement_id = Number(unitOfMeasurementIdNumber);
    inputData.type = inputData.type?.value;
    inputData.attributes = filteredAttributesValues;
    inputData.country_id = Number(countryIdNumber);

    const formdata: any = new FormData();
    formdata.append("unit_of_measurement_id", inputData.unit_of_measurement_id);
    formdata.append("product_id", inputData.product_id);
    formdata.append("product_name", inputData.product_name);
    formdata.append("type", inputData.type);
    formdata.append("description", inputData.description);
    formdata.append("country_id", inputData.country_id);
    formdata.append("category_id", inputData.category_id);
    attributesWithBlanks.forEach((item: any, index: any) => {
      Object.entries(item).forEach(([key, value]) => {
        formdata.append(`attributes[${index}][${key}]`, value);
      });
    });
    inputData.current_stock &&
      formdata.append("current_stock", inputData.current_stock);
    // inputData.hsn_code && formdata.append("hsn_code", inputData.hsn_code);
    inputData.default_price &&
      formdata.append("default_price", inputData.default_price);
    inputData.regular_buying_price &&
      formdata.append("regular_buying_price", inputData.regular_buying_price);
    inputData.wholesale_buying_price &&
      formdata.append(
        "wholesale_buying_price",
        inputData.wholesale_buying_price,
      );
    inputData.regular_selling_price &&
      formdata.append("regular_selling_price", inputData.regular_selling_price);
    inputData.wholesale_selling_price &&
      formdata.append(
        "wholesale_selling_price",
        inputData.wholesale_selling_price,
      );
    inputData.dealer_price &&
      formdata.append("dealer_price", inputData.dealer_price);
    inputData.distributor_price &&
      formdata.append("distributor_price", inputData.distributor_price);
    inputData.ingredients &&
      formdata.append("ingredients", inputData.ingredients);
    inputData.dosage_form &&
      formdata.append("dosage_form", inputData.dosage_form);
    inputData.filling && formdata.append("filling", inputData.filling);
    inputData.type_of_filling &&
      formdata.append("type_of_filling", inputData.type_of_filling);
    inputData.concentration &&
      formdata.append("concentration", inputData.concentration);
    inputData.packaging_material &&
      (typeof inputData.packaging_material === "object"
        ? formdata.append("packaging_material", inputData.packaging_material)
        : formdata.append(
            "packaging_material",
            inputData.packaging_material?.split("/").pop(),
          ));

    try {
      if (inputData.id) {
        formdata.append("_method", "PUT");
        const apiUrl = process.env.REACT_APP_API_URL as string;
        const BASE_URL = apiUrl + "api/";
        const token = getCookie("authToken");
        const headers = {
          Authorization: `Bearer ${token ? token.replace(/"/g, "") : ""}`,
        };

        const response = await fetch(`${BASE_URL}products/${inputData.id}`, {
          method: "POST",
          headers: headers,
          body: formdata,
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message);
        }
        setToast("success", `${title} Successfully Updated`);
      } else {
        await Product.$query().store(formdata);
        onChangeModalStatus && onChangeModalStatus(false);
        setToast("success", `${title} Successfully Added`);
      }
      if (onChangeModalStatus) {
        onChangeModalStatus(false);
      } else {
        navigate(`${listRoute.toLowerCase().replace(" ", "-")}`);
      }
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (typeof error.response?.data?.message === "object") {
        const dynamicErrors = Object.keys(error.response.data.message)
          .map((key) => error.response.data.message[key])
          .filter((value) => typeof value === "string");

        setFormError(dynamicErrors.join(", "));
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string, customeModel?: any) => {
    if (customeModel) {
      const item = await customeModel.$query().find(itemId);
      return item.$attributes;
    } else {
      const item: any = await Product.$query()
        .with(["category", "unitOfMeasurement", "country"])
        .find(itemId);

      return item.$attributes;
    }
  };
  useEffect(() => {
    DynamicFormFields(formFields, "products")?.then((updatedFormFields) => {
      setDynamicFormFields(updatedFormFields);
    });

    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);

          let data: any = itemData;

          const dynamicAttributeValues = await apiGet(
            `products/${id}/attributes-values`,
          );

          const getDynamicFormFields = await DynamicFormFields(
            formFields,
            "products",
          );

          await Promise.all(
            getDynamicFormFields.map(async (dynamicField: any) => {
              const matchingValue = dynamicAttributeValues.data.find(
                (value: any) =>
                  value.attribute_id === dynamicField.attribute_id,
              );
              let value;
              if (matchingValue) {
                if (dynamicField.type === "select") {
                  const selectedOption = dynamicField.options?.find(
                    (option: any) =>
                      option.value === matchingValue.integer_value,
                  );
                  value = {
                    label: selectedOption
                      ? selectedOption.label
                      : matchingValue.integer_value,
                    value: matchingValue.integer_value,
                  };
                } else if (dynamicField.type === "lookup") {
                  const selectedOption = matchingValue.integer_value;
                  const res = await getFormData(
                    selectedOption,
                    dynamicField.model,
                  );
                  value = {
                    label: CapitalizeAndRemoveUnderscore(
                      res[dynamicField.searchField],
                    ),
                    value: matchingValue.integer_value,
                  };
                } else if (dynamicField.type === "multiselect") {
                  // Handle "multiselect" type
                  const selectedOptionIds =
                    matchingValue.text_value
                      ?.split(",")
                      .map((id: string) => id.trim()) || [];

                  const selectedOptions = dynamicField.options?.filter(
                    (option: any) =>
                      selectedOptionIds.includes(String(option.value)),
                  );

                  value = selectedOptions;
                } else {
                  value =
                    matchingValue.text_value !== null
                      ? matchingValue.text_value
                      : matchingValue.integer_value !== null
                      ? matchingValue.integer_value
                      : matchingValue.float_value !== null
                      ? matchingValue.float_value
                      : matchingValue.boolean_value !== null
                      ? matchingValue.boolean_value
                      : matchingValue.json_value !== null
                      ? matchingValue.json_value
                      : matchingValue.date_value
                      ? matchingValue.date_value
                      : matchingValue.datetime_value
                      ? matchingValue.datetime_value
                      : matchingValue.decimal_value
                      ? matchingValue.decimal_value
                      : null;
                }
                data[dynamicField.name] = value;
              }
            }),
          );

          if (data["category_id"]) {
            data["category_id"] = {
              label: itemData.category["name"],
              value: itemData.category["id"],
            };
          }
          if (data["unit_of_measurement_id"]) {
            data["unit_of_measurement_id"] = {
              label: itemData.unit_of_measurement["name"],
              value: itemData.unit_of_measurement["id"],
            };
          }
          if (data["type"]) {
            data["type"] = {
              label: CapitalizeAndRemoveUnderscore(itemData.type),
              value: itemData.type,
            };
          }
          if (data["country_id"]) {
            data["country_id"] = {
              label: itemData.country["name"],
              value: itemData.country["id"],
            };
          }

          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    // Fetch dynamic attributes when the component mounts

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, cateoryModalStatus, UMOModalStatus]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>

        <AddUpdateForm
          formFields={dynamicFormFields}
          model={Product}
          title={title}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={listRoute}
          dynamicFormError={formError}
          isNotUpdate={isNotUpdate}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>

      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "attachments" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("attachments")}
            >
              Attachments
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && (
              <Notes id={id} model={Product} customModel={"products_id"} />
            )}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"PRODUCTS"} id={id} />
            )}
            {activeTab === "attachments" && (
              <Attachments
                fieldType="products_id"
                moduleType="product"
                id={id}
              ></Attachments>
            )}
          </div>
        </div>
      )}

      <div id="form-bottom" className="mt-4">
        <FieldDescription
          title={title}
          formFields={dynamicFormFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
