import { RouteObject } from "react-router-dom";
import List from "../../Pages/SalesAndPurchase/SalesQuotation/List";
import AddUpdate from "../../Pages/SalesAndPurchase/SalesQuotation/AddUpdate";
import View from "../../Pages/SalesAndPurchase/SalesQuotation/View";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_SALES_QUOTATION,
  DELETE_SALES_QUOTATION,
  UPDATE_SALES_QUOTATION,
  VIEW_SALES_QUOTATION,
} from "../../Util/PermissionList";

export const SALES_QUOTATION_TITLE = "Sales Quotation";
export const SALES_QUOTATION_LIST = "/sales-quotation";
export const SALES_QUOTATION_DASHBOARD_LIST = "/sales-quotation/:date";
export const SALES_QUOTATION_ADD = "/sales-quotation/add-quotation";
export const SALES_QUOTATION_VIEW = "/sales-quotation/:id/view";
export const SALES_QUOTATION_EDIT = "/sales-quotation/:updateid/edit-invoice";

const SalesQuotation: RouteObject[] = [
  {
    path: SALES_QUOTATION_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_SALES_QUOTATION}
        checkPermisson={{
          addPermission: ADD_SALES_QUOTATION,
          updatePermission: UPDATE_SALES_QUOTATION,
          deletePermission: DELETE_SALES_QUOTATION,
          viewPermission: VIEW_SALES_QUOTATION,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: SALES_QUOTATION_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_SALES_QUOTATION}
        checkPermisson={{
          addPermission: ADD_SALES_QUOTATION,
          updatePermission: UPDATE_SALES_QUOTATION,
          deletePermission: DELETE_SALES_QUOTATION,
          viewPermission: VIEW_SALES_QUOTATION,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: SALES_QUOTATION_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_SALES_QUOTATION}
        checkPermisson={{
          addPermission: ADD_SALES_QUOTATION,
          updatePermission: UPDATE_SALES_QUOTATION,
          deletePermission: DELETE_SALES_QUOTATION,
          viewPermission: VIEW_SALES_QUOTATION,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: SALES_QUOTATION_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_SALES_QUOTATION}
        checkPermisson={{
          addPermission: ADD_SALES_QUOTATION,
          updatePermission: UPDATE_SALES_QUOTATION,
          deletePermission: DELETE_SALES_QUOTATION,
          viewPermission: VIEW_SALES_QUOTATION,
        }}
      />
    ),
  },
  {
    path: SALES_QUOTATION_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_SALES_QUOTATION}
        checkPermisson={{
          addPermission: ADD_SALES_QUOTATION,
          updatePermission: UPDATE_SALES_QUOTATION,
          deletePermission: DELETE_SALES_QUOTATION,
          viewPermission: VIEW_SALES_QUOTATION,
        }}
      />
    ),
  },
  {
    path: SALES_QUOTATION_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_SALES_QUOTATION}
        checkPermisson={{
          addPermission: ADD_SALES_QUOTATION,
          updatePermission: UPDATE_SALES_QUOTATION,
          deletePermission: DELETE_SALES_QUOTATION,
          viewPermission: VIEW_SALES_QUOTATION,
        }}
      />
    ),
  },
];

export default SalesQuotation;
