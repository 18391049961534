import React, { ReactNode } from "react";
import EditButton from "./EditButton";
import CustomButton from "../../../Components/Button/CustomButton";

interface CardProps {
  title: string;
  onEdit: () => void;
  children?: ReactNode;
  isHideEditBtn?: boolean;
  buttonTitle?: any;
  width?: string;
  height?: string;
  onArchive?: () => void;
  isHideArchiveBtn?: boolean;
  archiveBtnTitle?: string;
  permissions?: any;
  showArchiveBtn?: any;
}

const Card: React.FC<CardProps> = ({
  title,
  children,
  onEdit,
  isHideEditBtn,
  buttonTitle,
  height,
  width,
  onArchive,
  isHideArchiveBtn,
  archiveBtnTitle,
  showArchiveBtn,
}) => (
  <div style={{ width: width ? width : "49%" }}>
    <div
      style={{
        height: height ? height : "250px",
        border: "1px solid #E7E7E7",
        borderRadius: "10px",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          backgroundColor: "#F8F8F8",
          height: "51px",
          paddingRight: "10px",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "9px 9px 0 0",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            fontSize: "17px",
          }}
        >
          {title}
        </div>
        <div className="d-flex">
          {!isHideEditBtn && (
            <EditButton onClick={onEdit} title={buttonTitle} />
          )}
          {isHideArchiveBtn && archiveBtnTitle && showArchiveBtn && (
            <CustomButton
              onClick={onArchive}
              label={archiveBtnTitle}
              variant="danger"
              type="button"
            />
          )}
          {!isHideArchiveBtn && (
            <CustomButton
              onClick={onArchive}
              label={"Back"}
              variant="secondary"
              type="button"
            />
          )}
        </div>
      </div>
      <div className="p-0">{children}</div>
    </div>
  </div>
);

export default Card;
