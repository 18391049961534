import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectManufacturesQuotations } from "../../Model/Projects/ProjectManufacturesQuotations";
import { Card, Table } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
import CustomButton from "../../Components/Button/CustomButton";

const ViewManufacturersQuotationRequests = () => {
  const { qid } = useParams();
  const [manufacturerDetails, setManufacturerDetails] = useState<any>(null);
  const navigate = useNavigate();

  const getManufacturesQuotations = async (itemId: any) => {
    const item: any = await ProjectManufacturesQuotations.$query()
      .with([
        "organization",
        "project",
        "projectManufactures",
        "projectManufactures.supplier",
        "projectManufactures.supplier.taxType",
        "quotationData",
        "quotationData.country",
      ])
      .find(itemId);

    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data: any = await getManufacturesQuotations(qid);
      setManufacturerDetails(data);
    };
    fetchData();
  }, [qid]);
  const GoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Quotation Received</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton label={`Back`} variant="secondary" onClick={GoBack} />
        </div>
      </div>
      <Card style={{ fontSize: "small" }}>
        <Card.Body className="p-0">
          <Card.Header style={{ fontSize: "20px", fontWeight: 500 }}>
            Manufacturer Details
          </Card.Header>
          {manufacturerDetails?.project_manufactures.supplier && (
            <div className="d-flex justify-content-between p-3">
              <span style={{ fontSize: "25px" }}>
                <Card.Text>
                  <strong>
                    {
                      manufacturerDetails.project_manufactures.supplier
                        .company_name
                    }
                  </strong>
                </Card.Text>
              </span>
              <span className="d-flex p-0">
                <span style={{ marginRight: "50px" }}>
                  <Card.Text className="p-0" style={{ marginBottom: "3px" }}>
                    Company Type:{" "}
                    <strong>
                      {manufacturerDetails.project_manufactures.supplier.company_type
                        .charAt(0)
                        .toUpperCase() +
                        manufacturerDetails.project_manufactures.supplier.company_type.slice(
                          1,
                        )}
                    </strong>{" "}
                  </Card.Text>
                  <Card.Text>
                    Tax Type:{" "}
                    <strong>
                      {
                        manufacturerDetails.project_manufactures.supplier
                          .tax_type.title
                      }
                    </strong>
                  </Card.Text>
                </span>
                <span>
                  <Card.Text>
                    Tax:{" "}
                    <strong>
                      {
                        manufacturerDetails.project_manufactures.supplier
                          .tax_number
                      }
                    </strong>{" "}
                  </Card.Text>
                </span>
              </span>
              <span style={{ marginRight: "100px" }}>
                <Card.Title>Contact Details</Card.Title>
                <Card.Text>
                  <span className="me-3">
                    {" "}
                    {manufacturerDetails.project_manufactures.supplier.email}
                  </span>
                  <span>
                    {" "}
                    {manufacturerDetails.project_manufactures.supplier.phone_no}
                  </span>
                </Card.Text>
              </span>
            </div>
          )}
        </Card.Body>
      </Card>
      <div className="row py-4">
        <h2>Filled Data</h2>
        <Card style={{ overflowX: "scroll" }}>
          <Card.Body>
            <Table className="table table-borderless w-max-content mb-2 bg-transparent">
              <thead className="bg-transparent">
                <tr className="text-center">
                  <th>#</th>
                  <th>Manufacturing Company Name</th>
                  <th>Manufacturing Company Country</th>
                  <th>Pack Size</th>
                  <th>MOQ</th>
                  <th>Price</th>
                  <th>Lead Time</th>
                  <th>Alternative Site/Company</th>
                  <th>Dossier Content</th>
                  <th>Formula</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody className="bg-transparent">
                {manufacturerDetails &&
                  manufacturerDetails.quotation_data.map(
                    (quotation: any, index: any) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.manuf_company_name}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.country.name}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.pack_size}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.MOQ}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.price}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.lead_time}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.alternative_company_site}
                            readOnly
                          />
                        </td>
                        <td className="text-center">
                          <a
                            href={quotation.dossier_content}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Download style={{ fill: "green" }}></Download>
                          </a>
                        </td>
                        <td className="text-center">
                          <a
                            href={quotation.formula}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Download style={{ fill: "green" }}></Download>
                          </a>
                        </td>
                        <td>
                          <input
                            style={{
                              border: 0,
                              borderBottom: "1px solid grey",
                              outline: "none",
                            }}
                            type="text"
                            value={quotation.comment}
                            readOnly
                          />
                        </td>
                      </tr>
                    ),
                  )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ViewManufacturersQuotationRequests;
