import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/UserGroup/List";
import AddUpdate from "../../Pages/Setting/UserGroup/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_USER_GROUP,
  DELETE_USER_GROUP,
  RESTORE_USER_GROUP,
  UPDATE_USER_GROUP,
  VIEW_USER_GROUP,
} from "../../Util/PermissionList";

export const USER_GROUP_TITLE = "User Group";
export const USER_GROUP_LIST = "/users-group";
export const USER_GROUP_ADD = "/users-group/add";
export const USER_GROUP_EDIT = "/users-group/:id/edit";
export const USER_GROUP_ARCHIVE = "/users-group/archive";

const UserGroup: RouteObject[] = [
  {
    path: USER_GROUP_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_USER_GROUP}
        checkPermisson={{
          addPermission: ADD_USER_GROUP,
          updatePermission: UPDATE_USER_GROUP,
          deletePermission: DELETE_USER_GROUP,
          restorePermission: RESTORE_USER_GROUP,
          viewPermission: VIEW_USER_GROUP,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: USER_GROUP_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={USER_GROUP_TITLE} listRoute={USER_GROUP_LIST} />
        }
        permission={ADD_USER_GROUP}
        checkPermisson={{
          addPermission: ADD_USER_GROUP,
          updatePermission: UPDATE_USER_GROUP,
          deletePermission: DELETE_USER_GROUP,
          restorePermission: RESTORE_USER_GROUP,
          viewPermission: VIEW_USER_GROUP,
        }}
      />
    ),
  },
  {
    path: USER_GROUP_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={USER_GROUP_TITLE} listRoute={USER_GROUP_LIST} />
        }
        permission={UPDATE_USER_GROUP}
        checkPermisson={{
          addPermission: ADD_USER_GROUP,
          updatePermission: UPDATE_USER_GROUP,
          deletePermission: DELETE_USER_GROUP,
          restorePermission: RESTORE_USER_GROUP,
          viewPermission: VIEW_USER_GROUP,
        }}
      />
    ),
  },
  {
    path: USER_GROUP_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_USER_GROUP,
          updatePermission: UPDATE_USER_GROUP,
          deletePermission: DELETE_USER_GROUP,
          restorePermission: RESTORE_USER_GROUP,
          viewPermission: VIEW_USER_GROUP,
        }}
        permission={DELETE_USER_GROUP}
      />
    ),
  },
];

export default UserGroup;
