import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Inventory/Category/List";
import AddUpdate from "../../Pages/Inventory/Category/AddUpdate";
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  RESTORE_CATEGORY,
  UPDATE_CATEGORY,
  VIEW_CATEGORY,
} from "../../Util/PermissionList";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import CategoryProducts from "../../Pages/Inventory/Category/Components/CategoryProducts";

export const CATEGORY_TITLE = "Category";
export const CATEGORY_LIST = "/categories";
export const CATEGORY_ADD = "/categories/add";
export const CATEGORY_EDIT = "/categories/:id/edit";
export const CATEGORY_ARCHIVE = "/categories/archive";
export const PRODUCTS_BY_CATEGORY = "/categories/:id/products";

const Category: RouteObject[] = [
  {
    path: CATEGORY_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_CATEGORY}
        checkPermisson={{
          viewPermission: VIEW_CATEGORY,
          addPermission: ADD_CATEGORY,
          updatePermission: UPDATE_CATEGORY,
          deletePermission: DELETE_CATEGORY,
          restorePermission: RESTORE_CATEGORY,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: CATEGORY_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={CATEGORY_TITLE} listRoute={CATEGORY_LIST} />}
        checkPermisson={{
          viewPermission: VIEW_CATEGORY,
          addPermission: ADD_CATEGORY,
          updatePermission: UPDATE_CATEGORY,
          deletePermission: DELETE_CATEGORY,
          restorePermission: RESTORE_CATEGORY,
        }}
        permission={ADD_CATEGORY}
      />
    ),
  },
  {
    path: CATEGORY_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate title={CATEGORY_TITLE} listRoute={CATEGORY_LIST} />}
        permission={UPDATE_CATEGORY}
        checkPermisson={{
          viewPermission: VIEW_CATEGORY,
          addPermission: ADD_CATEGORY,
          updatePermission: UPDATE_CATEGORY,
          deletePermission: DELETE_CATEGORY,
          restorePermission: RESTORE_CATEGORY,
        }}
      />
    ),
  },
  {
    path: CATEGORY_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          viewPermission: VIEW_CATEGORY,
          addPermission: ADD_CATEGORY,
          updatePermission: UPDATE_CATEGORY,
          deletePermission: DELETE_CATEGORY,
          restorePermission: RESTORE_CATEGORY,
        }}
        permission={DELETE_CATEGORY}
      />
    ),
  },
  {
    path: PRODUCTS_BY_CATEGORY,
    element: <AuthorizedRoute element={<CategoryProducts />} />,
  },
];

export default Category;
