import { Model } from "@tailflow/laravel-orion/lib/model";

export class BuyersAndSuppliers extends Model<{
  company_type: any;
  tax_number: string;
  tax_type_id: any;
  company_name: string;
  email: string;
  address_line1: string;
  address_line2: string;
  zip_code: string;
  city: string;
  state: string;
  country_id: any;
  company_tcs_setting?: any;
  country?: any;
  tax_type?: any;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "counter-party-companies";
  }
}
