import "bootstrap/dist/css/bootstrap.min.css";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { CounterPartyTag } from "../../../Model/Setting/CounterPartyTag";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { useState } from "react";

interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  title,
  listRoute,
  isNotUpdate,
  onChangeModalStatus,
}) => {
  const formFields = [
    {
      id: "inputName",
      label: "Name*",
      type: "text",
      col: 12,
      name: "name",
      placeholder: "Enter Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Name is required",
        },
      ],
      description:
        "Enter a unique and descriptive name for the counterparty tag. (Example: 'VIP Client', 'Preferred Supplier', 'Strategic Partner' etc.)",
    },
  ];
  const [isShows, setIsShows] = useState<boolean>(false);
  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={CounterPartyTag}
          title={title}
          listRoute={listRoute}
          isNotUpdate={isNotUpdate}
          onChangeModalStatus={onChangeModalStatus}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
