import { TableColumn } from "react-data-table-component";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomEditButton from "../../Components/Button/CustomEditButton";
import { fetchData } from "../../Util/OrionList";
import { setToast } from "../../Util/Toast";
import CustomDataTable from "../../Components/CustomDataTable";
import { BuyersAndSuppliers } from "../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import {
  BUYERS_AND_SUPPLIERS_ARCHIVE,
  BUYERS_AND_SUPPLIERS_BALANCE_SHEET,
  BUYERS_AND_SUPPLIERS_EDIT,
  BUYERS_AND_SUPPLIERS_LIST,
  BUYERS_AND_SUPPLIERS_TITLE,
} from "../../Routes/BuyersAndSuppliers/BuyersAndSuppliers";
import { Button, Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomButton from "../../Components/Button/CustomButton";
import AddUpdateModel from "./Components/Modal";
import AddUpdateForm from "../../Components/AddUpdateForm";
import { Country } from "../../Model/Setting/Country";
import { TaxType } from "../../Model/Setting/TaxType";
import CustomArchiveButton from "../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../Components/CustomArchiveModal";
import CustomRestoreIconButton from "../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../Components/CustomRestoreModal";
import AddUpdateTaxType from "../Setting/TaxType/AddUpdate";
import { TAX_TYPE_LIST, TAX_TYPE_TITLE } from "../../Routes/Setting/TaxType";
import { Table } from "react-bootstrap-icons";
export interface BuyersAndSuppliersItem {
  id: number;
  company_type: string;
  tax_number: string;
  tax_type_id: any;
  company_name: string;
  email: string;
  address_line1: string;
  address_line2: string;
  zip_code: string;
  city: string;
  state: string;
  country_id: any;
  company_tcs_setting: string;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const { date } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<BuyersAndSuppliersItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [taxTypeModalStatus, setTaxTypeModalStatus] = useState(false);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAddUpdateModel, setShowAddUpdateModel] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === BUYERS_AND_SUPPLIERS_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [isFromDateAvailable, setIsFromDateAvailable] = useState(false);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(BUYERS_AND_SUPPLIERS_EDIT.replace(":id", id.toString()));
  };

  const companyFormFields = [
    {
      id: "inputCompanyType",
      label: "Company Type*",
      type: "select",
      col: 4,
      name: "company_type",
      addNewBtnStatus: false,
      options: [
        { label: "Buyer", value: "buyer" },
        { label: "Supplier", value: "supplier" },
        { label: "Both", value: "both" },
      ],
      placeholder: "Select an Company Type",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Type is required",
        },
      ],
      description:
        "Select the type of business entity: Buyer, Supplier, or Both.",
    },
    {
      id: "inputCompanyName",
      label: "Company Name*",
      type: "text",
      col: 4,
      name: "company_name",
      placeholder: "Enter Your Company Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Name is required",
        },
      ],
      description:
        "Enter the name of the company, ensuring accuracy and consistency.",
    },
    {
      id: "inputEmail",
      label: "Email*",
      type: "text",
      col: 4,
      name: "email",
      placeholder: "Enter Your Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Please enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is required",
        },
      ],
      description:
        "Provide the email address associated with the company for communication.",
    },
    {
      id: "inputPhoneNumber",
      label: "Phone Number",
      type: "phone_number",
      col: 4,
      name: "phone_number",
      placeholder: "Enter Your Phone Number",
      description: "Enter Your Phone Number.",
    },
    {
      id: "inputTaxNumber",
      label: "Tax Number*",
      type: "text",
      col: 4,
      name: "tax_number",
      placeholder: "Enter Your Tax Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Tax Number is required",
        },
      ],
      description:
        "Include the Value Added Tax (VAT) number for taxation purposes.",
    },
    {
      id: "inputFaxNumber",
      label: "Fax Number",
      type: "text",
      col: 4,
      name: "fax_no",
      placeholder: "Enter Your Fax Number",
      description: "Enter Your Fax Number.",
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 4,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is required",
        },
        {
          rule: (value: any) => value && value.length >= 5,
          message: "The address line1 field must be at least 5 characters.",
        },
      ],
      description: "Specify the physical address of the company.",
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 4,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "Zip Code*",
      type: "text",
      col: 4,
      name: "zip_code",
      placeholder: "Enter Your Zip Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Zip Code is required",
        },
      ],
      description:
        "Enter the postal code or Zip code associated with the company's location.",
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 4,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is required",
        },
      ],
      description: "Provide the city in which the company is situated.",
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 4,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is required",
        },
      ],
      description: "Specify the state or region of the company's location.",
    },
    {
      id: "inputcompany_tcs_setting",
      label: "Company Tax Collection At Source Setting*",
      type: "select",
      addNewBtnStatus: false,
      col: 4,
      name: "company_tcs_setting",
      placeholder: "Select an Company Tax Collection At Source Setting",
      options: [
        { label: "None", value: "none" },
        { label: "Purchase", value: "purchase" },
        { label: "Sales ", value: "sales" },
        { label: "Both", value: "both" },
      ],
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Company Tax Collection At Source Setting is required",
        },
      ],
      description:
        "Select the Tax Collection at Source (TCS) setting for the company.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 4,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Choose the country in which the company is located.",
    },
    {
      id: "inputtax_types_id",
      label: "Tax Type*",
      type: "select",
      col: 4,
      name: "tax_type_id",
      placeholder: "Select an TaxType",
      searchField: "title",
      model: TaxType,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "TaxType is required",
        },
      ],
      description:
        "Select the applicable tax type for the company, such as GST, VAT, etc.",
      onChangeModalStatus: (status: boolean) => setTaxTypeModalStatus(status),
      modalStatus: taxTypeModalStatus,
      addNewBtnStatus: true,
      widget: (
        <AddUpdateTaxType
          title={TAX_TYPE_TITLE}
          listRoute={TAX_TYPE_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setTaxTypeModalStatus(status)
          }
        />
      ),
    },
  ];

  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    setShowAddUpdateModel(true);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(BUYERS_AND_SUPPLIERS_ARCHIVE);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleCompanyDetailsClose = () => setShowAddUpdateModel(false);

  const columns: TableColumn<BuyersAndSuppliersItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Company Name",
      cell: (row) => row.company_name,
    },
    {
      name: "Type",
      cell: (row) => {
        const companyType = row.company_type.toLowerCase();
        return companyType.charAt(0).toUpperCase() + companyType.slice(1);
      },
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Deleted At",
      cell: (row: any) => row.deleted_at,
      omit: !isArchive,
    },
    {
      name: "Actions",
      button: true,
      minWidth: "20%",
      style: {
        "justify-content": "right",
        "margin-right": "15px",
      },
      omit: !permissions.update && !permissions.delete,
      cell: (row) => (
        <div>
          {isArchive ? (
            permissions.restore && (
              <CustomRestoreIconButton
                id={row.id}
                onClick={handleRowRestoreButtonClick}
              />
            )
          ) : (
            <>
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  navigate(
                    BUYERS_AND_SUPPLIERS_BALANCE_SHEET.replace(
                      ":id",
                      row.id.toString(),
                    ),
                  );
                }}
              >
                Balance Sheet{" "}
                <span className="ms-1">
                  <Table />
                </span>
              </Button>
              {permissions.update && (
                <CustomEditButton
                  id={row.id}
                  onClick={handleRowEditButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomArchiveButton
                  id={row.id}
                  onClick={handleRowArchiveButtonClick}
                />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);
  // @ts-ignore
  const fetchTax = useCallback(async () => {
    try {
      setLoading(true);
      const params = date ? new URLSearchParams(date) : null;
      const fromDate = params?.get("fromDate");
      if (fromDate) {
        setIsFromDateAvailable(true);
      } else {
        setIsFromDateAvailable(false);
      }
      const filters = {
        ...(fromDate
          ? { created_at: { filter_operation: ">=", value: fromDate } }
          : {}),
        ...(inputValues ? inputValues : {}),
      };

      await fetchData(
        page,
        limit,
        filters,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new BuyersAndSuppliers(),
        sortField,
        sortOrder,
        isArchive,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  }, [
    date,
    page,
    limit,
    inputValues,
    sortField,
    sortOrder,
    isArchive,
    navigate,
  ]);
  useEffect(() => {
    fetchTax();
  }, [fetchTax]);

  useEffect(() => {
    setIsArchive(location.pathname === BUYERS_AND_SUPPLIERS_ARCHIVE);
    setInitialFetchDone(true);
  }, [location]);

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "company_name",
      value: "company_name",
      label: "Company Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "company_type",
      value: "company_type",
      label: "Type",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const handleCompanyCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    // Creating a new object with the updated values
    const updatedData = {
      ...formData,
      tax_type_id: parseInt(formData.tax_type_id.value),
      country_id: parseInt(formData.country_id.value),
      company_type: formData.company_type.value,
      company_tcs_setting: formData.company_tcs_setting.value,
    };

    try {
      const response = await BuyersAndSuppliers.$query().store(updatedData);
      const id = (response.$attributes as { id: number | string }).id;
      handleCompanyDetailsClose();
      setToast("success", `${BUYERS_AND_SUPPLIERS_TITLE} Successfully Added`);
      navigate(BUYERS_AND_SUPPLIERS_EDIT.replace(":id", id.toString()));
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };
  const handlePageChange = (page: any) => {
    setPage(page);
  };
  useEffect(() => {
    fetchTax();
  }, [limit, page]); // eslint-disable-line
  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchTax();
    }
  }, [isArchive]); // eslint-disable-line
  const handleGoBack = () => {
    navigate(BUYERS_AND_SUPPLIERS_LIST);
  };
  const handleGotoDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <div>
          <h2>
            {BUYERS_AND_SUPPLIERS_TITLE} {isArchive ? "Archive" : "List"}
          </h2>
        </div>
        <div>
          {isFromDateAvailable && (
            <button
              type="button"
              className="btn btn-secondary btn-sm mb-1"
              onClick={handleGotoDashboard}
            >
              Back
            </button>
          )}
        </div>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchTax}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              {permissions.add && (
                <CustomButton
                  label={`Add`}
                  variant="success"
                  onClick={handleRowCreateButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomButton
                  label={`Archive`}
                  variant="danger"
                  onClick={handleArchiveButtonClick}
                  customeClass="ms-2"
                />
              )}
            </>
          )}
        </Col>
      </Row>
      <div className="pb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"bs"} // Pass optional fields to CustomDataTable
        />
      </div>
      <AddUpdateModel
        title={`Add ${BUYERS_AND_SUPPLIERS_TITLE}`}
        show={showAddUpdateModel}
        size={"xl"}
        handleClose={() => {
          handleCompanyDetailsClose();
          setFormError(null);
        }}
        formFields={companyFormFields}
        widget={
          <AddUpdateForm
            formFields={companyFormFields}
            model={BuyersAndSuppliers}
            title="Buyers&Suppliers"
            customTitle=" "
            customFormSubmit={handleCompanyCustomSubmit}
            isBackBtnDisable={true}
            dynamicFormError={formError}
            submitButtonLabel="Submit And Fill Next Step"
            customSubmitting={customSubmitting}
          />
        }
      />
      <CustomArchiveModal
        title={BUYERS_AND_SUPPLIERS_TITLE}
        model={BuyersAndSuppliers}
        getData={fetchTax}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={BUYERS_AND_SUPPLIERS_TITLE}
        model={BuyersAndSuppliers}
        getData={fetchTax}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
    </>
  );
}

export default List;
