import { TableColumn } from "react-data-table-component";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { User } from "../../../Model/Setting/User";
import {
  USER_EDIT,
  USER_ADD,
  USER_TITLE,
  USER_ARCHIVE,
  USER_LIST,
} from "../../../Routes/Setting/User";
import { fetchData } from "../../../Util/OrionList";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { setToast } from "../../../Util/Toast";
import { Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomButton from "../../../Components/Button/CustomButton";
import pluralize from "pluralize";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";

interface UserItem {
  id: number;
  first_name: string;
  last_name: string;
  phone_no: string;
  email: string;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const { date } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<UserItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === USER_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);
  const [isFromDateAvailable, setIsFromDateAvailable] = useState(false);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(USER_EDIT.replace(":id", id.toString()));
  };
  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(USER_ADD);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(USER_ARCHIVE);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const columns: TableColumn<UserItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "First Name",
      cell: (row) => row.first_name,
    },
    {
      name: "Last Name",
      cell: (row) => row.last_name,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Deleted At",
      cell: (row: any) => row.deleted_at,
      omit: !isArchive,
    },
    {
      name: "Actions",
      button: true,
      omit: !permissions.update && !permissions.delete,
      cell: (row) => (
        <div>
          {isArchive ? (
            permissions.restore && (
              <CustomRestoreIconButton
                id={row.id}
                onClick={handleRowRestoreButtonClick}
              />
            )
          ) : (
            <>
              {permissions.update && (
                <CustomEditButton
                  id={row.id}
                  onClick={handleRowEditButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomArchiveButton
                  id={row.id}
                  onClick={handleRowArchiveButtonClick}
                />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  // @ts-ignore
  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);
      const params = date ? new URLSearchParams(date) : null;
      const fromDate = params?.get("fromDate");
      if (fromDate) {
        setIsFromDateAvailable(true);
      } else {
        setIsFromDateAvailable(false);
      }
      const filters = {
        ...(fromDate
          ? { created_at: { filter_operation: ">=", value: fromDate } }
          : {}),
        ...(inputValues ? inputValues : {}),
      };

      await fetchData(
        page,
        limit,
        filters,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new User(),
        sortField,
        sortOrder,
        isArchive,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  }, [
    date,
    page,
    limit,
    inputValues,
    sortField,
    sortOrder,
    isArchive,
    navigate,
  ]);
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchUser();
  }, [limit, page]); // eslint-disable-line
  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchUser();
    }
  }, [isArchive]); // eslint-disable-line
  useEffect(() => {
    setIsArchive(location.pathname === USER_ARCHIVE);
    setInitialFetchDone(true);
  }, [location]);

  const handleGoBack = () => {
    navigate(USER_LIST);
  };
  const handleGotoDashboard = () => {
    navigate("/");
  };
  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "first_name",
      value: "first_name",
      label: "First Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "last_name",
      value: "last_name",
      label: "Last Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">
                {pluralize(USER_TITLE)} {isArchive ? "Archive" : "List"}
              </h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchUser}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              {permissions.add && (
                <CustomButton
                  label={`Add`}
                  variant="success"
                  onClick={handleRowCreateButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomButton
                  label={`Archive`}
                  variant="danger"
                  onClick={handleArchiveButtonClick}
                  customeClass="ms-2"
                />
              )}
            </>
          )}
          {isFromDateAvailable && (
            <button
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={handleGotoDashboard}
            >
              Back
            </button>
          )}
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"User"} // Pass optional fields to CustomDataTable
        />
      </div>
      <CustomArchiveModal
        title={USER_TITLE}
        model={User}
        getData={fetchUser}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={USER_TITLE}
        model={User}
        getData={fetchUser}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
    </>
  );
}

export default List;
