import moment from "moment";

export function DateFormat(date: any, customFormat?: any) {
  const format = customFormat ?? "YYYY-MM-DD";
  const modifiedDate = moment(date).format(format);
  return modifiedDate;
}

export function DateFormatWithStartOFTheDay(date: any, format: any) {
  if (date && format) {
    if (format.includes("HH:MM:SS")) {
      format = format.replace("HH:MM:SS", "HH:mm:ss");
      return moment(date).format(format);
    } else if (format.includes("HH:MM")) {
      format = format.replace("HH:MM", "HH:mm");
      return moment(date).format(format);
    } else {
      return moment(date).format(format);
    }
  }
}

export function DateWithoutTime(date: any) {
  return date?.split(" ")[0];
}

export function DateString(date: any, dateFormat: any) {
  if (date && dateFormat) {
    return moment(date, dateFormat.replace("dd", "DD")).format(
      "ddd MMM DD YYYY",
    );
  }
}
