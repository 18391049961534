import { Model } from "@tailflow/laravel-orion/lib/model";

export class Attributes extends Model<{
  code: string;
  name: string;
  type: any;
  entity_type: any;
  sort_order: boolean;
  is_required: boolean;
  is_unique: boolean;
  quick_add: boolean;
  is_user_defined: boolean;
  validation: any;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "attributes";
  }
}
