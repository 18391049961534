import { Container } from "react-bootstrap";
import { getCookie } from "typescript-cookie";
import { Outlet, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Style/App.css";
function Layout() {
  if (getCookie("authToken")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="full-screen full-height">
      <Container className="sigin-box">
        <div className="">
          <Outlet />
        </div>
      </Container>
    </div>
  );
}

export default Layout;
