import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { fetchData } from "../../../Util/OrionList";
import { setToast } from "../../../Util/Toast";
import CustomButton from "../../../Components/Button/CustomButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import {
  BATCH_MANAGEMENT_ADD,
  BATCH_MANAGEMENT_EDIT,
  BATCH_MANAGEMENT_TITLE,
  BATCH_MANAGEMENT_VIEW,
} from "../../../Routes/SalesAndPurchase/BatchManagement/BatchManagement";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import CustomViewButton from "../../../Components/Button/CustomViewButton";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import EditStockModal from "../../Inventory/StockInventory/AddUpdate";

export interface BatchManagementItem {
  id: number;
  created_at: string;
  updated_at: string;
  batch_number: string;
  current_stock: string;
  change_quantity: number;
  expiry_date: string;
  description: string;
  concentration: string;
  product: any;
  pack_size: number;
}

function List({ permissions }: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<BatchManagementItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [includeWith] = useState(["product"]);
  const [showEditStockModal, setShowEditStockModal] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(BATCH_MANAGEMENT_EDIT.replace(":id", id.toString()));
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(BATCH_MANAGEMENT_ADD);
  };
  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(BATCH_MANAGEMENT_VIEW.replace(":id", id.toString()));
  };

  const handleEditStockClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    batch: any,
  ) => {
    e.preventDefault();
    const selectedBatch: any = {
      products_id: {
        label: batch.product?.product_name,
        value: batch.product?.id,
      },
      batches_id: { label: batch.batch_number, value: batch?.id },
    };
    setSelectedBatch(selectedBatch);
    setShowEditStockModal(true);
  };

  const columns: TableColumn<BatchManagementItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Batch Number",
      cell: (row) => row.batch_number,
    },
    {
      name: "Current Stock",
      width: "15%",
      style: { display: "flow" },
      cell: (row) => (
        <div className="current-stock-cell">
          <div className="stock-and-change">
            <span>{row.current_stock}</span>
            <span
              style={{ marginLeft: "5px", fontSize: "10px", fontWeight: 600 }}
              className={
                row.change_quantity > 0 ? "text-success" : "text-danger"
              }
            >
              {row.change_quantity > 0 ? (
                <ArrowUp color="green" />
              ) : (
                <ArrowDown color="red" />
              )}
              {row.change_quantity}
            </span>
          </div>
          <span className="edit-button-span">
            <CustomEditButton
              id={row.id}
              onClick={(e: any) => {
                handleEditStockClick(e, row);
              }}
            />
          </span>
        </div>
      ),
    },
    {
      name: "Description",
      cell: (row) => <span> {row.description}</span>,
    },
    {
      name: "Product Name",
      cell: (row) => <span> {row.product.product_name}</span>,
    },
    {
      name: "Concentration",
      cell: (row) => row.concentration,
    },
    {
      name: "Pack Size",
      cell: (row) => row.pack_size,
    },
    {
      name: "Expiry Date",
      cell: (row: any) => row.expiry_date,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Actions",
      button: true,
      omit: !permissions.update && !permissions.delete,
      cell: (row: any) => (
        <div>
          <CustomViewButton
            id={row.id}
            onClick={(e) => {
              return handleRowViewButtonClick(e, row.id);
            }}
          />
          {permissions.update && (
            <CustomEditButton id={row.id} onClick={handleRowEditButtonClick} />
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  // @ts-ignore
  const fetchBatchManagement = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new BatchManagement(),
        sortField,
        sortOrder,
        false,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchBatchManagement();
  }, [limit, page]); // eslint-disable-line

  const handleCloseEditStockPopup = () => {
    fetchBatchManagement();
    setShowEditStockModal(false);
  };

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "batch_number",
      value: "batch_number",
      label: "Batch Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "description",
      value: "description",
      label: "Description",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "product.product_name",
      value: "product.product_name",
      label: "Product Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "concentration",
      value: "concentration",
      label: "Concentration",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "pack_size",
      value: "pack_size",
      label: "Pack Size",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "expiry_date",
      value: "expiry_date",
      label: "Expiry Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">{BATCH_MANAGEMENT_TITLE} List</h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchBatchManagement}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add && (
            <CustomButton
              label={`Add`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
        </Col>
      </Row>
      <div className="pb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"BatchManagement"} // Pass optional fields to CustomDataTable
        />
      </div>
      <EditStockModal
        show={showEditStockModal}
        handleClose={handleCloseEditStockPopup}
        batch={selectedBatch}
      />
    </>
  );
}

export default List;
