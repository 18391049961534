import React, { useEffect, useState } from "react";
import { useApi } from "../Controller/ApiController";
import "bootstrap/dist/css/bootstrap.min.css";
import { CapitalizeAndRemoveUnderscore } from "../Util/CapitalizeAndRemoveUnderscore";
import { useNavigate } from "react-router-dom";
import { USER_DASHBOARD_LIST } from "../Routes/Setting/User";
import { BUYERS_AND_SUPPLIERS_DASHBOARD_LIST } from "../Routes/BuyersAndSuppliers/BuyersAndSuppliers";
import { PRODUCT_DASHBOARD_LIST } from "../Routes/Inventory/Product";
import { PROJECTS_DASHBOARD_LIST } from "../Routes/Project/Project";
import { PURCHASE_DASHBOARD_LIST } from "../Routes/SalesAndPurchase/Purchase";
import { SALES_QUOTATION_DASHBOARD_LIST } from "../Routes/SalesAndPurchase/SalesQuotation";
import { TAX_INVOICE_DASHBOARD_LIST } from "../Routes/SalesAndPurchase/TaxInvoice";
import { INWARD_DOCUMENT_DASHBOARD_LIST } from "../Routes/SalesAndPurchase/InwardDocument";
import { SALES_RETURN_DASHBOARD_LIST } from "../Routes/SalesAndPurchase/SalesReturn";
import { setToast } from "../Util/Toast";
function Dashboard() {
  const [dashboardData, setDashboardData] = useState<any>({});
  const { apiGet } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await apiGet("dashboard");
      setDashboardData(response);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setToast("error", error.response.data.errors);
      } else if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  // Map to match data keys with related icons
  const dataIcons: any = {
    user: (
      <img
        src="/images/svg/user.svg"
        alt="User Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    buyer_supplier: (
      <img
        src="/images/svg/buyers_suppliers.svg"
        alt="Buyer/Supplier Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    product: (
      <img
        src="/images/svg/product.svg"
        alt="Product Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    project: (
      <img
        src="/images/svg/project.svg"
        alt="Project Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    purchaseOrder: (
      <img
        src="/images/svg/purchase_order.svg"
        alt="Purchase Order Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    salesQuotation: (
      <img
        src="/images/svg/sales_quotation.svg"
        alt="Sales Quotation Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    taxInvoice: (
      <img
        src="/images/svg/tax_invoice.svg"
        alt="Tax Invoice Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    inwardDocument: (
      <img
        src="/images/svg/inward_document.svg"
        alt="Inward Document Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    salesReturn: (
      <img
        src="/images/svg/sales_return.svg"
        alt="Sales Return Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    grossMonthlySelling: (
      <img
        src="/images/svg/gross_monthly_selling.svg"
        alt="Gross Monthly Selling Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    grossMonthlyPurchaseOrder: (
      <img
        src="/images/svg/gross_monthly_purchase_order.svg"
        alt="Gross Monthly Purchase Order Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    grossMonthlySalesQuotations: (
      <img
        src="/images/svg/gross_monthly_sales_quotations.svg"
        alt="Gross Monthly Sales Quotations Icon"
        className="icon"
        style={{ width: "50px", height: "50px" }}
      />
    ),
  };

  // Desired display order for the data
  const displayOrder = [
    "user",
    "buyer_supplier",
    "product",
    "project",
    "purchaseOrder",
    "salesQuotation",
    "taxInvoice",
    "inwardDocument",
    "salesReturn",
    "grossMonthlySelling",
    "grossMonthlyPurchaseOrder",
    "grossMonthlySalesQuotations",
  ];

  const getModule: any = (module: any) => {
    const currentDate = new Date(); // Get current date
    const last30Days = new Date(currentDate);
    last30Days.setDate(currentDate.getDate() - 30); // Set date to 30 days ago

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const dateFilter = `fromDate=${formatDate(last30Days)}&toDate=${formatDate(
      currentDate,
    )}`;

    switch (module) {
      case "user":
        navigate(USER_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "buyer_supplier":
        navigate(
          BUYERS_AND_SUPPLIERS_DASHBOARD_LIST.replace(":date", dateFilter),
        );
        break;

      case "product":
        navigate(PRODUCT_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "project":
        navigate(PROJECTS_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "purchaseOrder":
        navigate(PURCHASE_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "salesQuotation":
        navigate(SALES_QUOTATION_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "taxInvoice":
        navigate(TAX_INVOICE_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "inwardDocument":
        navigate(INWARD_DOCUMENT_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      case "salesReturn":
        navigate(SALES_RETURN_DASHBOARD_LIST.replace(":date", dateFilter));
        break;

      default:
        break;
    }
  };

  const renderDashboardCards = () => {
    if (!dashboardData) return null;

    return (
      <div className="row mb-4">
        {displayOrder.map((key, index) => (
          <div key={key} className="col-md-3 mb-4">
            {" "}
            <div
              className="card shadow-lg"
              style={{ height: "100px", cursor: "pointer" }}
              onClick={() => {
                getModule(key);
              }}
            >
              <div className="card-body d-flex align-items-center justify-content-between">
                {dataIcons[key]}
                <div className="ms-3 text-end">
                  <p
                    className="card-title text-capitalize"
                    style={{ fontSize: "14px", lineHeight: "18px" }}
                  >
                    {CapitalizeAndRemoveUnderscore(
                      key.replace(/([A-Z])/g, " $1"),
                    )}
                  </p>
                  <p className="card-text" style={{ fontSize: "20px" }}>
                    {dashboardData[key] ?? 0}
                  </p>{" "}
                  {/* Display value */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Dashboard Overview</h2>
      {renderDashboardCards()}
    </div>
  );
}

export default Dashboard;
