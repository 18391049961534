import { Model } from "@tailflow/laravel-orion/lib/model";

export class CounterPartyTag extends Model<{
  name: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "counter-party-tags";
  }
}
