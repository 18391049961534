import { Model } from "@tailflow/laravel-orion/lib/model";

export class DocumentNumberFormat extends Model<{
  document_type: any;
  document_series_type: any;
  series_name: string;
  prefix_number: string;
  next_number: string;
  reset_type: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "document-number-formats";
  }
}
