import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { User } from "../../../Model/Setting/User";
import { USER_LIST, USER_TITLE } from "../../../Routes/Setting/User";
import { setToast } from "../../../Util/Toast";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import { Role } from "../../../Model/Setting/Role";
import { useApi } from "../../../Controller/ApiController";
import { UserType } from "../../../Model/Setting/UserType";
import Notes from "../../../Components/Notes";
import ActivityLogs from "../../../Components/ActivityLogs";
import { UserGroup } from "../../../Model/Setting/UserGroup";
import {
  USER_TYPE_LIST,
  USER_TYPE_TITLE,
} from "../../../Routes/Setting/UserType";
import AddUpdateUserType from "../../../Pages/Setting/UserType/AddUpdate";
import AddUpdateUserGroup from "../../../Pages/Setting/UserGroup/AddUpdate";
import AddUpdateRole from "../../../Pages/Setting/Role/AddUpdate";
import {
  USER_GROUP_LIST,
  USER_GROUP_TITLE,
} from "../../../Routes/Setting/UserGroup";
import { ROLE_LIST, ROLE_TITLE } from "../../../Routes/Setting/Role";

const AddUpdate = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const { apiGet, apiPatch } = useApi();
  const [isShows, setIsShows] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [customSubmitting, setCustomSubmitting] = useState(false);
  const [rolesModalStatus, setRolesModalStatus] = useState<boolean>(false);
  const [userTypeModalStatus, setUserTypeModalStatus] =
    useState<boolean>(false);
  const [userGroupModalStatus, setUserGroupModalStatus] =
    useState<boolean>(false);

  const formFields = [
    {
      id: "inputfirst_name",
      label: "First Name*",
      type: "text",
      col: 6,
      name: "first_name",
      placeholder: "Enter First Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "First Name is required",
        },
      ],
      description: "Enter the first name of the new sub-user.",
    },
    {
      id: "inputlast_name",
      label: "Last Name*",
      type: "text",
      col: 6,
      name: "last_name",
      placeholder: "Enter Last Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Last Name is required",
        },
      ],
      description: "Enter the last name of the new sub-user.",
    },
    {
      id: "inputphone_no",
      label: "Phone Number*",
      type: "phone_no",
      col: 6,
      name: "phone_no",
      placeholder: "Enter Phone Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Phone No is required",
        },
      ],
      description: "Provide a phone number for the new sub-user.",
    },
    {
      id: "inputemail",
      label: "Email*",
      type: "email",
      col: 6,
      name: "email",
      placeholder: "Enter Email",
      validate: [
        {
          rule: (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          message: "Please enter a valid email address",
        },
        {
          rule: (value: any) => !!value,
          message: "Email is required",
        },
      ],
      description: "Enter the email address associated with the new sub-user.",
    },
    {
      id: "inputrole",
      label: "Role*",
      type: "select",
      col: 6,
      name: "role_id",
      placeholder: "Select a Role",
      searchField: "name",
      model: Role,
      isMultiSelect: true,
      validate: [
        {
          rule: (value: any) => Array.isArray(value) && value.length > 0,
          message: "Role is required",
        },
      ],
      description: "Select the Role of user From the dropdown box.",
      onChangeModalStatus: (status: boolean) => setRolesModalStatus(status),
      modalStatus: rolesModalStatus,
      addNewBtnStatus: true,
      widget: (
        <AddUpdateRole
          title={ROLE_TITLE}
          listRoute={ROLE_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setRolesModalStatus(status)}
        />
      ),
    },
    {
      id: "inputUserTypeId",
      label: "User Type",
      type: "select",
      col: 6,
      name: "user_type_id",
      placeholder: "Select an User Type",
      searchField: "name",
      model: UserType,
      description: "Select the User type from the dropdown box.",
      onChangeModalStatus: (status: boolean) => setUserTypeModalStatus(status),
      modalStatus: userTypeModalStatus,
      addNewBtnStatus: true,
      widget: (
        <AddUpdateUserType
          title={USER_TYPE_TITLE}
          listRoute={USER_TYPE_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setUserTypeModalStatus(status)
          }
        />
      ),
    },
    {
      id: "inputUserGroupId",
      label: "User Group",
      type: "select",
      col: 6,
      name: "user_group_id",
      placeholder: "Select an User Group",
      searchField: "title",
      model: UserGroup,
      description: "Select the User Group from the dropdown box.",
      isMultiSelect: true,
      onChangeModalStatus: (status: boolean) => setUserGroupModalStatus(status),
      modalStatus: userGroupModalStatus,
      addNewBtnStatus: true,
      widget: (
        <AddUpdateUserGroup
          title={USER_GROUP_TITLE}
          listRoute={USER_GROUP_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setUserGroupModalStatus(status)
          }
        />
      ),
    },
  ];

  const getFormData = async (itemId: string) => {
    const item: any = await User.$query()
      .with(["parentId", "organization", "userTypeId", "userGroups"])
      .find(itemId);

    return item.$attributes;
  };

  const navigate = useNavigate();
  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = { ...formData };
    if (inputData.parent_id?.value !== undefined) {
      const userIdNumber = parseInt(inputData.parent_id.value);
      inputData.parent_id = userIdNumber;
    }

    let userRoles;
    if (inputData.role_id !== undefined) {
      const roleArray = inputData.role_id.map((d: any) => {
        return d.value;
      });

      userRoles = roleArray;
    }

    if (inputData.user_type_id !== undefined) {
      const userTypeIdNumber = parseInt(inputData.user_type_id?.value);
      inputData.user_type_id = userTypeIdNumber;
    }

    let userGroups: any = [];
    if (inputData.user_group_id !== undefined) {
      inputData?.user_group_id?.map((ug: any) => {
        return userGroups.push(ug.value);
      });
    }
    const ugData = {
      resources: userGroups,
    };
    try {
      if (inputData.id) {
        await apiPatch(`users/${id}/user-groups/sync`, ugData);
        let item = await User.$query().find(inputData.id);

        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };
        if (inputData.parent_id?.value == null) {
          delete item.$attributes.parent_id;
        }
        item = await item.$save();

        await apiPatch(`users/${inputData.id}/roles/sync`, {
          resources: userRoles,
        });
        setToast("success", `${USER_TITLE} Successfully Updated`);
      } else {
        let user = await User.$query().store(inputData);
        await apiPatch(`users/${user.$attributes.id}/roles/sync`, {
          resources: userRoles,
        });
        await apiPatch(`users/${user.$attributes.id}/user-groups/sync`, ugData);
        setToast("success", `${USER_TITLE} Successfully Added`);
      }
      navigate(`${USER_LIST.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data = Object.assign({}, itemData);

          let roleUserGet = await apiGet(`users/${id}/roles`);
          const roles = roleUserGet.data.map((role: any) => {
            return {
              label: role.name,
              value: role.id,
            };
          });
          data["role_id"] = roles;

          const userGroup = data.user_groups.map((ug: any) => {
            return {
              label: ug.title,
              value: ug.id,
            };
          });
          data["user_group_id"] = userGroup;

          if (data["parent_id"]) {
            data["parent_id"] = {
              label: itemData.parent_id["email"],
              value: itemData.parent_id["id"],
            };
          }
          if (data["user_type_id"]) {
            data["user_type_id"] = {
              label: itemData.user_type_id["name"],
              value: itemData.user_type_id["id"],
            };
          }
          if (data["organization"]) {
            data["company_name"] = itemData.organization["company_name"];
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <>
      <div>
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={User}
          title={USER_TITLE}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={USER_LIST}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
        />
      </div>
      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && <Notes id={id} model={User} />}
            {activeTab === "activityLogs" && (
              <ActivityLogs auditable_type={"USER"} id={id} />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={USER_TITLE}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
