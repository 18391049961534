import "bootstrap/dist/css/bootstrap.min.css";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { DocumentNumberFormat } from "../../../Model/Setting/DocumentNumberFormat";
import {
  DOCUMENT_NUMBER_FORMAT_LIST,
  DOCUMENT_NUMBER_FORMAT_TITLE,
} from "../../../Routes/Setting/DocumentNumberFormat";
import { setToast } from "../../../Util/Toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FieldDescription from "../../../Components/FieldDescription";
import Notes from "../../../Components/Notes";
import Alert from "react-bootstrap/Alert";
import ActivityLogs from "../../../Components/ActivityLogs";
import CustomSkeleton from "../../../Components/CustomSkeleton";

const AddUpdate = () => {
  let title = DOCUMENT_NUMBER_FORMAT_TITLE;
  const navigate = useNavigate();
  const { id } = useParams();
  const { document_type } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingForPreviewPrefix, setLoadingForPreviewPrefix] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("notes");
  const [currentData, setCurrentData] = useState<any>();
  const [previewPrefix, setPreviewPrefix] = useState<string>("");
  const [showDocTemp, setShowDocTemp] = useState<boolean>(false);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status

  useEffect(() => {
    var currentDate = new Date();
    const prefix_template = currentData?.prefix_template
      ?.replace(
        "[[month]]",
        currentDate.toLocaleString("en", { month: "2-digit" }),
      )
      ?.replace(
        "[[cal_year]]",
        currentDate.toLocaleString("en", { year: "2-digit" }),
      )
      .replace("[[inc_number]]", currentData.next_number);
    setPreviewPrefix(prefix_template);
  }, [currentData]);

  useEffect(() => {
    // Set loading to false only when previewPrefix is updated
    if (previewPrefix) {
      setLoadingForPreviewPrefix(false);
    }
  }, [previewPrefix]);

  const formFields = [
    {
      id: "inputdocument_series_type",
      label: "Document Series Type",
      type: "switch",
      col: 6,
      name: "document_series_type",
    },
    {
      id: "inputseries_name",
      label: "Series Name*",
      type: "text",
      col: 6,
      name: "series_name",
      placeholder: "Enter Series Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "series name is required",
        },
      ],
    },
    {
      id: "inputnext_number",
      label: "Next Number*",
      type: "text",
      col: 6,
      name: "next_number",
      placeholder: "Enter Next Number",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "next number is required",
        },
      ],
    },
    {
      id: "inputprefix_template",
      label: "Prefix Template*",
      type: "text",
      col: 5,
      name: "prefix_template",
      placeholder: "Enter Prefix Template",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "prefix template is required",
        },
      ],
    },
    {
      id: "inputbutton",
      label: "",
      type: "button",
      col: 1,
      name: "type",
      button: {
        variant: "primary",
        type: "button",
        label: "Need Help?",
        buttonDivClass: "w-100 d-flex justify-content-start my-1",
        onClick: () => {
          setShowDocTemp(!showDocTemp);
        },
      },
    },
  ];

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);

    const documentSeriesType =
      inputData.document_series_type === undefined
        ? "manual"
        : inputData.document_series_type === true
        ? "auto"
        : "manual";

    // Updating the object with the converted values
    inputData.document_series_type = documentSeriesType;
    inputData.document_type = document_type;

    try {
      if (inputData.id) {
        let item = await DocumentNumberFormat.$query().find(inputData.id);

        item.$attributes = {
          ...item.$attributes,
          ...inputData,
        };

        item = await item.$save();
        setToast("success", `${title} Successfully Updated`);
      } else {
        await DocumentNumberFormat.$query().store(inputData);
        setToast("success", `${title} Successfully Added`);
      }
      navigate(DOCUMENT_NUMBER_FORMAT_LIST);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string) => {
    const item: any = await DocumentNumberFormat.$query().find(itemId);

    return item.$attributes;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data = itemData;
          if (data["document_series_type"]) {
            data["document_series_type"] =
              itemData.document_series_type === "auto" ? true : false;
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const svgIcon =
    '<svg style="width: 14px; height: 14px; version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 115.77 122.88" style="enable-background:new 0 0 115.77 122.88" xml:space="preserve"><style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style><g><path class="st0" d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"/></g></svg>';
  const CustomFormatInstructions: any = [
    {
      description: `<h4>Custom Format Instructions</h4><h5>Auto Updating Template </h5><p>Lets assume some examples -</p><ul><li><p>PEPSI/PO/2023-2024/0001 (PO by Pepsico) : PEPSI/PO/20[[fin_year_p1]]-[[fin_year_p2]]/[[inc_number]]</p></li><li><p>HIND/INV/0223/12/23 (Invoice by Hindalco) : HIND/INV/[[inc_number]]/[[month]]/[[cal_year]]</p></li><li><p>REL/RAW/0560 (Item ID by Reliance) :: REL/RAW/[[inc_number]]</p></li></ul><p>Use following MAGIC CODES in 'Template' input field to generate personalised custom format</p><table><tbody><tr><td colspan='1' rowspan='1'><p id='copyMonthValue'>[[month]]</p></td><td colspan='1' rowspan='1'><p><button class="copy-button" id='copyMonthButton' style="margin-left:50px; margin-right:50px">
      ${svgIcon}
      <span>Copy</span>
    </button></p></td><td colspan='1' rowspan='1'><p>Denotes current month.<br>Eg: for today's date (05/12/2023), it is - <strong>12</strong></p></td></tr><tr><td colspan='1' rowspan='1'><p id='copyYearValue'>[[cal_year]]</p></td><td colspan='1' rowspan='1'><p><button class="copy-button" id='copyYearButton' style="margin-left:50px; margin-right:50px">
    ${svgIcon}
    <span>Copy</span>
  </button></p></td><td colspan='1' rowspan='1'><p>Denotes <strong>last 2 digits</strong> of current Calendar Year.<br>Eg: for today's date (05/12/2023), it is - <strong>23</strong></p></td></tr><tr><td colspan='1' rowspan='1'><p id='copyNumberValue'>[[inc_number]]</p></td><td colspan='1' rowspan='1'><p><button class="copy-button" id='copyNumberButton' style="margin-left:50px; margin-right:50px">
  ${svgIcon}
  <span>Copy</span>
</button></p></td><td colspan='1' rowspan='1'><p>Denotes the next number in the ongoing series</p></td></tr></tbody></table><p>[Note: If you do not mention [[inc_number]] in the Template input field, the Next Number will be added to the Template]</p>`,
    },
  ];
  const formFieldsForDocumentNumberFormat: any = [
    {
      description: `<h4 data-pm-slice='1 3 []'>Instructions </h4><p>Prefix</p><p>Next Number</p><p>Next Document Number</p><h5>Auto Reset Series </h5><p>Auto reset 'Next Number' in number series based on chosen duration. It can be one of the followings:</p><ul><li><p>Month : Series is reset on 1st day of every month</p></li><li><p>Calendar Year : Series is reset on 01 Jan of every year</p></li><li><p>Financial Year : Series is reset on 01 April of every year</p></li></ul>`,
    },
  ];

  const copyMonthButton = document.getElementById("copyMonthButton");
  const copyYearButton = document.getElementById("copyYearButton");
  const copyNumberButton = document.getElementById("copyNumberButton");
  copyMonthButton?.addEventListener("click", () => {
    const copyMonth = document.getElementById("copyMonthValue")?.innerText;
    if (copyMonth) {
      navigator.clipboard.writeText(copyMonth);
    }
  });
  copyNumberButton?.addEventListener("click", () => {
    const copyNumber = document.getElementById("copyNumberValue")?.innerText;
    if (copyNumber) {
      navigator.clipboard.writeText(copyNumber);
    }
  });
  copyYearButton?.addEventListener("click", () => {
    const copyYear = document.getElementById("copyYearValue")?.innerText;
    if (copyYear) {
      navigator.clipboard.writeText(copyYear);
    }
  });
  return (
    <>
      <div>
        <span
          style={{
            float: "inline-end",
          }}
        >
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={DocumentNumberFormat}
          title={title}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          dynamicFormError={formError}
          onChangeValue={(data) => {
            setCurrentData(data);
          }}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
        />
        {!loadingForPreviewPrefix ? (
          <div className="container">
            <Alert
              key="warning"
              variant={previewPrefix?.length > 0 ? "warning" : "danger"}
            >
              <div className="text-center">
                <h6 className="text-primary">Next Document Number Preview</h6>
                <h1>
                  {previewPrefix?.length > 0
                    ? previewPrefix
                    : "Please Enter Document Number"}
                </h1>
              </div>
            </Alert>
          </div>
        ) : (
          <div className="container">
            <CustomSkeleton
              count={1}
              height={95}
              customOuterStyle={{ marginTop: "20px", width: "100%" }}
            />
          </div>
        )}
        <FieldDescription
          title={title}
          formFields={CustomFormatInstructions}
          isShows={showDocTemp}
        />
      </div>
      {id && (
        <div className="card mt-4" style={{ marginBottom: "30px" }}>
          <div className="card-header d-flex">
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "notes" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("notes")}
            >
              Notes
            </h5>
            <h5
              style={{ cursor: "pointer", fontSize: "17px" }}
              className={`mb-0 me-3 ${
                activeTab === "activityLogs" ? "text-primary" : ""
              }`}
              onClick={() => setActiveTab("activityLogs")}
            >
              Activity Logs
            </h5>
          </div>

          <div
            className="card-body"
            style={{
              height: "auto",
              overflow: "auto",
              backgroundColor: "#e4e4e4",
            }}
          >
            {activeTab === "notes" && (
              <Notes id={id} model={DocumentNumberFormat} />
            )}
            {activeTab === "activityLogs" && (
              <ActivityLogs
                auditable_type={"DOCUMENT_NUMBER_FORMATS"}
                id={id}
              />
            )}
          </div>
        </div>
      )}
      <div id="form-bottom">
        <FieldDescription
          title={title}
          formFields={formFieldsForDocumentNumberFormat}
          isShows={isShows}
        />
      </div>
    </>
  );
};

export default AddUpdate;
