import React, { useEffect, useState } from "react";
import CustomAsyncSelect from "../AsyncSelect";
import CustomEditButton from "../Button/CustomEditButton";
import AddUpdateForm from "../AddUpdateForm";
import { Country } from "../../Model/Setting/Country";
import { setToast } from "../../Util/Toast";
import CustomCard from "../CustomCard";
import CustomSkeleton from "../CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";

interface AddressSelecterPros {
  loadingStatus?: boolean;
  title: string;
  isChild: boolean;
  isPratent: boolean;
  parentModal?: any;
  parentInputfield?: string;
  isParentNeedFullData?: boolean;
  parentFieldName?: string;
  isParentMultiSelect?: boolean;
  parentFormData?: string;
  parentLabel?: string;
  setSelectedParentValue?: any;
  selectedParentValue?: any;
  addParentNewBtnStatus?: boolean;
  parentCustomFilter?: any[];
  parentIncludeWith?: string;
  childModal?: any;
  childInputfield?: string;
  isChildNeedFullData?: boolean;
  childFieldName?: string;
  isChildMultiSelect?: boolean;
  childFormData?: string;
  childLabel?: string;
  addChildNewBtnStatus?: boolean;
  childCustomFilter?: any[];
  childIncludeWith?: string;
  childWidget?: any;
  formTitle?: any;
  formModal?: any;
  selectedValue?: any;
  setSelectedValue?: any;
  errorValues?: any;
  setErrorValues?: any;
  isChildDisabled?: boolean;
  isParentDisabled?: boolean;
  childFormAddressType?: any;
}
const AddressSelecter: React.FC<AddressSelecterPros> = ({
  loadingStatus,
  title,
  isPratent,
  parentModal,
  parentInputfield,
  parentFieldName,
  isParentMultiSelect,
  parentFormData,
  parentLabel,
  addParentNewBtnStatus,
  parentCustomFilter,
  isParentNeedFullData,
  parentIncludeWith,
  setSelectedParentValue,
  selectedParentValue,
  isChild,
  childModal,
  childInputfield,
  isChildNeedFullData,
  childFieldName,
  isChildMultiSelect,
  childFormData,
  childLabel,
  addChildNewBtnStatus,
  childCustomFilter,
  childIncludeWith,
  childWidget,
  formTitle,
  formModal,
  selectedValue,
  setSelectedValue,
  errorValues,
  setErrorValues,
  isChildDisabled,
  isParentDisabled,
  childFormAddressType,
}) => {
  const [reloadOptionsKey, setReloadOptionsKey] = useState<number>(0);
  const [selectedPratentOption, setSelectedPratentOption] = useState<any>({
    label: "",
    value: "",
  });
  const [selectedChildOption, setSelectedChildOption] = useState<any>({
    label: "",
    value: "",
  });
  const [selecteAddress, setSelecteAddress] = useState<any | null>(null);
  const [deliveryFormData, setDeliveryFormData] = useState<any | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false);
  const [childModalStatus, setChildModalStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const selectPratentOption = (option: any) => {
    if (isPratent) {
      setSelectedPratentOption(option);
      setSelectedParentValue(option);
      setSelectedChildOption({ label: "", value: "" });
      setSelecteAddress(null);
      // setReloadOptionsKey((prevKey) => prevKey + 1);
    }
  };

  const getAddressFormData = async () => {
    const item = await formModal
      .$query()
      .with([childIncludeWith])
      .find(selectedChildOption.value);
    return item.$attributes;
  };

  useEffect(() => {
    if (!childModalStatus) {
      setDeliveryFormData(null);
      setFormError(null);
    }
  }, [childModalStatus]);

  const deliveryFormFields = [
    {
      id: "inputAddressName",
      label: "Address Name*",
      type: "text",
      col: 12,
      name: "address_name",
      placeholder: "Enter Your Address Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Name is required",
        },
      ],
    },
    {
      id: "inputaddress_line1",
      label: "Address Line 1*",
      type: "text",
      col: 6,
      name: "address_line1",
      placeholder: "Enter Your Address Line 1",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Address Line 1 is required",
        },
      ],
    },
    {
      id: "inputaddress_line2",
      label: "Address Line 2",
      type: "text",
      col: 6,
      name: "address_line2",
      placeholder: "Enter Your Address Line 2",
    },
    {
      id: "inputPinCode",
      label: "Pin Code*",
      type: "text",
      col: 6,
      name: "zip_code",
      placeholder: "Enter Your Pin Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Pin Code is required",
        },
      ],
    },
    {
      id: "inputCity",
      label: "City*",
      type: "text",
      col: 6,
      name: "city",
      placeholder: "Enter Your City",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "City is required",
        },
      ],
    },
    {
      id: "inputState",
      label: "State*",
      type: "text",
      col: 6,
      name: "state",
      placeholder: "Enter Your State",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "State is required",
        },
      ],
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select an Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
    },
    {
      id: "inputEmail",
      label: "Email",
      type: "text",
      col: 6,
      name: "email",
      placeholder: "Enter Your Email",
      description:
        "Provide the email address associated with the company for communication.",
    },
    {
      id: "inputPhoneNumber",
      label: "Phone Number",
      type: "phone_no",
      col: 6,
      name: "phone_no",
      placeholder: "Enter Your Phone Number",
    },
    {
      id: "inputFaxNumber",
      label: "Fax Number",
      type: "text",
      col: 6,
      name: "fax_no",
      placeholder: "Enter Your Fax Number",
    },
  ];

  const handleSelectChange = (option: any) => {
    setSelectedChildOption(option);
    setSelecteAddress(option.data);
  };

  useEffect(() => {
    if (!loadingStatus) {
      setLoading(false);
    }
    if (selectedValue && Object.keys(selectedValue).length > 0) {
      setLoading(false);
    }
    if (isPratent) {
      setSelectedPratentOption({
        label: selectedValue?.counter_party_company?.company_name,
        value: selectedValue?.counter_party_company?.id,
      });
      setSelectedParentValue({
        label: selectedValue?.counter_party_company?.company_name,
        value: selectedValue?.counter_party_company?.id,
      });
    }
    if (isChild) {
      setSelectedChildOption({
        label: selectedValue?.address_name,
        value: selectedValue?.id,
      });
    }
    setSelecteAddress(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    if (
      !isPratent ||
      (isChild && childCustomFilter && childCustomFilter?.length > 0)
    ) {
      if (
        selectedParentValue?.value !== selectedValue?.counter_party_company_id
      ) {
        setSelectedChildOption({ label: "", value: "" });
        setSelecteAddress(null);
      } else {
        setSelectedChildOption({
          label: selectedValue?.address_name,
          value: selectedValue?.id,
        });
        setSelecteAddress(selectedValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childCustomFilter]);

  useEffect(() => {
    if (
      selectedChildOption?.value &&
      selectedChildOption?.label &&
      setErrorValues
    ) {
      setErrorValues("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChildOption]);
  useEffect(() => {
    if (isChild && selectedParentValue?.value) {
      setReloadOptionsKey((prevKey) => prevKey + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParentValue]);

  useEffect(() => {
    if (selecteAddress != null && setSelectedValue) {
      setSelectedValue((prevValue: any) => {
        if (JSON.stringify(prevValue) !== JSON.stringify(selecteAddress)) {
          return selecteAddress;
        }
        return prevValue;
      });
    }
  }, [selecteAddress, setSelectedValue]);
  const handleAddressCustomSubmit = async (formData: any) => {
    let inputData = Object.assign({}, formData);

    setCustomSubmitting(true);
    inputData.country_id = parseInt(inputData.country_id.value);
    inputData.counter_party_company_id = selectedParentValue?.value;
    inputData.address_type = childFormAddressType;
    try {
      if (inputData.id) {
        let item: any = await childModal
          .$query()
          .update(inputData.id, inputData);
        setToast("success", `Successfully Updated`);
        setDeliveryFormData([item.$attributes]);
        const seletedData = await getAddressFormData();
        setSelectedChildOption({
          label: seletedData.address_name,
          value: seletedData.id,
        });
        setSelecteAddress(seletedData);
      } else {
        await childModal.$query().store(inputData);
        setToast("success", ` Successfully Added`);
      }
      setChildModalStatus(false);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setReloadOptionsKey((prevKey) => prevKey + 1);
      setCustomSubmitting(false);
    }
  };

  return (
    <CustomCard
      title={title}
      onEdit={() => {}}
      isHideEditBtn={true}
      customStyle={{
        // cardHight: "300px",
        cardHight: "100%",
        titleDivHeight: "50px",
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px",
      }}
    >
      {loading ? (
        <CustomSkeleton
          height={202}
          customOuterStyle={{ padding: "0" }}
          customInnerStyle={{ borderRadius: "0 0 0.375rem 0.375rem" }}
          containerClassName={"d-flex"}
        />
      ) : (
        <div style={{ padding: "15px" }}>
          {isPratent && (
            <CustomAsyncSelect
              isdefaultselected={true}
              model={parentModal}
              inputfield={parentInputfield}
              fieldName={parentFieldName}
              placeholder={"Select Address"}
              isMultiSelect={isParentMultiSelect ?? false}
              formData={parentFormData}
              label={parentLabel}
              isdisabled={isParentDisabled ?? false}
              addNewBtnStatus={addParentNewBtnStatus}
              onChange={selectPratentOption}
              customValue={selectedPratentOption && selectedPratentOption}
              customFilter={parentCustomFilter}
              isNeedFullData={isParentNeedFullData ?? false}
            />
          )}

          {isChild && (
            <div className="mt-3">
              <CustomAsyncSelect
                isdefaultselected={true}
                componentKey={reloadOptionsKey} // Pass reloadOptionsKey as the key prop
                model={isChildDisabled ? "" : childModal}
                placeholder={"Select Address"}
                inputfield={childInputfield}
                fieldName={childFieldName}
                isMultiSelect={isChildMultiSelect ?? false}
                formData={childFormData}
                label={childLabel}
                isNeedFullData={isChildNeedFullData}
                onChange={handleSelectChange}
                includeWith={childIncludeWith}
                // customComponents={MenuList}
                isdisabled={isChildDisabled}
                customValue={selectedChildOption}
                addNewBtnStatus={addChildNewBtnStatus}
                customFilter={childCustomFilter}
                modalStatus={childModalStatus}
                onChangeModalStatus={(status) => {
                  setChildModalStatus(status);
                }}
                widget={
                  <AddUpdateForm
                    formFields={deliveryFormFields}
                    model={formModal}
                    title={formTitle}
                    customFormSubmit={(data) => {
                      handleAddressCustomSubmit(data);
                    }}
                    dynamicFormData={deliveryFormData}
                    isBackBtnDisable={false}
                    dynamicFormError={formError}
                    isNotUpdate={!deliveryFormData}
                    isUpdate={deliveryFormData && true}
                    customSubmitting={customSubmitting}
                    onChangeModalStatus={(status) => {
                      setChildModalStatus(status);
                    }}
                  />
                }
              />
            </div>
          )}
          {selecteAddress &&
            selectedValue &&
            typeof selectedValue === "object" &&
            Object.keys(selectedValue).length > 0 && (
              <div
                className="me-auto mt-2 d-flex justify-content-between"
                style={{ fontSize: "small" }}
              >
                <div style={{ overflowWrap: "anywhere" }}>
                  <div style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {selecteAddress?.address_name} -{" "}
                    {selecteAddress?.counter_party_company?.company_name ??
                      selecteAddress?.organization?.company_name}
                  </div>
                  <p className="mb-0">
                    {selecteAddress?.address_line1},{" "}
                    {selecteAddress?.address_line2}
                  </p>
                  {selecteAddress?.phone_no && (
                    <p className="mb-0">{selecteAddress?.phone_no},</p>
                  )}
                  {selecteAddress?.email && (
                    <p className="mb-0">{selecteAddress?.email},</p>
                  )}
                  {selecteAddress?.fax_no && (
                    <p className="mb-0">{selecteAddress?.fax_no}</p>
                  )}
                  <p className="mb-0">
                    {selecteAddress?.city},{selecteAddress?.state},
                    {selecteAddress?.country?.name} -{selecteAddress?.zip_code}
                  </p>
                </div>
                <div>
                  {isChild && (
                    <CustomEditButton
                      onClick={async () => {
                        try {
                          const itemData = await getAddressFormData();
                          let data = itemData;
                          if (data["country_id"]) {
                            data["country_id"] = {
                              label: itemData.country["name"],
                              value: itemData.country["id"],
                            };
                          }

                          if (data["address_type"]) {
                            data["address_type"] = {
                              label: CapitalizeAndRemoveUnderscore(
                                itemData.address_type,
                              ),
                              value: itemData.address_type,
                            };
                          }
                          setDeliveryFormData(data);
                          setChildModalStatus(true);
                        } catch (error: any) {
                          if (error.response.status === 404) {
                            setToast(
                              "error",
                              "Address not found or it is been deleted.",
                            );
                          } else {
                            setToast("error", error.response.data.message);
                          }
                        }
                      }}
                      id={0}
                    />
                  )}
                </div>
              </div>
            )}
          {errorValues && (
            <div className="error-message text-danger">{errorValues}</div>
          )}
        </div>
      )}
    </CustomCard>
  );
};

export default AddressSelecter;
