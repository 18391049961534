import React, { useEffect, useState } from "react";
import { Badge, Col, Overlay, Popover, Row } from "react-bootstrap";
import CustomButton from "../../Components/Button/CustomButton";
import {
  PAYMENT_ADD,
  PAYMENT_EDIT,
  PAYMENT_TITLE,
} from "../../Routes/Payment/Payment";
import pluralize from "pluralize";
import CustomDataTable from "../../Components/CustomDataTable";
import { useLocation, useNavigate } from "react-router-dom";
import { TableColumn } from "react-data-table-component";
import { fetchData } from "../../Util/OrionList";
import { setToast } from "../../Util/Toast";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import { Payment } from "../../Model/Setting/Payment";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";
import CustomEditButton from "../../Components/Button/CustomEditButton";
import CustomDownloadButton from "../../Components/Button/CustomDownloadButton";
import { useApi } from "../../Controller/ApiController";
import { JournalText } from "react-bootstrap-icons";

interface PaymentItem {
  id: number;
  tax_invoice: any;
  invoice_id: number;
  amount: number;
  buyer: any;
  buyer_id: number;
  supplier: any;
  supplier_id: number;
  payment_mode: string;
  payment_reference_number: string;
  payment_type: string;
  status: string;
  document_number: string;
  payment_date: string;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const { apiGet } = useApi();
  const [data, setData] = useState<PaymentItem[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [totalRows, setTotalRows] = useState(0);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [loading, setLoading] = useState(false);
  const [sortField] = useState<string>("id");
  const [sortOrder] = useState<string>("desc");
  const [includeWith] = useState(["buyer", "supplier", "taxInvoice"]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [noteData, setNoteData] = useState<any>(null);
  const [isOpenNoteData, setIsOpenNoteData] = useState<any>(false);
  const [target, setTarget] = useState(null);

  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchPayment();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setInitialFetchDone(true);
  }, [location]);

  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PAYMENT_ADD);
  };

  const openNote = (event: any, data: any) => {
    setTarget(event.target);
    setNoteData({ payment_type: data.payment_type, note: data.notes });
    setIsOpenNoteData(true);
  };

  const columns: TableColumn<PaymentItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Document Number",
      cell: (row) => row.document_number,
    },
    {
      name: "Invoice Number",
      cell: (row) => row.tax_invoice?.document_number,
    },
    {
      name: "Amount",
      cell: (row) => row.amount,
    },
    {
      name: "Buyer",
      cell: (row) => row.buyer?.company_name,
    },
    // {
    //   name: "Supplier",
    //   cell: (row) => row.supplier?.company_name,
    // },
    {
      name: "Payment Mode",
      cell: (row) => CapitalizeAndRemoveUnderscore(row.payment_mode),
    },
    {
      name: "Payment Type",
      cell: (row) => CapitalizeAndRemoveUnderscore(row.payment_type),
    },
    {
      name: "Status",
      minWidth: "165px",
      cell: (row) => (
        <Badge
          pill
          bg={
            row.status === "draft"
              ? "secondary"
              : row.status === "sent"
              ? "warning"
              : row.status === "partially-paid" ||
                row.status === "partially-refunded"
              ? "info"
              : row.status === "paid"
              ? "success"
              : row.status === "refunded"
              ? "danger"
              : "success"
          }
        >
          {CapitalizeAndRemoveUnderscore(row.status)}
        </Badge>
      ),
    },
    {
      name: "Payment Reference Number",
      cell: (row: any) => row.payment_reference_number,
    },
    {
      name: "Payment Date",
      cell: (row: any) => row.payment_date,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Actions",
      button: true,
      style: {
        justifyContent: "end",
        position: "sticky", // Fix the column
        right: 0, // Stick to the right
        backgroundColor: "#fff",
      },
      ignoreRowClick: true, // Prevent triggering row click events
      allowOverflow: true, // Prevent clipping in scrollable table

      omit: !permissions.update && !permissions.delete,
      cell: (row: any) => (
        <div
          className="d-flex justify-content-end"
          style={{ background: "#f8f9fa" }}
        >
          <button
            className="btn btn-xs border-0"
            onClick={(e) => {
              if (row.notes) {
                openNote(e, row);
              }
            }}
            disabled={!row.notes}
          >
            <JournalText style={{ fill: row.notes ? "green" : "grey" }} />
          </button>
          {permissions.update && (
            <CustomEditButton id={row.id} onClick={handleRowEditButtonClick} />
          )}
          <CustomDownloadButton
            title="Download Receipt"
            id={row.id}
            onClick={handlePDFDownloadButtonClick}
          />
        </div>
      ),
    },
  ];

  // @ts-ignore
  const fetchPayment = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Payment(),
        sortField,
        sortOrder,
        false,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  useEffect(() => {
    fetchPayment();
  }, [limit, page]); // eslint-disable-line

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "taxInvoice.document_number",
      value: "tax_invoice_number",
      label: "Tax Invoice",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "amount",
      value: "amount",
      label: "Amount",
      fieldType: "number",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "payment_type",
      value: "payment_type",
      label: "Payment Type",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "status",
      value: "status",
      label: "Status",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "payment_mode",
      value: "payment_mode",
      label: "Payment Mode",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "payment_date",
      value: "payment_date",
      label: "Payment Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "payment_reference_number",
      value: "payment_reference_number",
      label: "Payment Reference Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PAYMENT_EDIT.replace(":id", id.toString()));
  };
  const handlePDFDownloadButtonClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    try {
      const item = await apiGet(`payment/receipt/${id}`);
      window.open(item.pdf_url);
    } catch (error) {
      console.error("Error fetching data:", error);
      setToast("error", "Something went wrong while fetching the PDF.");
    }
  };

  const onHide: any = () => {
    setIsOpenNoteData(false);
  };

  return (
    <>
      <Row>
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">{pluralize(PAYMENT_TITLE)} List</h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchPayment}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          <>
            {permissions.add && (
              <CustomButton
                label={`Add`}
                variant="success"
                onClick={handleRowCreateButtonClick}
              />
            )}
          </>
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"Payemnt"} // Pass optional fields to CustomDataTable
        />
      </div>

      <Overlay
        show={isOpenNoteData}
        target={target}
        placement="left"
        containerPadding={20}
        onHide={onHide}
        rootClose={onHide}
      >
        <Popover style={{ width: "max-content" }} id="popover-contained">
          <Popover.Header as="h3">
            {CapitalizeAndRemoveUnderscore(noteData?.payment_type) + " Note"}
          </Popover.Header>
          <Popover.Body style={{ maxHeight: "450px", overflow: "auto" }}>
            {noteData?.note}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

export default List;
