import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { Role } from "../../../Model/Setting/Role";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROLE_ADD,
  ROLE_ARCHIVE,
  ROLE_EDIT,
  ROLE_LIST,
  ROLE_TITLE,
} from "../../../Routes/Setting/Role";
import { fetchData } from "../../../Util/OrionList";
import { ROLE_PERMISSION_EDIT } from "../../../Routes/Setting/RolePermission";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { setToast } from "../../../Util/Toast";
import CustomButton from "../../../Components/Button/CustomButton";
import pluralize from "pluralize";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";

interface RoleItem {
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
}

function List() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<RoleItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === ROLE_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(ROLE_EDIT.replace(":id", id.toString()));
  };
  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(ROLE_ADD);
  };

  const handleRowRolePermissionButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(ROLE_PERMISSION_EDIT.replace(":id", id.toString()));
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(ROLE_ARCHIVE);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const RolePermissionCell: React.FC<{ row: RoleItem }> = ({ row }) => {
    return (
      <CustomEditButton
        id={row.id}
        isDisabled={
          row.name === "ROLE_ORG_ADMIN" ||
          (row.name === "ROLE_SUB_USER" && true)
        }
        onClick={handleRowRolePermissionButtonClick}
      />
    );
  };

  const RoleActionsCell: React.FC<{ row: RoleItem }> = ({ row }) => {
    return (
      <div>
        {isArchive ? (
          <CustomRestoreIconButton
            id={row.id}
            onClick={handleRowRestoreButtonClick}
            isDisabled={
              row.name === "ROLE_ORG_ADMIN" ||
              (row.name === "ROLE_SUB_USER" && true)
            }
          />
        ) : (
          <>
            <CustomEditButton
              id={row.id}
              isDisabled={
                row.name === "ROLE_ORG_ADMIN" ||
                (row.name === "ROLE_SUB_USER" && true)
              }
              onClick={handleRowEditButtonClick}
            />
            <CustomArchiveButton
              id={row.id}
              isDisabled={
                row.name === "ROLE_ORG_ADMIN" ||
                (row.name === "ROLE_SUB_USER" && true)
              }
              onClick={handleRowArchiveButtonClick}
            />
          </>
        )}
      </div>
    );
  };

  const columns: TableColumn<RoleItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Name",
      cell: (row) => row.name,
    },
    {
      name: "Slug",
      cell: (row) => row.slug,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Deleted At",
      cell: (row: any) => row.deleted_at,
      omit: !isArchive,
    },
    {
      name: "RolePermission",
      button: true,
      cell: (row) => <RolePermissionCell row={row} />,
      omit: isArchive,
    },
    {
      name: "Actions",
      button: true,
      cell: (row) => <RoleActionsCell row={row} />,
    },
  ];

  // @ts-ignore
  const fetchrole = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Role(),
        sortField,
        sortOrder,
        isArchive,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchrole();
  }, [limit, page]); // eslint-disable-line

  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchrole();
    }
  }, [isArchive]); // eslint-disable-line

  useEffect(() => {
    setIsArchive(location.pathname === ROLE_ARCHIVE);
    setInitialFetchDone(true);
  }, [location]);

  const handleGoBack = () => {
    navigate(ROLE_LIST);
  };

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  return (
    <>
      <div className="mb-4">
        <h2>
          {pluralize(ROLE_TITLE)} {isArchive ? "Archive" : "List"}
        </h2>
      </div>
      <Row>
        <CustomFilter
          options={filterOptions}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          getData={fetchrole}
          setPage={setPage}
        />
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              <CustomButton
                label={`Add`}
                variant="success"
                onClick={handleRowCreateButtonClick}
              />
              <CustomButton
                label={`Archive`}
                variant="danger"
                onClick={handleArchiveButtonClick}
                customeClass="ms-2"
              />
            </>
          )}
        </Col>
      </Row>
      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"Role"} // Pass optional fields to CustomDataTable
        />
      </div>
      <CustomArchiveModal
        title={ROLE_TITLE}
        model={Role}
        getData={fetchrole}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={ROLE_TITLE}
        model={Role}
        getData={fetchrole}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
    </>
  );
}

export default List;
