import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/DocumentNumberFormat/List";
import AddUpdate from "../../Pages/Setting/DocumentNumberFormat/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_DOCUMENT_NUMBER_FORMAT,
  DELETE_DOCUMENT_NUMBER_FORMAT,
  UPDATE_DOCUMENT_NUMBER_FORMAT,
  VIEW_DOCUMENT_NUMBER_FORMAT,
} from "../../Util/PermissionList";

export const DOCUMENT_NUMBER_FORMAT_TITLE = "Document Number Format";
export const DOCUMENT_NUMBER_FORMAT_LIST = "/document-number-formats";
export const DOCUMENT_NUMBER_FORMAT_ADD = "/document-number-formats/add";
export const DOCUMENT_NUMBER_FORMAT_EDIT =
  "/document-number-formats/:id/:document_type/edit";

const DocumentNumberFormat: RouteObject[] = [
  {
    path: DOCUMENT_NUMBER_FORMAT_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_DOCUMENT_NUMBER_FORMAT}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_NUMBER_FORMAT,
          updatePermission: UPDATE_DOCUMENT_NUMBER_FORMAT,
          deletePermission: DELETE_DOCUMENT_NUMBER_FORMAT,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: DOCUMENT_NUMBER_FORMAT_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_DOCUMENT_NUMBER_FORMAT}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_NUMBER_FORMAT,
          updatePermission: UPDATE_DOCUMENT_NUMBER_FORMAT,
          deletePermission: DELETE_DOCUMENT_NUMBER_FORMAT,
        }}
      />
    ),
  },
  {
    path: DOCUMENT_NUMBER_FORMAT_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_DOCUMENT_NUMBER_FORMAT}
        checkPermisson={{
          addPermission: ADD_DOCUMENT_NUMBER_FORMAT,
          updatePermission: UPDATE_DOCUMENT_NUMBER_FORMAT,
          deletePermission: DELETE_DOCUMENT_NUMBER_FORMAT,
        }}
      />
    ),
  },
];

export default DocumentNumberFormat;
