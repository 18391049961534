import React, { useEffect, useState } from "react";
import { SALES_RETURN_TITLE } from "../../../Routes/SalesAndPurchase/SalesReturn";
import ConfirmationModal from "../../../Components/ConfirmationModel";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import AddressSelecter from "../../../Components/Address/Address";
import { CounterPartyAddress } from "../../../Model/BuyersAndSuppliers/CounterPartyAddress/CounterPartyAddress";
import { BuyersAndSuppliers } from "../../../Model/BuyersAndSuppliers/BuyersAndSuppliers";
import { OrganizationAddress } from "../../../Model/Setting/OrganizationAddress";
import FormSkeleton from "../../../Components/FormSkeleton";
import CustomButton from "../../../Components/Button/CustomButton";
import { useApi } from "../../../Controller/ApiController";
import DatePicker from "react-datepicker";
import { useDateFormat } from "../../../Util/InputDateFormate";
import moment from "moment";
import CustomAsyncSelect from "../../../Components/AsyncSelect";
import { TaxInvoice } from "../../../Model/SalesAndPurchase/TaxInvoice/TaxInvoice";
import {
  DateFormatWithStartOFTheDay,
  DateString,
} from "../../../Util/DateFormat";
import CreateRow from "../../../Components/CreateRow";
import { BatchManagement } from "../../../Model/SalesAndPurchase/BatchManagement/BatchManagement";
import AddUpdateUMO from "../../Setting/UnitOfMeasurement/AddUpdate";
import AddUpdateProduct from "../../Inventory/Product/AddUpdate";
import { UnitOfMeasurement } from "../../../Model/Setting/UnitOfMeasurement";
import {
  UNIT_OF_MEASUREMENT_LIST,
  UNIT_OF_MEASUREMENT_TITLE,
} from "../../../Routes/Setting/UnitOfMeasurement";
import { setToast } from "../../../Util/Toast";
import { SalesReturns } from "../../../Model/SalesAndPurchase/SalesReturn/SalesReturn";
import { Product } from "../../../Model/Inventory/Product";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../../../Routes/Inventory/Product";
import { showErrorsInToasts } from "../../../Util/ErrorMessageToast";
import AddUpdateStore from "../../Setting/Store/AddUpdate";
import { Store } from "../../../Model/Setting/Store";
import { STORE_LIST, STORE_TITLE } from "../../../Routes/Setting/Store";
import AttachmentsForDocuments from "../../../Components/AttachmentsForDocuments";
import SignatureForDocuments from "../../../Components/SignatureForDocuments";

type ProductDetails = {
  position: number | null;
  batches_id: number | null;
  products_id: number | null;
  unit_of_measurement_id: number | null;
  quantity: number | null;
  comments: string;
};

const AddUpdate = () => {
  const navigate = useNavigate();
  const dateFormat = useDateFormat();
  const { apiGet } = useApi();
  const { tax_invoice_id, id } = useParams();

  const [showConfirmationForGoBack, setShowConfirmationForGoBack] =
    useState(false);
  const [UMOModalStatus, setUMOModalStatus] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  // const [status, setStatus] = useState(true);
  const [formData, setFormData] = useState<any>([]);
  const [taxInvoiceData, setTaxInvoiceData] = useState<any>(null);
  const [tiDate, setTIDate] = useState<any>(null);
  // Data Store State
  const [selectedCompanyOption, setSelectedCompanyOption] = useState({
    label: "",
    value: "",
  });
  const [showComments, setShowComments] = useState(false);
  const [storeError, setStoreError] = useState("");
  const [storeModalStatus, setStoreModalStatus] = useState<boolean>(false);
  const [hideShowField, setHideShowField] = useState<any>([
    { id: 1, label: "comments", fieldStatus: false },
  ]);
  const [productDetails] = useState<ProductDetails>({
    position: null,
    batches_id: null,
    products_id: null,
    unit_of_measurement_id: null,
    quantity: null,
    comments: "",
  });
  const [transporterName, setTransporterName] = useState<any>(null);
  const [transporterDocName, setTransporterDocName] = useState<any>(null);
  const [documentnumber, setDocumentNumber] = useState<any | null>(null);
  const [documentDate, setDocumentDate] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [deliveryDate, setDeliveryDate] = useState<any>(null);
  const [vehicleNumber, setVehicleNumber] = useState<any>(null);
  const [transportationDocumentDate, setTransportationDocumentDate] = useState<
    any | null
  >(null);
  const [products, setProducts] = useState<ProductDetails[]>([]);
  const taxIncludeWith =
    "items,store,items.product,items.unit,items.tax,items.batch,buyerDeliveryAddress,buyerDeliveryAddress.counterPartyCompany,buyerDeliveryAddress.country,buyerBillingAddress,buyerBillingAddress.counterPartyCompany,buyerBillingAddress.country,supplierBillingAddress,supplierBillingAddress.country,manufactureBillingAddress,manufactureBillingAddress.counterPartyCompany,manufactureBillingAddress.country";
  //Address
  const [buyerBillingAddress, setBuyerBillingAddress] = useState<any>(null);
  const [buyerDeliveryAddress, setBuyerDeliveryAddress] = useState<any>(null);
  const [disableItem, setDisableItem] = useState<any>(false);
  const [salesReturnData, setSalesReturnData] = useState<any>(null);
  const [supplierBillingAddress, setSupplierBillingAddress] =
    useState<any>(null);
  const [manufacturerBillingAddress, setManufacturerBillingAddress] =
    useState<any>(id ? {} : null);
  const [manufactureCompanyOption, setManufactureCompanyOption] = useState({
    label: "",
    value: "",
  });
  const [attachmentId, setAttachmentId] = useState<any>("");
  const [signatureId, setSignatureId] = useState<any>("");
  const [isAttchmentExist, setIsAttchmentExist] = useState(false);
  const [isSignatureExist, setIsSignatureExist] = useState(false);
  const [showAttachmentSignature, setShowAttachmentSignature] = useState(false);
  const [customSubmit, setcustomSubmit] = useState(false);
  const [attchmentTitle, setAttchmentTitle] = useState("");
  const [selectedAttchmentFile, setSelectedAttchmentFile] = useState(false);
  const [selectedSignatureFile, setSelectedSignatureFile] = useState(false);
  const [attachmentError, setAttachmentError] = useState<any>("");
  const [signaturecustomSubmit, setSignaturecustomSubmit] = useState(false);
  const [salesReturnComment, setSalesReturnComment] = useState<any>("");
  const [attachmentSignatureError, setAttachmentSignatureError] =
    useState<any>("");
  const [showAttachment, setShowAttachment] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const [submitEvent, setSubmitEvent] = useState<any>(null);
  const [showConfirmationForSendData, setShowConfirmationForSendData] =
    useState(false);

  //Address Error
  const [buyerBillingError, setBuyerBillingError] = useState<any>(null);
  const [supplierBillingAddressError, setSupplierBillingAddressError] =
    useState<any>(null);
  const [buyerDeliveryLocationError, setBuyerDeliveryLocationError] =
    useState<any>(null);
  const [documentDateError, setDocumentDateError] = useState<string | null>(
    null,
  );
  const [deliveryDateError, setDeliveryDateError] = useState<string | null>(
    null,
  );
  const [transportationDocumentDateError, setTransportationDocumentDateError] =
    useState<string | null>(null);
  const [productFormErrors, setProductFormErrors] = useState<any>({});
  const [store, setStore] = useState<any>({});

  const [productStatus, setProductStatus] = useState<boolean>(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [
    loadingForPrimaryDocumentDetails,
    setLoadingForPrimaryDocumentDetails,
  ] = useState(true);

  const [primaryDetails, setPrimaryDetails] = useState({
    document_date: false,
    document_number: false,
    delivery_date: false,
    store: false,
    tax_invoice_id: false,
    tax_invoice_date: false,
    transporter_name: false,
    transporter_doc_name: false,
    vehicle_number: false,
    transportation_doc_date: false,
  });
  useEffect(() => {
    if (isAttchmentExist) {
      setShowAttachment(true);
    }
    if (isSignatureExist) {
      setShowAttachmentSignature(true);
    }
    // eslint-disable-next-line
  }, [isAttchmentExist, isSignatureExist]);
  const handleConfirmationForGoBack: any = async () => {
    navigate(-1);
    setShowConfirmationForGoBack(false);
  };

  const GoBack = () => {
    setShowConfirmationForGoBack(true);
  };
  const handleSelectInputChange = (selectedOption: any, field: any) => {
    setFormData((prevData: any) => {
      const updatedData = {
        ...prevData,
        [field]: selectedOption,
      };
      return updatedData;
    });
  };

  const productTableFields = [
    {
      type: "CustomAsyncSelect",
      label: "product",
      headerLabel: "Item*",
      modal: Product,
      customFilter: [
        {
          field: "type",
          operator: "in",
          value: ["sell", "both"],
        },
      ],
      isMenuPortalTarget: true,
      inputField: "product_name",
      fieldName: "product_name",
      submitFieldName: "products_id",
      isMultiSelect: false,
      isNeedFullData: true,
      disabled: disableItem,
      onChangeModalStatus: (status: boolean) => setProductStatus(status),
      modalStatus: productStatus,
      widget: (
        <AddUpdateProduct
          title={PRODUCT_TITLE}
          listRoute={PRODUCT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setProductStatus(status)}
        />
      ),
    },
    {
      type: "CustomAsyncSelect",
      label: "batch",
      headerLabel: "Batch*",
      modal: BatchManagement,
      isMenuPortalTarget: true,
      inputField: "batch_number",
      fieldName: "batch_number",
      submitFieldName: "batches_id",
      isMultiSelect: false,
      isNeedFullData: true,
      addNewBtnStatus: false,
      disabled: disableItem,
    },
    {
      type: "number",
      label: "quantity",
      headerLabel: "Quantity*",
      fieldName: "quantity",
      submitFieldName: "quantity",
    },
    {
      type: "CustomAsyncSelect",
      label: "unit",
      headerLabel: "Unit*",
      modal: UnitOfMeasurement,
      isMenuPortalTarget: true,
      inputField: "name",
      fieldName: "name",
      submitFieldName: "unit_of_measurement_id",
      isMultiSelect: false,
      isNeedFullData: true,
      onChangeModalStatus: (status: boolean) => setUMOModalStatus(status),
      modalStatus: UMOModalStatus,
      disabled: disableItem,
      widget: (
        <AddUpdateUMO
          title={UNIT_OF_MEASUREMENT_TITLE}
          listRoute={UNIT_OF_MEASUREMENT_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setUMOModalStatus(status)}
        />
      ),
    },
    {
      type: "text",
      label: "comments",
      fieldShow: hideShowField[0].fieldStatus,
      headerLabel: "Comments",
      fieldName: "comments",
    },
  ];
  const handleToggleAttachmentSignature = () => {
    setShowAttachmentSignature(!showAttachmentSignature);
  };
  useEffect(() => {
    const allFieldsSet = Object.values(primaryDetails).every(
      (field) => field === true,
    );

    if (allFieldsSet) {
      setLoadingForPrimaryDocumentDetails(false);
    }
  }, [primaryDetails]);

  useEffect(() => {
    if (!id) {
      if (showAttachment) {
        const attachmentsElement = document.getElementById("attachments");
        if (attachmentsElement) {
          attachmentsElement.scrollIntoView({ behavior: "smooth" });
        }
      }
      if (showComments) {
        const commentsElement = document.getElementById("comments");
        if (commentsElement) {
          commentsElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAttachment, showComments]);

  const handleToggleComments = () => {
    setShowComments(!showComments);
  };

  useEffect(() => {
    if (!id) {
      documentRegenerate();
    }

    if (tax_invoice_id) {
      fetchTaxInvoiceData(tax_invoice_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFormat]);

  const documentRegenerate = async () => {
    const responce = await apiGet(`next-document-number/sales_return`);
    setDocumentNumber(responce.data.value);
  };
  const customTheme = (theme: any) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });
  const handleToggleAttachment = () => {
    setShowAttachment(!showAttachment);
  };
  const cleanObject = (obj: any, fields: any) => {
    for (var propName in obj) {
      if (
        !fields.includes(propName) &&
        (obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === "")
      ) {
        delete obj[propName];
      } else if (typeof obj[propName] === "object") {
        cleanObject(obj[propName], fields);
      }
    }
    return obj;
  };

  const submit = async (e: any, status: any) => {
    setSubmitEvent(e);
    setSubmitStatus(status);
    if (status === "draft") {
      await handleConfirmationForSubmit(e, status);
    } else {
      setShowConfirmationForSendData(true);
    }
  };

  useEffect(() => {
    try {
      if (id) {
        getSalesReturnData();
      } else {
        setProducts([{ ...productDetails, position: 0 }]);
      }
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateFormat]);

  const getSalesReturnData = async () => {
    const item: any = await SalesReturns.$query()
      .with([
        "store",
        "items",
        "items.unit",
        "items.batch",
        "items.product",
        "taxInvoice",
        "taxInvoice.items",
        "taxInvoice.items.product",
        "supplierBillingAddress",
        "buyerBillingAddress",
        "buyerDeliveryAddress",
        "manufacture",
        "manufactureBillingAddress",
        "buyerDeliveryAddress.counterPartyCompany",
        "buyerBillingAddress.counterPartyCompany",
        "supplierBillingAddress.counterPartyCompany",
        "supplierBillingAddress.counterPartyCompany",
        "manufactureBillingAddress.counterPartyCompany",
        "manufactureBillingAddress.country",
        "manufactureBillingAddress.counterPartyCompany",
      ])
      .find(Number(id));
    setSalesReturnData(item.$attributes);
    setSignatureId(id);
    setAttachmentId(id);
    setDisableItem(item.$attributes.tax_invoice_id ? true : false);
    let inputData = { ...item.$attributes };
    if (
      item.$attributes["tax_invoice_date"] ||
      item.$attributes["tax_invoice"] ||
      item.$attributes["vehicle_number"] ||
      item.$attributes["transporter_name"] ||
      item.$attributes["transporter_doc_name"] ||
      item.$attributes["transportation_doc_date"]
    ) {
      setShowOptionalFields(true);
    }

    const fieldSetters = [
      { key: "document_number", setter: setDocumentNumber },
      { key: "status", setter: setStatus },
      { key: "vehicle_number", setter: setVehicleNumber },
      { key: "transporter_name", setter: setTransporterName },
      { key: "transporter_doc_name", setter: setTransporterDocName },
    ];

    fieldSetters.forEach(({ key, setter }) => {
      if (item.$attributes[key]) {
        setter(item.$attributes[key]);
      }
    });

    const dateFields = [
      { key: "tax_invoice_date", setter: setTIDate },
      { key: "document_date", setter: setDocumentDate },
      { key: "delivery_date", setter: setDeliveryDate },
      { key: "transportation_doc_date", setter: setTransportationDocumentDate },
    ];

    const isFieldsValueExist = (fieldName: string) => {
      setPrimaryDetails((prevState) => ({
        ...prevState,
        [fieldName]: true,
      }));
    };

    if (dateFormat) {
      dateFields.forEach(({ key, setter }) => {
        if (item.$attributes[key]) {
          setter(DateString(item.$attributes[key], dateFormat.split(" ")[0]));
        }
        isFieldsValueExist(key); // Set primary details for date fields
      });
    }
    if (item.$attributes.comments) {
      setShowComments(true);
      setSalesReturnComment(inputData.comments);
    }

    if (item.$attributes["tax_invoice"]) {
      inputData["tax_invoice"] = {
        label: inputData.tax_invoice["document_number"],
        value: inputData.tax_invoice["id"],
      };
      setTaxInvoiceData(item.$attributes["tax_invoice"]);
    }
    if (item.$attributes.store_id) {
      inputData.store = {
        label: inputData.store.name,
        value: inputData.store.id,
      };
      setStore(item.$attributes["store"]);
    }

    const addressSetters = [
      {
        key: "buyer_billing_address",
        setter: setBuyerBillingAddress,
        // idSetter: setBuyerBillingAddressId,
      },
      {
        key: "buyer_delivery_address",
        setter: setBuyerDeliveryAddress,
        // idSetter: setBuyerDeliveryAddressId,
      },
      {
        key: "supplier_billing_address",
        setter: setSupplierBillingAddress,
        // idSetter: setSupplierBillingAddressId,
      },
      {
        key: "manufacture_billing_address",
        setter: setManufacturerBillingAddress,
        // idSetter: setManufacturerBillingAddressId,
      },
    ];
    addressSetters.forEach(({ key, setter, idSetter }: any) => {
      if (item.$attributes[key]) {
        setter(item.$attributes[key]);
        idSetter && idSetter(item.$attributes[key].id);
      } else {
        setter(null);
      }
    });

    setProducts((prevState: any) => {
      const uniqueItems = new Set(prevState.map((item: any) => item.id));

      const returnProduct = [
        ...prevState,
        ...inputData.items
          .filter((item: any) => !uniqueItems.has(item.id))
          .map((item: any, index: number) => ({
            ...item,
            position: item.position ?? index,
          })),
      ];

      return returnProduct.sort((a, b) => a.position - b.position);
    });

    setHideShowField((prevState: any) => {
      return prevState.map((field: any) => {
        if (
          field.id === 1 &&
          inputData.items.some((item: any) => item.comments !== null)
        ) {
          return { ...field, fieldStatus: true };
        }
        return field;
      });
    });

    const checkField = [
      "document_number",
      "store",
      "tax_invoice_id",
      "transporter_name",
      "transporter_doc_name",
      "vehicle_number",
    ];

    checkField.forEach((fieldName) => {
      isFieldsValueExist(fieldName);
    });

    setFormData(inputData);
  };

  const fetchTaxInvoiceData = async (tax_invoice_id: string | number) => {
    const response = await TaxInvoice.$query()
      .with([taxIncludeWith])
      .find(tax_invoice_id);
    getTaxInvoiceData(response?.$attributes);
  };

  const getTaxInvoiceData = (data: any) => {
    setShowOptionalFields(true);
    setDisableItem(true);
    setProducts([]);
    setBuyerBillingAddress(data?.supplier_billing_address);
    setSupplierBillingAddress(data?.buyer_billing_address);
    setSelectedCompanyOption({
      label: data?.counter_party_company?.company_name,
      value: data?.counter_party_company_id,
    });
    setManufacturerBillingAddress(data?.manufacture_billing_address);
    setManufactureCompanyOption({
      label: data?.manufacture_billing_address?.company_name,
      value: data?.manufacture_billing_address?.id,
    });
    setTaxInvoiceData(data);
    setStore(data["store"]);

    setFormData((prevState: any) => ({
      ...prevState,
      tax_invoice: { value: data.document_number, label: data.id },
      store: { value: data.store?.id, label: data.store?.name },
    }));

    if (dateFormat) {
      setTIDate(DateString(data?.document_date, dateFormat.split(" ")[0]));
    }
    setProducts((prevState: any) => {
      const uniqueItems = new Set(prevState.map((item: any) => item.id));
      const returnProduct = [
        ...prevState,
        ...data.items
          .filter((item: any) => !uniqueItems.has(item.id))
          .map((item: any, index: number) => ({
            ...item,
            quantity: id ? item.quantity : null,
            position: item.position ?? index,
          })),
      ];

      return returnProduct.sort((a, b) => a.position - b.position);
    });
  };

  const handleConfirmationForSendData = () => {
    handleConfirmationForSubmit(submitEvent, submitStatus, true);
    setShowConfirmationForSendData(false);
  };

  const handleConfirmationForSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    status?: any,
    fullData?: boolean,
  ) => {
    e.preventDefault();
    let isValid;
    let productErrors: any = {};
    let isSignatureValid = true;
    let isAttchmentValid = true;

    if (!buyerBillingAddress) {
      isValid = false;
      setBuyerBillingError("Billing Address is Required");
    } else {
      isValid = true;
      setBuyerBillingError("");
    }

    if (!buyerDeliveryAddress) {
      isValid = false;
      setBuyerDeliveryLocationError("Delivery Address is Required");
    } else {
      isValid = true;
      setBuyerDeliveryLocationError("");
    }

    if (!supplierBillingAddress) {
      isValid = false;
      setSupplierBillingAddressError("Supplier Billing Address is Required");
    } else {
      isValid = true;
      setSupplierBillingAddressError("");
    }

    if (!documentDate) {
      setDocumentDateError("Document Date is Required");
      isValid = false;
    } else {
      isValid = true;
      setDocumentDateError("");
    }

    if (!deliveryDate) {
      setDeliveryDateError("Delivery Date is Required");
      isValid = false;
    } else {
      isValid = true;
      setDeliveryDateError("");
    }

    if (showAttachmentSignature) {
      if (!id && !selectedSignatureFile) {
        setAttachmentSignatureError("Signature is Required");
        isSignatureValid = false;
      } else {
        setAttachmentSignatureError("");
      }
    }
    if (Object.keys(store).length === 0) {
      setStoreError("Store is Required");
      isValid = false;
    } else {
      setStoreError("");
    }

    if (showAttachment) {
      if (!id) {
        if (!attchmentTitle || !(attchmentTitle.length >= 2)) {
          setAttachmentError({
            titleError:
              "Attachment's title field must be at least 2 characters",
          });
          isAttchmentValid = false;
        } else if (!selectedAttchmentFile) {
          setAttachmentError({ attachmentsError: "Attachments is Required" });
          isAttchmentValid = false;
        } else {
          setAttachmentError("");
        }
      } else {
        if (attchmentTitle && !(attchmentTitle.length >= 2)) {
          setAttachmentError({
            titleError:
              "Attachment's title field must be at least 2 characters",
          });
          isAttchmentValid = false;
        } else if (attchmentTitle && !selectedAttchmentFile) {
          setAttachmentError({ attachmentsError: "Attachments is Required" });
          isAttchmentValid = false;
        } else {
          setAttachmentError("");
        }
      }
    }

    let rowDataArray = products.map((item) => {
      const newItem: any = { ...item };
      const fields = [
        "batches_id",
        "quantity",
        "products_id",
        "unit_of_measurement_id",
        "comments",
      ];
      return cleanObject(newItem, fields);
    });

    rowDataArray.every((item: any, index: number) => {
      Object.entries(item).forEach(([key, value]: [string, any]) => {
        if (
          !(
            value ||
            key === "comments" ||
            key === "position" ||
            key === "customKey" ||
            key === "invoice_quantity"
          )
        ) {
          let errorKey = key
            .replace("id", "")
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          const errorMessage = `${errorKey} is required`;
          productErrors[index] = {
            ...productErrors[index],
            [key]: errorMessage,
          };
        }
      });
      return item;
    });

    setProductFormErrors(productErrors);

    if (fullData) {
      rowDataArray = rowDataArray?.map((item: any, _index: any) => ({
        ...item,
        products_data: JSON.stringify(item.product) ?? {},
        unit_of_measurement_data: JSON.stringify(item.unit) ?? {},
        batches_data: JSON.stringify(item.batch) ?? {},
        tax_invoices_items_data: id
          ? JSON.stringify(
              salesReturnData?.tax_invoice?.items?.find((d: any) => {
                return d.id === item.tax_invoices_items_id;
              }),
            ) ?? {}
          : JSON.stringify(
              taxInvoiceData?.items?.find((d: any) => {
                return d.id === item.id;
              }),
            ),
        ...(id ? { sales_returns_data: JSON.stringify(salesReturnData) } : {}),
      }));
    }

    if (
      !isValid ||
      !isSignatureValid ||
      !isAttchmentValid ||
      Object.keys(productErrors).length !== 0
    ) {
      setToast("error", "Validation failed. Please check the form fields");
      if (!isValid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (Object.keys(productErrors).length !== 0) {
        window.scrollTo({ top: 500, behavior: "smooth" });
      }
      return;
    }

    let newRowData = rowDataArray.map((item) => {
      const newObj: any = {};
      const fields = [
        // "id",
        "batches_id",
        "tax_invoices_items_id",
        "unit_of_measurement_id",
        "comments",
        "quantity",
        "position",
        "products_id",
        "products_data",
        "unit_of_measurement_data",
        "tax_invoices_items_data",
        "sales_returns_data",
      ];
      fields.forEach((field: string) => {
        newObj[field] = item[field];
      });
      if (id) {
        newObj.id = item["id"];
      }
      newObj.quantity = Number(newObj.quantity);

      newObj["tax_invoices_items_id"] = id
        ? item["tax_invoices_items_id"]
        : item["id"];

      return newObj;
    });

    const formdata: any = {
      ...(!id ? { document_number: documentnumber } : {}),
      status: status,
      document_date: DateFormatWithStartOFTheDay(
        documentDate,
        dateFormat?.replace("dd", "DD"),
      ),
      supplier_billing_address_id: supplierBillingAddress?.id,
      buyer_billing_address_id: buyerBillingAddress?.id,
      buyer_delivery_address_id: buyerDeliveryAddress?.id,
      supplier_id: supplierBillingAddress?.counter_party_company_id,
      manufacture_id: manufacturerBillingAddress?.counter_party_company?.id,
      manufacture_billing_address_id: manufacturerBillingAddress?.id,
      delivery_date: DateFormatWithStartOFTheDay(
        deliveryDate,
        dateFormat?.replace("dd", "DD"),
      ),
      tax_invoice_id: taxInvoiceData?.id,
      store_id: store.value || store?.id,
      // ...(store.id ? { store_id: store.id } : {}),
      tax_invoice_date: taxInvoiceData?.document_date,
      transporter_name: transporterName,
      transporter_doc_name: transporterDocName,
      vehicle_number: vehicleNumber,
      comments: showComments ? salesReturnComment : "",
      transportation_doc_date: DateFormatWithStartOFTheDay(
        transportationDocumentDate,
        dateFormat?.replace("dd", "DD"),
      ),
      items: newRowData,
      ...(fullData
        ? {
            tax_invoice_data: JSON.stringify(taxInvoiceData),
            buyer_billing_address_data: JSON.stringify(buyerBillingAddress),
            buyer_delivery_address_data: JSON.stringify(buyerDeliveryAddress),
            supplier_billing_address_data: JSON.stringify(
              supplierBillingAddress,
            ),
            manufacture_billing_address_data: JSON.stringify(
              manufacturerBillingAddress,
            ),
            manufacture_name:
              manufacturerBillingAddress?.counter_party_company?.company_name,
            supplier_name:
              supplierBillingAddress?.counter_party_company?.company_name,
            store_data:
              store && store.data
                ? JSON.stringify(store.data)
                : JSON.stringify(store),
          }
        : {}),
    };

    try {
      setSubmitting(true);
      if (id) {
        if (selectedSignatureFile && showAttachmentSignature) {
          setSignaturecustomSubmit(true);
        }
        if (selectedAttchmentFile && showAttachment) {
          setcustomSubmit(true);
        }
        await (SalesReturns.$query() as any).update(id, formdata);
        setToast("success", "Sales Return successfully updated");
      } else {
        const data = await (SalesReturns.$query() as any).store(formdata);
        if (selectedSignatureFile && showAttachmentSignature) {
          setSignatureId(data?.$attributes.id);
          setSignaturecustomSubmit(true);
        }
        if (selectedAttchmentFile && showAttachment) {
          setAttachmentId(data?.$attributes.id);
          setcustomSubmit(true);
        }
        setToast("success", "Sales Return successfully added");
      }
      navigate(-1);
    } catch (error: any) {
      if (error.response && error.response.data.errors) {
        showErrorsInToasts(error.response.data.errors);
      } else if (error.response.data.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h5>{SALES_RETURN_TITLE}</h5>
        </div>
        <div style={{ display: "flex" }}>
          <CustomButton label={`Back`} variant="secondary" onClick={GoBack} />
        </div>
      </div>
      <div>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                paddingTop: "20px",
              }}
            >
              <AddressSelecter
                title="Organization Details*"
                loadingStatus={id ? true : false}
                isPratent={false}
                isChild={true}
                childModal={OrganizationAddress}
                childIncludeWith={"country"}
                childFieldName="buyer_billing_address"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="organization_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                childCustomFilter={[
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={false}
                isParentDisabled={false}
                childFormAddressType={"billing"}
                selectedValue={buyerBillingAddress}
                setSelectedValue={setBuyerBillingAddress}
                formTitle={"Billing Address"}
                formModal={OrganizationAddress}
                errorValues={buyerBillingError}
                setErrorValues={setBuyerBillingError}
                addChildNewBtnStatus={true}
              />
              <AddressSelecter
                title="Delivery Location*"
                isPratent={false}
                loadingStatus={id ? true : false}
                isChild={true}
                childModal={OrganizationAddress}
                addChildNewBtnStatus={true}
                childIncludeWith={"country"}
                childFieldName="company_name"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="organization_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                childCustomFilter={[
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["delivery"],
                  },
                ]}
                isChildDisabled={false}
                isParentDisabled={false}
                childFormAddressType={"delivery"}
                selectedValue={buyerDeliveryAddress}
                setSelectedValue={setBuyerDeliveryAddress}
                formTitle={"Delivery Address"}
                formModal={OrganizationAddress}
                errorValues={buyerDeliveryLocationError}
                setErrorValues={setBuyerDeliveryLocationError}
              />
            </div>
            <div
              style={{
                display: "flex",
                paddingTop: "37px",
                marginBottom: "20px",
              }}
            >
              <AddressSelecter
                title="Supplier Details*"
                loadingStatus={id ? true : false}
                isPratent={true}
                isChild={true}
                parentModal={BuyersAndSuppliers}
                parentFieldName="company_name"
                parentFormData="company_name"
                parentInputfield="company_name"
                parentLabel="counter_party_company"
                addParentNewBtnStatus={false}
                childModal={CounterPartyAddress}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="supplier_billing_address"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="counter_party_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                selectedParentValue={selectedCompanyOption}
                childCustomFilter={[
                  ...(selectedCompanyOption?.value ||
                  supplierBillingAddress?.counter_party_company_id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            selectedCompanyOption?.value ||
                              supplierBillingAddress?.counter_party_company_id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={false}
                childFormAddressType={"billing"}
                selectedValue={supplierBillingAddress}
                setSelectedValue={setSupplierBillingAddress}
                setSelectedParentValue={setSelectedCompanyOption}
                formTitle={"Billing Address"}
                formModal={CounterPartyAddress}
                errorValues={supplierBillingAddressError}
                setErrorValues={setSupplierBillingAddressError}
              />
              <AddressSelecter
                title="Manufactured by"
                loadingStatus={
                  !manufacturerBillingAddress ? false : id ? true : false
                }
                isPratent={true}
                isChild={true}
                parentModal={BuyersAndSuppliers}
                parentFieldName="company_name"
                parentFormData="company_name"
                parentInputfield="company_name"
                parentLabel="counter_party_company"
                addParentNewBtnStatus={false}
                childModal={CounterPartyAddress}
                setSelectedParentValue={setManufactureCompanyOption}
                selectedParentValue={manufactureCompanyOption}
                addChildNewBtnStatus={true}
                childIncludeWith={"country,counterPartyCompany"}
                childFieldName="company_name"
                childFormData="formData"
                childInputfield="address_name"
                childLabel="counter_party_address"
                isChildMultiSelect={false}
                isChildNeedFullData={true}
                isParentMultiSelect={false}
                isParentNeedFullData={false}
                childCustomFilter={[
                  ...(manufactureCompanyOption?.value ||
                  manufacturerBillingAddress?.counter_party_company?.id
                    ? [
                        {
                          field: "counter_party_company_id",
                          operator: "in",
                          value: [
                            manufactureCompanyOption?.value ||
                              manufacturerBillingAddress?.counter_party_company
                                ?.id,
                          ],
                        },
                      ]
                    : []),
                  {
                    field: "address_type",
                    operator: "in",
                    value: ["billing"],
                  },
                ]}
                isChildDisabled={manufactureCompanyOption?.value === undefined}
                isParentDisabled={false}
                childFormAddressType={"billing"}
                selectedValue={manufacturerBillingAddress}
                setSelectedValue={setManufacturerBillingAddress}
                formTitle={"Manufacturer Address"}
                formModal={CounterPartyAddress}
              />
            </div>
          </Col>
          <Col>
            <Card className="mt-3" style={{ fontSize: "small" }}>
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "15px" }}>
                    Primary Document Details
                  </span>
                  <button
                    className="btn btn-sm btn-light border"
                    onClick={() => setShowOptionalFields(!showOptionalFields)}
                  >
                    {showOptionalFields
                      ? "Hide Optional Fields"
                      : "Show Optional Fields"}
                  </button>
                </div>
              </Card.Header>

              {id && loadingForPrimaryDocumentDetails ? (
                <div style={{ padding: "1rem" }} className="pb-0">
                  <FormSkeleton />
                </div>
              ) : (
                <Card.Body>
                  <div className="row mb-2">
                    <div className="col">
                      <label
                        htmlFor="documentnumber"
                        className="d-flex justify-content-between "
                      >
                        <span>Document Number*</span>
                        <button
                          onClick={() => {
                            documentRegenerate();
                          }}
                          className="btn btn-light border mb-1"
                          style={{ padding: "3px 5px", fontSize: "12px" }}
                          disabled={id ? true : false}
                        >
                          Regenerate
                        </button>
                      </label>
                      <input
                        type="text"
                        className=" form-control form-control-sm"
                        disabled
                        value={documentnumber}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label
                        htmlFor="documentdate"
                        style={{ marginBottom: "10px" }}
                      >
                        Document Date*
                      </label>
                      <DatePicker
                        selected={documentDate}
                        placeholderText={dateFormat.replace("dd", "DD")}
                        onChange={(date) => {
                          setDocumentDate(date);
                        }}
                        dateFormat={
                          dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                        }
                        showYearDropdown
                        className="form-control form-control-sm w-100"
                        onBlur={(e: any) => {
                          let enteredDate = e.target.value;
                          const dateFormatWithoutTime = dateFormat
                            .replace("dd", "DD")
                            .split(" ")[0];
                          enteredDate = enteredDate.replace(
                            /\b(\d{1})\b/g,
                            "0$1",
                          );
                          const isValid = moment(
                            enteredDate,
                            dateFormatWithoutTime,
                          ).isValid();
                          if (isValid) {
                            setDocumentDateError("");
                          } else {
                            setDocumentDate("");
                            if (enteredDate.length > 0) {
                              setDocumentDateError(
                                `Please select a date in the correct format (${
                                  dateFormat.replace("dd", "DD").split(" ")[0]
                                }).`,
                              );
                            } else {
                              setDocumentDateError("");
                            }
                          }
                        }}
                      />
                      {documentDateError && (
                        <div className="error-message text-danger">
                          {documentDateError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col d-flex flex-column">
                      <label htmlFor="deliverydate">Delivery Date*</label>
                      <DatePicker
                        selected={deliveryDate}
                        placeholderText={dateFormat.replace("dd", "DD")}
                        onChange={(date) => {
                          setDeliveryDate(date);
                        }}
                        dateFormat={
                          dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                        }
                        showYearDropdown
                        className="form-control form-control-sm w-100"
                        onBlur={(e: any) => {
                          let enteredDate = e.target.value;
                          const dateFormatWithoutTime = dateFormat
                            .replace("dd", "DD")
                            .split(" ")[0];
                          enteredDate = enteredDate.replace(
                            /\b(\d{1})\b/g,
                            "0$1",
                          );
                          const isValid = moment(
                            enteredDate,
                            dateFormatWithoutTime,
                          ).isValid();
                          if (isValid) {
                            setDeliveryDateError("");
                          } else {
                            setDeliveryDate("");
                            if (enteredDate.length > 0) {
                              setDeliveryDateError(
                                `Please select a date in the correct format (${
                                  dateFormat.replace("dd", "DD").split(" ")[0]
                                }).`,
                              );
                            } else {
                              setDeliveryDateError("");
                            }
                          }
                        }}
                      />
                      {deliveryDateError && (
                        <div className="error-message text-danger">
                          {deliveryDateError}
                        </div>
                      )}
                    </div>
                    <div className="col">
                      <label htmlFor="store">Store*</label>
                      <CustomAsyncSelect
                        componentKey={storeModalStatus ? 0 : 1}
                        customTheme={customTheme}
                        model={Store}
                        inputfield={"name"}
                        fieldName={"store"}
                        isMultiSelect={false}
                        formData={formData}
                        label={"store"}
                        addNewBtnStatus={true}
                        isNeedFullData={true}
                        isdisabled={
                          tax_invoice_id ? true : taxInvoiceData ? true : false
                        }
                        onChange={(selectedOption: any) => {
                          setStore(selectedOption?.data);
                          handleSelectInputChange(selectedOption, "store");
                        }}
                        onChangeModalStatus={(status: boolean) => {
                          setStoreModalStatus(status);
                        }}
                        modalStatus={storeModalStatus}
                        widget={
                          <AddUpdateStore
                            title={STORE_TITLE}
                            listRoute={STORE_LIST}
                            isNotUpdate={true}
                            onChangeModalStatus={(status: boolean) =>
                              setStoreModalStatus(status)
                            }
                          />
                        }
                      />
                      {storeError && (
                        <div className="error-message text-danger">
                          {storeError}
                        </div>
                      )}
                    </div>
                  </div>
                  {showOptionalFields && (
                    <>
                      <div className="row mb-2">
                        <div className="col">
                          <label htmlFor="PurchaseOrdernumber">
                            Tax Invoice Number
                          </label>
                          <CustomAsyncSelect
                            customTheme={customTheme}
                            model={TaxInvoice}
                            inputfield={"document_number"}
                            fieldName={"tax_invoice"}
                            isMultiSelect={false}
                            includeWith={taxIncludeWith}
                            formData={formData}
                            label={"taxInvoice"}
                            addNewBtnStatus={false}
                            isNeedFullData={true}
                            // isdisabled={formData.store && true}
                            customValue={{
                              value: taxInvoiceData?.id,
                              label: taxInvoiceData?.document_number,
                            }}
                            isdisabled={true}
                            onChange={(selectedOption: any) => {
                              setFormData({ ...formData, store: null });
                              getTaxInvoiceData(selectedOption?.data);
                              handleSelectInputChange(
                                selectedOption,
                                "tax_invoice",
                              );
                            }}
                          />
                        </div>
                        <div className="col d-flex flex-column">
                          <label htmlFor="podate">Tax Invoice Date</label>
                          <DatePicker
                            selected={tiDate}
                            placeholderText="Select Date"
                            // readOnly
                            disabled={true}
                            dateFormat={dateFormat.split(" ")[0]}
                            className="form-control form-control-sm w-100"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={() => {}}
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        <div className="col">
                          <label htmlFor="title">Transporter Name</label>
                          <input
                            className="w-100 form-control form-control-sm form-control form-control-sm-sm"
                            type="text"
                            value={transporterName}
                            onChange={(e) => {
                              setTransporterName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="title">
                            Transportation Document Number
                          </label>
                          <input
                            className="w-100 form-control form-control-sm form-control form-control-sm-sm"
                            type="text"
                            value={transporterDocName}
                            onChange={(e) => {
                              setTransporterDocName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col d-flex flex-column">
                          <label htmlFor="transportationdocumentdate">
                            Transportation Document Date
                          </label>
                          <DatePicker
                            selected={transportationDocumentDate}
                            onChange={(date) => {
                              setTransportationDocumentDate(date);
                            }}
                            placeholderText={dateFormat.replace("dd", "DD")}
                            dateFormat={
                              dateFormat?.replace("YYYY", "yyyy").split(" ")[0]
                            }
                            showYearDropdown
                            className="form-control form-control-sm w-100"
                            onBlur={(e: any) => {
                              let enteredDate = e.target.value;
                              const dateFormatWithoutTime = dateFormat
                                .replace("dd", "DD")
                                .split(" ")[0];
                              enteredDate = enteredDate.replace(
                                /\b(\d{1})\b/g,
                                "0$1",
                              );
                              const isValid = moment(
                                enteredDate,
                                dateFormatWithoutTime,
                              ).isValid();
                              if (isValid) {
                                setTransportationDocumentDate(
                                  DateString(
                                    enteredDate,
                                    dateFormat.split(" ")[0],
                                  ),
                                );
                                setTransportationDocumentDateError("");
                              } else {
                                setTransportationDocumentDate("");
                                if (enteredDate.length > 0) {
                                  setTransportationDocumentDateError(
                                    `Please select a date in the correct format (${
                                      dateFormat
                                        .replace("dd", "DD")
                                        .split(" ")[0]
                                    }).`,
                                  );
                                } else {
                                  setTransportationDocumentDateError("");
                                }
                              }
                            }}
                          />
                          {transportationDocumentDateError && (
                            <div className="error-message text-danger">
                              {transportationDocumentDateError}
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <label htmlFor="vehiclenumber">Vehicle Number</label>
                          <input
                            type="text"
                            className="w-48 form-control form-control-sm"
                            onChange={(e) => setVehicleNumber(e.target.value)}
                            value={vehicleNumber}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Card.Body>
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <CreateRow
        FormTableFields={productTableFields}
        setCardsData={setProducts}
        cardsData={products}
        initializedProduct={productDetails}
        formErrors={productFormErrors}
        updateStatus={true}
        hideShowField={hideShowField}
        setHideShowField={setHideShowField}
        cardShowStatus={true}
        cardName="Product Details"
        setFormErrors={setProductFormErrors}
        addButtonStatus={taxInvoiceData ? false : true}
      />

      <Row className="mb-4">
        <Col md={7}>
          <Card className="mb-4 w-100">
            <Card.Header>
              <div className="d-flex align-items-center">
                <div className="btngroup">
                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showAttachment ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleAttachment}
                    size={"sm"}
                  >
                    Attachment
                  </Button>
                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showAttachmentSignature ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleAttachmentSignature}
                    size={"sm"}
                  >
                    Attach Signature
                  </Button>
                  <Button
                    type="button"
                    style={{ fontSize: "12px" }}
                    className={`btn me-2 btn-secondary rounded-pill ${
                      showComments ? "btn-success" : "secondary"
                    } `}
                    onClick={handleToggleComments}
                    size={"sm"}
                  >
                    Add Comments
                  </Button>
                </div>
              </div>
            </Card.Header>
          </Card>
          <div
            className="row w-100"
            style={{ marginLeft: "0", justifyContent: "space-between" }}
          >
            <Card
              style={{
                display: showAttachment ? "block" : "none",
                fontSize: "small",
                width: "48%",
              }}
              className="mb-2 p-0"
              id="attachments"
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Attachments
              </Card.Header>
              <Card.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
                <AttachmentsForDocuments
                  fieldType="sales_returns_id"
                  moduleType="sales_returns"
                  id={attachmentId}
                  customSubmit={customSubmit}
                  setAttchmentTitle={setAttchmentTitle}
                  setSelectedAttchmentFile={setSelectedAttchmentFile}
                  attachmentError={attachmentError}
                  setIsAttchmentExist={setIsAttchmentExist}
                ></AttachmentsForDocuments>
              </Card.Body>
            </Card>
            <Card
              className="mb-2 p-0"
              style={{
                width: "48%",
                display: showAttachmentSignature ? "block" : "none",
                fontSize: "small",
                height: "fit-content",
              }}
            >
              <Card.Header style={{ fontSize: "15px" }}>
                Attach Signature
              </Card.Header>
              <Card.Body>
                <SignatureForDocuments
                  fieldType="sales_returns_id"
                  moduleType="sales_returns"
                  id={signatureId}
                  customSubmit={signaturecustomSubmit}
                  setSelectedSignatureFile={setSelectedSignatureFile}
                  attachmentSignatureError={attachmentSignatureError}
                  setIsSignatureExist={setIsSignatureExist}
                ></SignatureForDocuments>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <Card
        style={{ display: showComments ? "block" : "none", fontSize: "small" }}
        className="mb-4"
        id="comments"
      >
        <Card.Header style={{ fontSize: "15px" }}>Comments</Card.Header>
        <Card.Body>
          <Form.Group controlId="comments">
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter your comments here"
              onChange={(e) => {
                setSalesReturnComment(e.target.value);
              }}
              value={salesReturnComment}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <div className="mb-4 d-flex justify-content-end">
        <span>
          <CustomButton
            type="submit"
            label={"SAVE DRAFT"}
            variant="info"
            onClick={(e) => submit(e, "draft")}
            size="sm"
            customeStyle={{ width: "150px", padding: "5px", fontWeight: "500" }}
            customeClass={"text-light"}
            disableStatus={
              (status && status === "sent") ||
              (id && loadingForPrimaryDocumentDetails) ||
              submitting
            }
            spinnerStatus={submitting && submitStatus === "draft"}
          />
        </span>

        <span>
          <CustomButton
            type="submit"
            label={"SAVE AND SEND"}
            variant="primary"
            onClick={(e) => submit(e, "sent")}
            size="sm"
            customeStyle={{ width: "150px", padding: "5px", fontWeight: "500" }}
            customeClass={"text-light"}
            disableStatus={
              (status && status === "sent") ||
              (id && loadingForPrimaryDocumentDetails) ||
              submitting
            }
            spinnerStatus={submitting && submitStatus === "sent"}
          />
        </span>
      </div>
      <ConfirmationModal
        show={showConfirmationForGoBack}
        onHide={() => setShowConfirmationForGoBack(false)}
        title="Confirmation"
        message="Are you sure you want to go back?"
        onConfirm={handleConfirmationForGoBack}
        confirmButtonColor="primary"
      />
      <ConfirmationModal
        show={showConfirmationForSendData}
        onHide={() => setShowConfirmationForSendData(false)}
        title="Confirmation"
        message="Are you sure you want to send?"
        onConfirm={handleConfirmationForSendData}
        confirmButtonColor="primary"
        spinnerStatus={submitting ? submitting : false}
      />
    </>
  );
};

export default AddUpdate;
