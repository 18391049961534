import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ButtonGroup } from "react-bootstrap";

interface CustomRestoreIconButtonProps {
  onClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number,
    option?: any,
  ) => void;
  id: number;
  isDisabled?: boolean;
}

const CustomRestoreIconButton: React.FC<CustomRestoreIconButtonProps> = ({
  onClick,
  id,
  isDisabled,
}) => {
  return (
    <ButtonGroup>
      <button
        className="btn btn-xs border-0 text-primary"
        disabled={isDisabled}
        style={{ paddingLeft: "1px", paddingRight: "5px" }}
        onClick={(e) => onClick(e, id)}
        title="Archive"
      >
        <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#009e20" }} />
        <span style={{ marginLeft: "5px", color: "#009e20" }}>Restore</span>
      </button>
    </ButtonGroup>
  );
};

export default CustomRestoreIconButton;
