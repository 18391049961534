import { TableColumn } from "react-data-table-component";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData } from "../../../Util/OrionList";
import {
  TERMSANDCONDITION_ADD,
  TERMSANDCONDITION_ARCHIVE,
  TERMSANDCONDITION_EDIT,
  TERMSANDCONDITION_LIST,
  TERMSANDCONDITION_TITLE,
} from "../../../Routes/Setting/TermsAndCondition";
import { TermsAndCondition } from "../../../Model/Setting/TermsAndCondition";
import CustomEditButton from "../../../Components/Button/CustomEditButton";
import CustomDataTable from "../../../Components/CustomDataTable";
import { Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomButton from "../../../Components/Button/CustomButton";
import pluralize from "pluralize";
import CustomArchiveButton from "../../../Components/Button/CustomArchiveButton";
import CustomArchiveModal from "../../../Components/CustomArchiveModal";
import CustomRestoreIconButton from "../../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../../Components/CustomRestoreModal";
import { setToast } from "../../../Util/Toast";

interface TermsAndConditionItem {
  id: number;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<TermsAndConditionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === TERMSANDCONDITION_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(TERMSANDCONDITION_EDIT.replace(":id", id.toString()));
  };
  const handleRowArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setArchiveItemId(id);
    setshowArchiveModal(true);
  };
  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(TERMSANDCONDITION_ADD);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const handleArchiveButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(TERMSANDCONDITION_ARCHIVE);
  };

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  const columns: TableColumn<TermsAndConditionItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Name",
      cell: (row) => row.name,
    },
    {
      name: "Description",
      cell: (row) => row.description,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Updated At",
      cell: (row: any) => row.updated_at,
    },
    {
      name: "Deleted At",
      cell: (row: any) => row.deleted_at,
      omit: !isArchive,
    },
    {
      name: "Actions",
      button: true,
      omit: !permissions.update && !permissions.delete,
      cell: (row) => (
        <div>
          {isArchive ? (
            permissions.restore && (
              <CustomRestoreIconButton
                id={row.id}
                onClick={handleRowRestoreButtonClick}
              />
            )
          ) : (
            <>
              {permissions.update && (
                <CustomEditButton
                  id={row.id}
                  onClick={handleRowEditButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomArchiveButton
                  id={row.id}
                  onClick={handleRowArchiveButtonClick}
                />
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  // @ts-ignore
  const fetchTermsAndCondition = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new TermsAndCondition(),
        sortField,
        sortOrder,
        isArchive,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };
  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  useEffect(() => {
    fetchTermsAndCondition();
  }, [limit, page]); // eslint-disable-line
  useEffect(() => {
    if (initialFetchDone) {
      setPage(1);
      fetchTermsAndCondition();
    }
  }, [isArchive]); // eslint-disable-line
  useEffect(() => {
    setIsArchive(location.pathname === TERMSANDCONDITION_ARCHIVE);
    setInitialFetchDone(true);
  }, [location]);

  const handleGoBack = () => {
    navigate(TERMSANDCONDITION_LIST);
  };

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "name",
      value: "name",
      label: "Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "description",
      value: "description",
      label: "Description",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "updated_at",
      value: "updated_at",
      label: "Updated Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">
                {pluralize(TERMSANDCONDITION_TITLE)}{" "}
                {isArchive ? "Archive" : "List"}
              </h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchTermsAndCondition}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="col-2 text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {isArchive ? (
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          ) : (
            <>
              {permissions.add && (
                <CustomButton
                  label={`Add`}
                  variant="success"
                  onClick={handleRowCreateButtonClick}
                />
              )}
              {permissions.delete && (
                <CustomButton
                  label={`Archive`}
                  variant="danger"
                  onClick={handleArchiveButtonClick}
                  customeClass="ms-2"
                />
              )}
            </>
          )}
        </Col>
      </Row>

      <div className="mb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"TermsAndCondition"} // Pass optional fields to CustomDataTable
        />
      </div>
      <CustomArchiveModal
        title={TERMSANDCONDITION_TITLE}
        model={TermsAndCondition}
        getData={fetchTermsAndCondition}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={TERMSANDCONDITION_TITLE}
        model={TermsAndCondition}
        getData={fetchTermsAndCondition}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
    </>
  );
}

export default List;
