import { Model } from "@tailflow/laravel-orion/lib/model";

export class InwardDocument extends Model<{
  supplier_id: any;
  title: string;
  document_number: string;
  document_date: string;
  amendment: number;
  delivery_date: string;
  indent_number: string;
  indent_date: string;
  payment_term_id: any;
  store_id: any;
  kind_attention: string;
  order_date: string;
  total_tax: number;
  total_amount: number;
  advance_pay: number;
  comments: string;
  status: string;
  organization_id: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}> {
  public $resource(): string {
    return "inward-document";
  }
}
