import { RouteObject } from "react-router-dom";
import PermissionList from "../../Pages/Setting/Permission/List";
import AddUpdate from "../../Pages/Setting/Permission/AddUpdate";

export const PERMISSION_TITLE = "Permission";
export const PERMISSION_LIST = "/permissions";
export const PERMISSION_ADD = "/permissions/add";
export const PERMISSION_EDIT = "/permissions/:id/edit";

const Permission: RouteObject[] = [
  { path: PERMISSION_LIST, element: <PermissionList /> },
  { path: PERMISSION_ADD, element: <AddUpdate /> },
  { path: PERMISSION_EDIT, element: <AddUpdate /> },
];

export default Permission;
