import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/Role/List";
import AddUpdate from "../../Pages/Setting/Role/AddUpdate";

export const ROLE_TITLE = "Role";
export const ROLE_LIST = "/roles";
export const ROLE_ADD = "/roles/add";
export const ROLE_EDIT = "/roles/:id/edit";
export const ROLE_ARCHIVE = "/roles/archive";

const Role: RouteObject[] = [
  { path: ROLE_LIST, element: <List /> },
  {
    path: ROLE_ADD,
    element: <AddUpdate title={ROLE_TITLE} listRoute={ROLE_LIST} />,
  },
  {
    path: ROLE_EDIT,
    element: <AddUpdate title={ROLE_TITLE} listRoute={ROLE_LIST} />,
  },
  { path: ROLE_ARCHIVE, element: <List /> },
];

export default Role;
