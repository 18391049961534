import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { STOCK_INVENTORY_TITLE } from "../../../Routes/Inventory/StockInventory";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StockInventory } from "../../../Model/Inventory/StockInventory";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import { InputValues } from "../../../Components/CustomFilter";
import CustomSkeleton from "../../../Components/CustomSkeleton";

const View = () => {
  const { document_number } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [includeWith] = useState([
    "product",
    "batch",
    "store",
    "organization",
    "unitOfMeasurement",
  ]);
  const [isArchive] = useState<boolean>(false);
  const [setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const [sortField] = useState<string>("document_number");
  const [sortOrder] = useState<string>("desc");

  useEffect(() => {
    if (document_number) {
      setInputValues({ document_number: { value: document_number } });
    }
  }, [document_number]);

  const fetchStockData = async () => {
    try {
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new StockInventory(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
      setLimit(limit);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  useEffect(() => {
    if (
      Object.entries(inputValues).length !== 0 &&
      inputValues.constructor === Object
    ) {
      fetchStockData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues]);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <div>
          <h5>View {STOCK_INVENTORY_TITLE}</h5>
        </div>
      </div>
      <div
        style={{
          border: !loading ? "1px solid black" : undefined,
          padding: !loading ? "15px" : 0,
        }}
        className="mb-5"
      >
        {loading ? (
          <CustomSkeleton
            height={200}
            width={"100%"}
            center={true}
            customOuterStyle={{ padding: 0 }}
          />
        ) : (
          <>
            <div
              className="Tax Invoice"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "bold",
              }}
            >
              <span>
                <p className="mb-0 mt-2">{STOCK_INVENTORY_TITLE}</p>
                <p>{data[0]?.document_number}</p>
              </span>
            </div>
            <div className="Invoice Details">
              <div className="row" style={{ padding: "10px" }}>
                <div className="col">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}> Document Type : </span>
                    {data[0]?.document_type
                      ? data[0]?.document_type?.split("(")[0]
                      : "Not Found"}
                  </p>
                </div>
                <div className="col">
                  <p className="mb-0">
                    <span style={{ fontWeight: "500" }}> Created By : </span>
                    {data[0]?.organization.company_name
                      ? data[0]?.organization.company_name
                      : "Not Found"}
                  </p>
                </div>
              </div>
            </div>
            <div className="table w-100 col-md-12">
              <Table
                responsive
                bordered
                style={{ border: "1px solid black", textAlign: "center" }}
                borderless
              >
                <thead className="bg-transparent">
                  <tr className="bg-transparent">
                    <th className="bg-transparent">#</th>
                    <th className="bg-transparent">Product Id</th>
                    <th className="bg-transparent">Product Name</th>
                    <th className="bg-transparent">Previous Batch Quantity</th>
                    <th className="bg-transparent">Change Batch Quantity</th>
                    <th className="bg-transparent">New Batch Quantity</th>
                    <th className="bg-transparent">Unit</th>
                    <th className="bg-transparent">Store</th>
                    <th className="bg-transparent">Comment</th>
                  </tr>
                </thead>
                <tbody className="bg-transparent">
                  {data?.map((item: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td className="bg-transparent">{index + 1}</td>
                        <td className="bg-transparent" style={{ width: "10%" }}>
                          {item?.product?.product_id ? (
                            <div style={{ textAlign: "left" }}>
                              {item.product.product_id}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="bg-transparent" style={{ width: "20%" }}>
                          {item?.product?.product_name ? (
                            <div style={{ textAlign: "left" }}>
                              {item.product.product_name}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td className="bg-transparent" style={{ width: "15%" }}>
                          {item?.old_quantity_by_batch
                            ? item?.old_quantity_by_batch
                            : "-"}
                        </td>
                        <td className="bg-transparent" style={{ width: "15%" }}>
                          <span
                            style={{ fontWeight: "500" }}
                            className={
                              item?.change_quantity_by_batch < 0
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {item?.change_quantity_by_batch
                              ? item?.change_quantity_by_batch
                              : "-"}{" "}
                          </span>
                        </td>
                        <td className="bg-transparent" style={{ width: "15%" }}>
                          {item?.new_quantity_by_batch
                            ? item?.new_quantity_by_batch
                            : "-"}
                        </td>
                        <td className="bg-transparent" style={{ width: "10%" }}>
                          {item?.unit_of_measurement?.name
                            ? item?.unit_of_measurement?.name
                            : "-"}
                        </td>
                        <td className="bg-transparent" style={{ width: "20%" }}>
                          {item?.store ? item?.store?.name : "-"}
                        </td>
                        <td className="bg-transparent" style={{ width: "20%" }}>
                          {item?.comment ? item?.comment : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default View;
