import React from "react";

interface EditButtonProps {
  onClick: () => void;
  title?: string;
}

const EditButton: React.FC<EditButtonProps> = ({ onClick, title }) => (
  <div
    style={{
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 12,
      paddingRight: 12,
      background: "white",
      borderRadius: 5,
      overflow: "hidden",
      border: "1.50px #EAECF0 solid",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 10,
      display: "inline-flex",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <div
      style={{
        color: "black",
        fontSize: 20,
      }}
    >
      {title ? title : "Edit"}
    </div>
  </div>
);

export default EditButton;
