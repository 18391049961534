import "bootstrap/dist/css/bootstrap.min.css";
import { setToast } from "../../../Util/Toast";
import { useNavigate, useParams } from "react-router-dom";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { useEffect, useState } from "react";
import { DynamicFormFields } from "../../../Util/DynamicFormFields";
import { Country } from "../../../Model/Setting/Country";
import { Currency } from "../../../Model/Setting/Currency";
import { useApi } from "../../../Controller/ApiController";

interface AddUpdateProps {
  title?: any;
  listRoute?: any;
  isNotUpdate?: any;
  onChangeModalStatus?: (status: boolean) => void;
}

const AddUpdate: React.FC<AddUpdateProps> = ({
  isNotUpdate,
  title,
  listRoute,
  onChangeModalStatus,
}) => {
  const navigate = useNavigate();
  let { id }: any = useParams();
  if (isNotUpdate) {
    id = null;
  }
  const { apiGet } = useApi();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({ active: 1 });
  const [formError, setFormError] = useState<string | null>(null);
  const [dynamicFormFields, setDynamicFormFields] = useState([]); // eslint-disable-line
  const [customSubmitting, setCustomSubmitting] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    fetchCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchCurrencies = async () => {
    try {
      const response = await apiGet("get-currencies");
      const codes = Object.keys(response);
      const options = codes.map((code) => ({ label: code, value: code }));
      setCurrencyOptions(options);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setToast("error", error.response.data.errors);
      } else if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };

  const formFields = [
    {
      id: "inputCurrencyCode",
      label: "Currency Code*",
      type: "select",
      col: 6,
      name: "code",
      options: currencyOptions.map((option) => ({
        label: option.label,
        value: option.value,
      })),
      placeholder: "Enter Currency Code",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Currency Code is required",
        },
      ],
    },
    {
      id: "inputSymbol",
      label: "Symbol*",
      type: "text",
      col: 6,
      name: "symbol",
      placeholder: "Enter Symbol",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Symbol is required",
        },
      ],
      description: "Indicate the country in which the Currency is located.",
    },
    {
      id: "inputcountry_id",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select a Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Indicate the country in which the Currency is located.",
    },
    {
      id: "input_active",
      label: "Active*",
      type: "boolean",
      labelstyle: { fontSize: "15px" },
      col: 6,
      name: "active",
    },
  ];

  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);
    if (inputData?.country_id) {
      const country_id = parseInt(inputData.country_id?.value);
      inputData.country_id = country_id;
    }
    if (inputData?.code) {
      inputData.code = inputData.code.value;
    }
    const { options, ...newObj } = inputData;
    try {
      if (id) {
        await Currency.$query().update(id, newObj);
        setToast("success", `${title} Successfully Updated`);
      } else {
        await Currency.$query().store(newObj);
        setToast("success", `${title} Successfully Added`);
      }
      navigate(`${listRoute?.toLowerCase().replace(" ", "-")}`);
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (typeof error.response?.data?.message === "object") {
        const dynamicErrors = Object.keys(error.response.data.message)
          .map((key) => error.response.data.message[key])
          .filter((value) => typeof value === "string");
        setFormError(dynamicErrors.join(", "));
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async (itemId: string) => {
    const item: any = await Currency.$query().with(["country"]).find(itemId);
    return item.$attributes;
  };

  useEffect(() => {
    DynamicFormFields(formFields, "currency")?.then((updatedFormFields) => {
      setDynamicFormFields(updatedFormFields);
    });

    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const itemData = await getFormData(id);
          let data: any = itemData;
          if (data["country_id"]) {
            data["country_id"] = {
              label: itemData.country["name"],
              value: itemData.country["id"],
            };
          }
          if (data["code"]) {
            data["code"] = {
              label: itemData.code,
              value: itemData.code,
            };
          }
          setFormData(data);
          setLoading(false);
        } catch (error: any) {
          setToast("error", error.response.data.message);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {currencyOptions.length > 0 ? (
        <div>
          <AddUpdateForm
            formFields={formFields}
            model={Currency}
            title={title}
            customFormSubmit={handleCustomSubmit}
            listRoute={listRoute}
            entityName={"currency"}
            entityType={"currency"}
            dynamicFormData={formData}
            isDynamicFormFields={false}
            isNotUpdate={isNotUpdate}
            dynamicFormError={formError}
            customSubmitting={customSubmitting}
            loadingStatus={loading}
            onChangeModalStatus={onChangeModalStatus}
          />
        </div>
      ) : (
        <p>Loading currency options...</p>
      )}
    </>
  );
};

export default AddUpdate;
