import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setToast } from "../../../Util/Toast";
import { getUser } from "../../../Util/Auth";
import { Badge, Table } from "react-bootstrap";
import { InwardDocument } from "../../../Model/SalesAndPurchase/InwardDocument/InwardDocument";
import {
  INWARD_DOCUMENT_LIST,
  INWARD_DOCUMENT_TITLE,
} from "../../../Routes/SalesAndPurchase/InwardDocument";
import { DateWithoutTime } from "../../../Util/DateFormat";
import { Attachment } from "../../../Model/Setting/Attachment";
import {
  FiletypeDoc,
  FiletypeDocx,
  FiletypePdf,
  FiletypePptx,
  FiletypeCsv,
  FiletypeXls,
  FiletypeXlsx,
  FiletypePpt,
  ExclamationTriangle,
  FileEarmarkImage,
  Download,
} from "react-bootstrap-icons";
import { Signature } from "../../../Model/Setting/Signature";
import { getCookie } from "typescript-cookie";
import CustomSkeleton from "../../../Components/CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../../../Util/CapitalizeAndRemoveUnderscore";
import CustomButton from "../../../Components/Button/CustomButton";

function View() {
  const { id } = useParams();
  const { inward_id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [supplierBillingAddress, setSupplieBillingAddress] = useState<any>([]);
  const [buyerDeliveryAddress, setBuyerDeliveryAddress] = useState<any>([]);
  const [buyerBillingAddress, setBuyerBillingAddress] = useState<any>([]);
  const [attachmentData, setAttachmentData] = useState<any>([]);
  const [signatureData, setSignatureData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [supplierName, setSupplierName] = useState<any>("");
  const [storeId, setStoreId] = useState<any>("");
  const [manufactureBillingAddress, setManufactureBillingAddress] =
    useState<any>([]);
  const [manufactureName, setManufactureName] = useState<any>("");

  const user = getUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getDataId = inward_id || id;
        getDataId && (await getData(getDataId));
        getDataId && (await getAttchmentData(getDataId));
        getDataId && (await getSignatureData(getDataId));
      } catch (error: any) {
        setToast("error", error.response?.data.message);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, inward_id]);

  const getData = async (itemId: string) => {
    try {
      let item: any = await InwardDocument.$query()
        .with([
          "items",
          "items.batch",
          "items.product",
          "invoiceNumber",
          "stores",
          "supplierBillingAddress",
          "supplierBillingAddress.counterPartyCompany",
          "buyerBillingAddress",
          "buyerDeliveryAddress",
          "purchaseOrderNumber",
          "items.unitOfMeasurement",
          "items.purchaseOrderItems.product",
          "buyerDeliveryAddress.country",
          "buyerBillingAddress.country",
          "supplierBillingAddress.country",
          "manufactureBillingAddress.country",
          "manufactureBillingAddress.counterPartyCompany",
        ])
        .find(itemId);
      setSupplieBillingAddress(
        item.$attributes.status === "sent" &&
          item.$attributes.supplier_billing_address_data
          ? JSON.parse(item.$attributes.supplier_billing_address_data)
          : item.$attributes.supplier_billing_address,
      );
      setBuyerDeliveryAddress(
        item.$attributes.status === "sent" &&
          item.$attributes.buyer_delivery_address_data
          ? JSON.parse(item.$attributes.buyer_delivery_address_data)
          : item.$attributes.buyer_delivery_address,
      );

      setBuyerBillingAddress(
        item.$attributes.status === "sent" &&
          item.$attributes.buyer_billing_address_data
          ? JSON.parse(item.$attributes.buyer_billing_address_data)
          : item.$attributes.buyer_billing_address,
      );
      setManufactureBillingAddress(
        item.$attributes.status !== "draft" &&
          item.$attributes.manufacture_billing_address_data
          ? JSON.parse(item.$attributes.manufacture_billing_address_data)
          : item.$attributes.manufacture_billing_address,
      );

      setManufactureName(
        item.$attributes.status !== "draft" && item.$attributes.manufacture_name
          ? item.$attributes.manufacture_name
          : item.$attributes.manufacture_billing_address?.counter_party_company
              ?.company_name,
      );

      setSupplierName(
        item.$attributes.status === "sent" && item.$attributes.supplier_name
          ? item.$attributes.supplier_name
          : item.$attributes.supplier_billing_address?.counter_party_company
              ?.company_name,
      );
      if (item.$attributes.store_id) {
        setStoreId(item.$attributes.store_id);
      }
      setData(item.$attributes);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setLoading(false);
    }
  };
  const getAttchmentData = async (itemId: string) => {
    try {
      const response: any = await Attachment.$query()
        .getHttpClient()
        .get(`/search?field=inward_document_id&operator==&value=${itemId}`);

      setAttachmentData(response.data.data);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  const getSignatureData = async (itemId: string) => {
    try {
      const response: any = await Signature.$query()
        .getHttpClient()
        .get(`/search?field=inward_document_id&operator==&value=${itemId}`);
      setSignatureData(response.data.data);
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  const getFileTypeImage = (filePath: string) => {
    const extension = filePath.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "png":
      case "jpeg":
      case "jpg":
      case "gif":
        return (
          <span>
            <FileEarmarkImage
              style={{ fill: "grey" }}
              width={"100%"}
              height={"30px"}
            ></FileEarmarkImage>
          </span>
        );
      case "pdf":
        return (
          <FiletypePdf
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePdf>
        );
      case "ppt":
        return (
          <FiletypePpt
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePpt>
        );
      case "pptx":
        return (
          <FiletypePptx
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypePptx>
        );
      case "doc":
        return (
          <FiletypeDoc
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeDoc>
        );
      case "docx":
        return (
          <FiletypeDocx
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeDocx>
        );
      case "csv":
        return (
          <FiletypeCsv
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeCsv>
        );
      case "xls":
        return (
          <FiletypeXls
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeXls>
        );
      case "xlsx":
        return (
          <FiletypeXlsx
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></FiletypeXlsx>
        );
      default:
        return (
          <ExclamationTriangle
            style={{ fill: "grey" }}
            width={"100%"}
            height={"30px"}
          ></ExclamationTriangle>
        );
    }
  };

  const handleDownload = (filePath: any, title: any) => {
    if (!filePath) {
      return;
    }
    const downloadUrl = filePath;
    const isImage = /\.(jpeg|jpg|png|gif|pdf)$/i.test(filePath);

    if (isImage) {
      window.open(downloadUrl, "_blank");
    } else {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", title || "download");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const groupedItems: any = {};

  // Combine items and charges arrays
  const allItems = data?.items?.concat(data?.charges);

  allItems?.forEach((item: any) => {
    if (item?.tax) {
      const taxName = item?.tax?.tax_name; // Assuming a default name for items without a tax
      if (!groupedItems[taxName]) {
        groupedItems[taxName] = [];
      }
      groupedItems[taxName].push(item);
    }
  });

  const GoBack = () => {
    navigate(INWARD_DOCUMENT_LIST);
  };
  const handlePDFDownloadButtonClick = async () => {
    try {
      const download_pdf_id = (await id) ? id : inward_id ? inward_id : null;
      const authToken = getCookie("authToken");
      const token = authToken ? authToken.replace(/["']/g, "") : "";
      const apiUrl = process.env.REACT_APP_API_URL + `api/inward-document/pdf/`;
      const response = await fetch(apiUrl + download_pdf_id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const anchor = document.createElement("a");
        anchor.href = responseData.data;
        anchor.download = "purchase order";
        anchor.rel = "noreferrer";
        anchor.target = "_blank";
        anchor.click();
      } else {
        setToast("error", "Failed to download pdf");
      }
    } catch (error: any) {
      setToast("error", "An error occurred while processing your request");
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <span>
            <h5>View {INWARD_DOCUMENT_TITLE}</h5>
          </span>
          <span className="ms-2">
            <h5>
              <Badge
                pill
                bg={data.status === "draft" ? "secondary" : "success"}
              >
                {data.status && CapitalizeAndRemoveUnderscore(data.status)}
              </Badge>
            </h5>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!loading && (
            <button
              type="button"
              className="btn btn-secondary btn-sm mb-2 mx-2"
              onClick={handlePDFDownloadButtonClick}
            >
              Download PDF
              <Download className="mx-2" style={{ fill: "white" }} />
            </button>
          )}
          <CustomButton
            customeClass="mb-2"
            label={`Back`}
            variant="secondary"
            onClick={GoBack}
          />
        </div>
      </div>
      {loading ? (
        <CustomSkeleton
          height={500}
          width={"100%"}
          center={true}
          customOuterStyle={{ padding: 0 }}
        />
      ) : (
        <div
          style={{ border: "1px solid black", padding: "15px" }}
          className="mb-5"
        >
          <div
            className="Tax Invoice"
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: "bold",
              borderBottom: "1px solid black",
            }}
          >
            <div>
              <img
                src="/images/svg/ic_logo.svg"
                width="160"
                height="80"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </div>
            <span>
              <p className="mb-0 mt-2">{INWARD_DOCUMENT_TITLE}</p>
              <p>{data.document_number}</p>
            </span>
          </div>

          <div className="address" style={{ fontSize: "11px" }}>
            <div
              className="d-flex justify-content-between"
              style={{ borderBottom: "1px solid black" }}
            >
              <div
                className="col-md-6 py-1 pe-1"
                style={{ borderRight: "1px solid black" }}
              >
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Goods Received By </span>
                      {user.organization.company_name && (
                        <span style={{ fontWeight: "500" }}>
                          : {user.organization.company_name}
                        </span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!buyerBillingAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {buyerBillingAddress?.address_name &&
                          buyerBillingAddress?.address_name + ", "}
                        {buyerBillingAddress?.address_line1 &&
                          buyerBillingAddress?.address_line1 + ", "}
                        {buyerBillingAddress?.address_line2 &&
                          buyerBillingAddress?.address_line2 + ", "}
                        {buyerBillingAddress?.city &&
                          buyerBillingAddress?.city + ", "}
                        {buyerBillingAddress?.state &&
                          buyerBillingAddress?.state + ", "}
                        {buyerBillingAddress?.country?.name &&
                          buyerBillingAddress?.country?.name}
                        {buyerBillingAddress?.zip_code &&
                          "-" + buyerBillingAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      <p className="mb-0">
                        <span style={{ fontWeight: "500" }}>
                          Contact Person :
                        </span>
                        {user?.first_name} {user?.last_name}
                      </p>
                      {buyerBillingAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {buyerBillingAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {buyerBillingAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {buyerBillingAddress?.phone_no}
                        </p>
                      )}
                      {buyerBillingAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {buyerBillingAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-1 ps-1">
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Goods Sent By </span>
                      {supplierName && (
                        <span style={{ fontWeight: "500" }}>
                          : {supplierName}
                        </span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!supplierBillingAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {supplierBillingAddress?.address_name &&
                          supplierBillingAddress?.address_name + ", "}
                        {supplierBillingAddress?.address_line1 &&
                          supplierBillingAddress?.address_line1 + ", "}
                        {supplierBillingAddress?.address_line2 &&
                          supplierBillingAddress?.address_line2 + ", "}
                        {supplierBillingAddress?.city &&
                          supplierBillingAddress?.city + ", "}
                        {supplierBillingAddress?.state &&
                          supplierBillingAddress?.state + ", "}
                        {supplierBillingAddress?.country?.name &&
                          supplierBillingAddress?.country?.name}
                        {supplierBillingAddress?.zip_code &&
                          "-" + supplierBillingAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      {supplierBillingAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {supplierBillingAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {supplierBillingAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {supplierBillingAddress?.phone_no}
                        </p>
                      )}
                      {supplierBillingAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {supplierBillingAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-between"
              style={{ borderBottom: "1px solid black" }}
            >
              <div
                className="col-md-6 py-1 pe-1"
                style={{ borderRight: "1px solid black" }}
              >
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Shipped To </span>
                      {user.organization.company_name && (
                        <span style={{ fontWeight: "500" }}>
                          : {user.organization.company_name}
                        </span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!buyerDeliveryAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {buyerDeliveryAddress?.address_name &&
                          buyerDeliveryAddress?.address_name + ", "}
                        {buyerDeliveryAddress?.address_line1 &&
                          buyerDeliveryAddress?.address_line1 + ", "}
                        {buyerDeliveryAddress?.address_line2 &&
                          buyerDeliveryAddress?.address_line2 + ", "}
                        {buyerDeliveryAddress?.city &&
                          buyerDeliveryAddress?.city + ", "}
                        {buyerDeliveryAddress?.state &&
                          buyerDeliveryAddress?.state + ", "}
                        {buyerDeliveryAddress?.country?.name &&
                          buyerDeliveryAddress?.country?.name}
                        {buyerDeliveryAddress?.zip_code &&
                          "-" + buyerDeliveryAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      {buyerDeliveryAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {buyerDeliveryAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {buyerDeliveryAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {buyerDeliveryAddress?.phone_no}
                        </p>
                      )}
                      {buyerDeliveryAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {buyerDeliveryAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-1 ps-1">
                <div className="col-md-12 pb-1" style={{}}>
                  <div className="row">
                    <div
                      className="col-12 d-flex"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      <span className="pe-1">Manufactured By </span>
                      {manufactureName && (
                        <span style={{ fontWeight: "500" }}>
                          : {manufactureName}
                        </span>
                      )}
                    </div>
                    <div className="col-12 py-1">
                      {!manufactureBillingAddress && (
                        <p className="text-center">Address is not found</p>
                      )}
                      <span className="text-justify">
                        {manufactureBillingAddress?.address_name &&
                          manufactureBillingAddress?.address_name + ", "}
                        {manufactureBillingAddress?.address_line1 &&
                          manufactureBillingAddress?.address_line1 + ", "}
                        {manufactureBillingAddress?.address_line2 &&
                          manufactureBillingAddress?.address_line2 + ", "}
                        {manufactureBillingAddress?.city &&
                          manufactureBillingAddress?.city + ", "}
                        {manufactureBillingAddress?.state &&
                          manufactureBillingAddress?.state + ", "}
                        {manufactureBillingAddress?.country?.name &&
                          manufactureBillingAddress?.country?.name}
                        {manufactureBillingAddress?.zip_code &&
                          "-" + manufactureBillingAddress?.zip_code}
                      </span>
                    </div>
                    <div className="col-6">
                      {manufactureBillingAddress?.email && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Email : </span>
                          {manufactureBillingAddress?.email}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {manufactureBillingAddress?.phone_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>
                            Contact No :{" "}
                          </span>
                          {manufactureBillingAddress?.phone_no}
                        </p>
                      )}
                      {manufactureBillingAddress?.fax_no && (
                        <p className="mb-0">
                          <span style={{ fontWeight: "500" }}>Fax No : </span>
                          {manufactureBillingAddress?.fax_no}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Invoice Details">
            <div
              className="my-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
              }}
            >
              <span
                className="mt-1"
                style={{ flex: 1, borderBottom: "1px solid black" }}
              ></span>
              <span
                style={{
                  padding: "0 10px",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {INWARD_DOCUMENT_TITLE} Details
              </span>
              <span
                className="mt-1"
                style={{ flex: 1, borderBottom: "1px solid black" }}
              ></span>
            </div>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Inward Number : </span>
                  {data.document_number ? data.document_number : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Inward Date : </span>
                  {data.created_at
                    ? DateWithoutTime(data.document_date)
                    : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Delivery Date : </span>
                  {data.delivery_date
                    ? DateWithoutTime(data.delivery_date)
                    : "Not Found"}
                </p>
              </div>
            </div>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Amendment : </span>
                  {data.amendment ? data.amendment : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> PO Number : </span>
                  {data?.purchase_order_number
                    ? data?.purchase_order_number.document_number
                    : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>PO Date : </span>
                  {data.purchase_order_date
                    ? DateWithoutTime(data.purchase_order_date)
                    : "Not Found"}
                </p>
              </div>
            </div>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Invoice Number : </span>
                  {data.invoice_number && data.invoice_number.document_number
                    ? data.invoice_number.document_number
                    : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Invoice Date : </span>
                  {data?.tax_invoice_date
                    ? DateWithoutTime(data?.tax_invoice_date)
                    : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>Challan Number : </span>
                  {data.delivery_challan_number
                    ? data.delivery_challan_number
                    : "Not Found"}
                </p>
              </div>
            </div>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}> Challan Date : </span>
                  {data.delivery_challan_date
                    ? DateWithoutTime(data.delivery_challan_date)
                    : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>Transporter Name : </span>
                  {data.transporter_name ? data.transporter_name : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>
                    Transporter Doc Number :{" "}
                  </span>
                  {data.transportation_document_number
                    ? data.transportation_document_number
                    : "Not Found"}
                </p>
              </div>
            </div>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>
                    Transportation Doc Date :{" "}
                  </span>
                  {data.transportation_document_date
                    ? DateWithoutTime(data.transportation_document_date)
                    : "Not Added"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>Vehicle Number : </span>
                  {data.vehicle_number ? data.vehicle_number : "Not Found"}
                </p>
              </div>
              <div className="col-4">
                <p className="mb-0">
                  <span style={{ fontWeight: "500" }}>Store : </span>
                  {data.stores?.name ? data.stores?.name : "Not Found"}
                </p>
              </div>
            </div>
          </div>

          <div className="table w-100 col-md-12 my-2">
            <Table
              responsive
              bordered
              style={{
                border: "1px solid black",
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              <thead className="bg-transparent">
                <tr className="bg-transparent">
                  <th className="bg-transparent">#</th>
                  <th className="bg-transparent">Description</th>
                  {!storeId && (
                    <th className="bg-transparent">Quantity Ordered</th>
                  )}
                  <th className="bg-transparent">Unit</th>
                  {!storeId && (
                    <th className="bg-transparent">Delivered Earlier</th>
                  )}
                  <th className="bg-transparent">Delivered Today</th>
                  {!storeId && <th className="bg-transparent">Balance</th>}
                  <th className="bg-transparent">Comments</th>
                </tr>
              </thead>
              <tbody className="bg-transparent">
                {data?.items?.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td className="bg-transparent">{index + 1}</td>
                      <td className="bg-transparent" style={{ width: "30%" }}>
                        {item?.product ? (
                          <div style={{ textAlign: "left" }}>
                            {data.status === "sent" && item.products_data
                              ? JSON.parse(item.products_data)?.product_name
                              : item.product?.product_name}
                            <br />
                            <span style={{ fontSize: "small" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Item ID:{" "}
                              </span>
                              {data.status === "sent" && item.products_data
                                ? JSON.parse(item.products_data)?.product_id
                                : item.product?.product_id}
                              <span style={{ fontWeight: "700" }}>|</span>
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Description:{" "}
                              </span>
                              {data.status === "sent" && item.products_data
                                ? JSON.parse(item.products_data)?.description
                                : item.product?.description}{" "}
                              {item.batch && (
                                <>
                                  <span style={{ fontWeight: "700" }}>|</span>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Batch Number:{" "}
                                  </span>
                                  {data.status === "sent" && item.batches_data
                                    ? JSON.parse(item.batches_data)
                                        ?.batch_number
                                    : item.batch.batch_number}{" "}
                                  <span style={{ fontWeight: "700" }}>|</span>
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Expiry Date:{" "}
                                  </span>
                                  {data.status === "sent" && item.batches_data
                                    ? DateWithoutTime(
                                        JSON.parse(item.batches_data)
                                          ?.expiry_date,
                                      )
                                    : DateWithoutTime(
                                        item.batch.expiry_date,
                                      )}{" "}
                                </>
                              )}
                            </span>
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>
                      {!storeId && (
                        <td className="bg-transparent" style={{ width: "10%" }}>
                          {item["quantity"] ? item["quantity"] : "-"}
                        </td>
                      )}
                      <td className="bg-transparent">
                        {data.status === "sent" && item.unit_of_measurement_data
                          ? JSON.parse(item.unit_of_measurement_data)?.name
                          : item?.unit_of_measurement?.name}
                      </td>
                      {!storeId && (
                        <td className="bg-transparent">
                          {item.delivered && data.status !== "sent"
                            ? item.delivered
                            : item.delivered && item.delivered_now
                            ? item.delivered - item.delivered_now
                            : "-"}
                        </td>
                      )}
                      <td className="bg-transparent">
                        {item.delivered_now ? item.delivered_now : "-"}
                      </td>
                      {!storeId && (
                        <td className="bg-transparent">
                          {item.balance === 0 ? 0 : item.balance || "-"}
                        </td>
                      )}
                      <td className="bg-transparent">
                        {item.comments ? item.comments : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {data?.charges?.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="mt-1"
                    style={{ flex: 1, borderBottom: "1px solid black" }}
                  ></span>
                  <span style={{ padding: "0 10px", fontWeight: "500" }}>
                    Extra Charges
                  </span>
                  <span
                    className="mt-1"
                    style={{ flex: 1, borderBottom: "1px solid black" }}
                  ></span>
                </div>
                <Table
                  responsive
                  bordered
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    marginTop: "20px",
                    fontSize: "12px",
                  }}
                >
                  <thead className="bg-transparent">
                    <tr className="bg-transparent">
                      <th className="bg-transparent">Description</th>
                      <th className="bg-transparent">Total Before Tax</th>
                      <th className="bg-transparent">Total Tax</th>
                      <th className="bg-transparent">Total After Tax</th>
                    </tr>
                  </thead>
                  <tbody className="bg-transparent">
                    {data?.charges?.map((item: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td
                            className="bg-transparent"
                            style={{ textAlign: "left" }}
                          >
                            {item.extra_charge_description}
                          </td>
                          <td className="bg-transparent">
                            {item.total ? item.total : "-"}
                          </td>
                          <td className="bg-transparent">
                            {item.total_tax ? item.total_tax : "-"}
                          </td>
                          <td className="bg-transparent">
                            {(
                              Number(item.total) + Number(item.total_tax)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )}
          </div>
          <div
            className="py-2"
            style={{ borderBottom: "1px solid lightgrey", fontSize: "12px" }}
          >
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              Comments:
            </span>
            <p className="m-0">{data.comments || "Not Added"}</p>
          </div>

          <div
            className="py-2"
            style={{ borderBottom: "1px solid lightgrey", fontSize: "12px" }}
          >
            <span key={data.id}>
              <div>
                <span style={{ fontSize: "14px", fontWeight: "500" }}>
                  Existing Attachments:
                </span>
                <span className="d-flex flex-wrap align-items-center mb-0 mt-2">
                  {attachmentData && attachmentData.length > 0 ? (
                    attachmentData.map((data: any) => (
                      <span>
                        <div
                          className="text-center me-2 p-2"
                          style={{
                            background: "#dddddd",
                            cursor: "pointer",
                            flexBasis: "13%",
                            flexShrink: 0,
                            minWidth: "100px",
                            borderRadius: "5px",
                          }}
                          onClick={() =>
                            handleDownload(data?.file_path, data?.title)
                          }
                        >
                          {getFileTypeImage(data.file_path)}
                          <div style={{ fontSize: "10px", marginTop: "5px" }}>
                            {data.file_path.split("/").pop()}
                          </div>
                        </div>
                      </span>
                    ))
                  ) : (
                    <p className="mb-0">Not Added</p>
                  )}
                </span>
              </div>
            </span>
          </div>

          <div style={{ width: "300px", marginLeft: "auto" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <span className="d-flex justify-content-end my-2">
                For {user.organization.company_name}
              </span>
              <span className="">
                {signatureData && signatureData.length > 0 ? (
                  signatureData.map((data: any) => (
                    <div
                      className="text-end"
                      style={{ flexBasis: "13%", flexShrink: 0 }}
                    >
                      <img
                        src={`${data?.file_path}?${Date.now()}`}
                        alt={data?.title}
                        className="uploaded-image"
                        style={{
                          maxWidth: "150px",
                          maxHeight: "50pt",
                          borderRadius: "3px",
                          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <p
                    className="mb-0"
                    style={{
                      width: "150px",
                      height: "50pt",
                    }}
                  ></p>
                )}
              </span>
              <span className="d-flex justify-content-end my-2">
                Authorised Signatory
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default View;
