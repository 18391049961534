import { Model } from "@tailflow/laravel-orion/lib/model";

export class Payment extends Model<{
  id: number;
  invoice: any;
  invoice_id: number;
  amount: number;
  buyer: any;
  buyer_id: number;
  supplier: any;
  supplier_id: number;
  payment_mode: string;
  payment_reference_number: string;
  payment_type: string;
  status: string;
  document_number: string;
  payment_date: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "payments";
  }
}
