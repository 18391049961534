import { RouteObject } from "react-router-dom";
import List from "../../Pages/SalesAndPurchase/SalesReturn/List";
import AddUpdate from "../../Pages/SalesAndPurchase/SalesReturn/AddUpdate";
import View from "../../Pages/SalesAndPurchase/SalesReturn/View";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_SALES_RETURN,
  VIEW_SALES_RETURN,
  UPDATE_SALES_RETURN,
  DELETE_SALES_RETURN,
  // RESTORE_SALES_RETURN
} from "../../Util/PermissionList";

export const SALES_RETURN_TITLE = "Sales Return";
export const SALES_RETURN_LIST = "/sales-return";
export const SALES_RETURN_DASHBOARD_LIST = "/sales-return/:date";
export const SALES_RETURN_ADD = "/sales-return/create-sales-return";
export const SALES_RETURN_VIEW = "/sales-return/:id/view";
export const SALES_RETURN_EDIT = "/sales-return/:id/edit-sales-return";
export const SALES_RETURN_LIST_BY_TAX_INVOICE =
  "/tax-invoice/:tax_invoice_id/sales-return";
export const SALES_RETURN_VIEW_BY_TAX_INVOICE =
  "/tax-invoice/:tax_invoice_id/sales-return/:id/view";
export const SALES_RETURN_ADD_BY_TAX_INVOICE =
  "/tax-invoice/:tax_invoice_id/sales-return/create-sales-return";
export const SALES_RETURN_EDIT_BY_TAX_INVOICE =
  "/tax-invoice/:tax_invoice_id/sales-return/:id/edit-sales-return";

const SalesReturn: RouteObject[] = [
  {
    path: SALES_RETURN_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: SALES_RETURN_DASHBOARD_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: SALES_RETURN_ADD,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
      />
    ),
  },
  {
    path: SALES_RETURN_VIEW,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
      />
    ),
  },
  {
    path: SALES_RETURN_EDIT,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
      />
    ),
  },
  {
    path: SALES_RETURN_LIST_BY_TAX_INVOICE,
    element: (
      <AuthorizedRoute
        permission={VIEW_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: SALES_RETURN_ADD_BY_TAX_INVOICE,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={ADD_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
      />
    ),
  },
  {
    path: SALES_RETURN_VIEW_BY_TAX_INVOICE,
    element: (
      <AuthorizedRoute
        element={<View />}
        permission={VIEW_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
      />
    ),
  },
  {
    path: SALES_RETURN_EDIT_BY_TAX_INVOICE,
    element: (
      <AuthorizedRoute
        element={<AddUpdate />}
        permission={UPDATE_SALES_RETURN}
        checkPermisson={{
          addPermission: ADD_SALES_RETURN,
          updatePermission: UPDATE_SALES_RETURN,
          deletePermission: DELETE_SALES_RETURN,
          viewPermission: VIEW_SALES_RETURN,
        }}
      />
    ),
  },
];

export default SalesReturn;
