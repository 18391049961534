import React from "react";
import { RouteObject } from "react-router-dom";
import List from "../../Pages/Setting/PaymentTerms/List";
import AddUpdate from "../../Pages/Setting/PaymentTerms/AddUpdate";
import AuthorizedRoute from "../../Util/AuthorizedRoute";
import {
  ADD_PAYMENT_TERMS,
  DELETE_PAYMENT_TERMS,
  RESTORE_PAYMENT_TERMS,
  UPDATE_PAYMENT_TERMS,
  VIEW_PAYMENT_TERMS,
} from "../../Util/PermissionList";

export const PAYMENTTERMS_TITLE = "Payment Terms";
export const PAYMENTTERMS_LIST = "/payment-terms";
export const PAYMENTTERMS_ADD = "/payment-terms/add";
export const PAYMENTTERMS_EDIT = "/payment-terms/:id/edit";
export const PAYMENTTERMS_ARCHIVE = "/payment-terms/archive";

const PaymentTerms: RouteObject[] = [
  {
    path: PAYMENTTERMS_LIST,
    element: (
      <AuthorizedRoute
        permission={VIEW_PAYMENT_TERMS}
        checkPermisson={{
          addPermission: ADD_PAYMENT_TERMS,
          updatePermission: UPDATE_PAYMENT_TERMS,
          deletePermission: DELETE_PAYMENT_TERMS,
          restorePermission: RESTORE_PAYMENT_TERMS,
          viewPermission: VIEW_PAYMENT_TERMS,
        }}
        element={<List />}
      />
    ),
  },
  {
    path: PAYMENTTERMS_ADD,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={PAYMENTTERMS_TITLE} listRoute={PAYMENTTERMS_LIST} />
        }
        checkPermisson={{
          addPermission: ADD_PAYMENT_TERMS,
          updatePermission: UPDATE_PAYMENT_TERMS,
          deletePermission: DELETE_PAYMENT_TERMS,
          restorePermission: RESTORE_PAYMENT_TERMS,
          viewPermission: VIEW_PAYMENT_TERMS,
        }}
        permission={ADD_PAYMENT_TERMS}
      />
    ),
  },
  {
    path: PAYMENTTERMS_EDIT,
    element: (
      <AuthorizedRoute
        element={
          <AddUpdate title={PAYMENTTERMS_TITLE} listRoute={PAYMENTTERMS_LIST} />
        }
        checkPermisson={{
          addPermission: ADD_PAYMENT_TERMS,
          updatePermission: UPDATE_PAYMENT_TERMS,
          deletePermission: DELETE_PAYMENT_TERMS,
          restorePermission: RESTORE_PAYMENT_TERMS,
          viewPermission: VIEW_PAYMENT_TERMS,
        }}
        permission={UPDATE_PAYMENT_TERMS}
      />
    ),
  },
  {
    path: PAYMENTTERMS_ARCHIVE,
    element: (
      <AuthorizedRoute
        element={<List />}
        checkPermisson={{
          addPermission: ADD_PAYMENT_TERMS,
          updatePermission: UPDATE_PAYMENT_TERMS,
          deletePermission: DELETE_PAYMENT_TERMS,
          restorePermission: RESTORE_PAYMENT_TERMS,
          viewPermission: VIEW_PAYMENT_TERMS,
        }}
        permission={DELETE_PAYMENT_TERMS}
      />
    ),
  },
];

export default PaymentTerms;
