import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { setToast } from "../Util/Toast";

interface CustomRestoreModalProps {
  title: string;
  model: any;
  getData: (page: number) => void;
  RestoreItemId: number | null;
  showRestoreModal: boolean;
  setRestoreItemId: React.Dispatch<React.SetStateAction<number | null>>;
  setshowRestoreModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRestoreClick?: Function | null;
}

export default function CustomRestoreModal({
  title,
  model,
  getData,
  RestoreItemId,
  showRestoreModal,
  setshowRestoreModal,
  setRestoreItemId,
  handleRestoreClick,
}: CustomRestoreModalProps) {
  const [inputValue, setInputValue] = useState("");
  const isArchiveEnabled = inputValue.toLowerCase() === "restore";

  const handleRowRestore = async () => {
    setshowRestoreModal(false);

    try {
      await model.$query().restore(RestoreItemId);
      getData(1);
      setRestoreItemId(null);
      setToast("success", title + " Successfully restored");
    } catch (error: any) {
      if (error.response?.data?.message) {
        setToast("error", error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    }
  };
  useEffect(() => {
    if (showRestoreModal) {
      setInputValue("");
    }
  }, [showRestoreModal]);
  return (
    <>
      <Modal show={showRestoreModal} onHide={() => setshowRestoreModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Restore</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <input
              type="text"
              placeholder="Type 'Restore' to Confirm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-100 p-2 rounded-2 border-secondary"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setshowRestoreModal(false)}
            className="btn-sm"
          >
            Cancel
          </Button>
          {isArchiveEnabled && (
            <Button
              variant="success"
              onClick={() =>
                handleRestoreClick ? handleRestoreClick() : handleRowRestore()
              }
              className="btn-sm"
            >
              Restore
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
