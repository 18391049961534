import React, { useEffect, useState } from "react";
import { Alert, Card, ListGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { AuditLogIdFieldDetails } from "../Util/AuditLogIdFields";
import { PURCHASE_VIEW } from "../Routes/SalesAndPurchase/Purchase";
import { SALES_QUOTATION_VIEW } from "../Routes/SalesAndPurchase/SalesQuotation";
import { TAX_INVOICE_VIEW } from "../Routes/SalesAndPurchase/TaxInvoice";
import { PROFORMAINVOICES_VIEW } from "../Routes/SalesAndPurchase/ProformaInvoices";
import { INWARD_DOCUMENT_VIEW } from "../Routes/SalesAndPurchase/InwardDocument";
import CustomSkeleton from "./CustomSkeleton";

function CustomViewComponent() {
  let { model, field, id }: any = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [details, setDetails] = useState<any>([]);
  const [noDataFound, setNoDataFound] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [fieldName, setFieldName] = useState<any>(field);

  useEffect(() => {
    field === "purchase_order" &&
      navigate(PURCHASE_VIEW.replace(":id", id.toString()));
    field === "sales_quotation" &&
      navigate(SALES_QUOTATION_VIEW.replace(":id", id.toString()));
    field === "tax_invoices" &&
      navigate(TAX_INVOICE_VIEW.replace(":id", id.toString()));
    field === "proforma_invoice" &&
      navigate(PROFORMAINVOICES_VIEW.replace(":id", id.toString()));
    field === "inward_documents" &&
      navigate(INWARD_DOCUMENT_VIEW.replace(":id", id.toString()));

    if (
      model === "proforma_invoice" ||
      model === "tax_invoices" ||
      model === "sales_quotation"
    ) {
      if (
        fieldName === "buyer_billing_address" ||
        fieldName === "buyer_delivery_address" ||
        fieldName === "manufacture_billing_address"
      ) {
        setFieldName("counter-party-address");
      } else if (fieldName === "supplier_billing_address") {
        setFieldName("organization-address");
      } else if (fieldName === "manufacture" || fieldName === "buyer") {
        setFieldName("counter_party_company");
      }
    }
    if (model === "purchase_order" || model === "inward_documents") {
      if (
        fieldName === "buyer_billing_address" ||
        fieldName === "buyer_delivery_address"
      ) {
        setFieldName("organization-address");
      } else if (fieldName === "supplier_billing_address") {
        setFieldName("counter-party-address");
      } else if (fieldName === "supplier") {
        setFieldName("counter_party_company");
      }
    }
    const allDetails: any = AuditLogIdFieldDetails(fieldName);
    setDetails(allDetails);
    allDetails && getData(allDetails);
    // eslint-disable-next-line
  }, [model, fieldName, id]);

  const getData = async (allDetails: any) => {
    try {
      if (!allDetails) {
        setNoDataFound(true);
      } else {
        let model = allDetails.model;
        let itemData: any;
        if (allDetails.includes) {
          const item: any = await model
            .$query()
            .with([allDetails.includes])
            .find(id);
          itemData = item.$attributes;
        } else {
          const item: any = await model.$query().find(id);
          itemData = item.$attributes;
        }
        const filteredData = allDetails.needFormFields
          ? allDetails.needFormFields.reduce((acc: any, key: string) => {
              const value = getValue(key, itemData);
              if (value !== undefined) {
                acc[key] = value;
              }
              return acc;
            }, {})
          : itemData;
        setData(filteredData);
      }
    } catch (error) {
      setNoDataFound(true);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getValue = (key: string, itemData: any) => {
    const keys = key.split(".");
    // Traverse the nested structure to retrieve the value
    let value = itemData;
    for (const k of keys) {
      if (value && typeof value === "object" && k in value) {
        value = value[k];
      } else {
        value = undefined;
        break;
      }
    }
    return value;
  };

  if (noDataFound) {
    return <Alert variant="danger">No Data Found</Alert>;
  }

  if (loading) {
    return (
      <CustomSkeleton
        height={200}
        width={"100%"}
        center={true}
        customOuterStyle={{ padding: 0 }}
      />
    );
  }
  const formatKey = (key: any) => {
    // Remove underscores, replace with space, capitalize each word,
    // and remove text after dot
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char: any) => char.toUpperCase())
      .split(".")[0];
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <h5 className="m-0">{details && details.header} Details</h5>
        </Card.Header>
        <ListGroup variant="flush">
          <div className="row m-0 p-0">
            {Object.keys(data).map((key, index) => (
              <div key={key} className="col-4 m-0 p-0">
                <ListGroup.Item className="border-0">
                  <strong>{formatKey(key)}:</strong> {data[key] || "not found"}
                </ListGroup.Item>
              </div>
            ))}
          </div>
        </ListGroup>
      </Card>
    </div>
  );
}

export default CustomViewComponent;
