// Importing necessary modules and components.
import React from "react"; // Importing React library for building UI components.
import ReactDOM from "react-dom/client"; // Importing createRoot API for rendering.
import { BrowserRouter } from "react-router-dom"; // Importing BrowserRouter for implementing client-side routing.
import App from "./App"; // Importing the main App component.

// Creating a root using the new createRoot API.
const container = document.getElementById("root") as HTMLElement; // Ensure "root" matches your HTML ID.
const root = ReactDOM.createRoot(container);

// Rendering the application using the root object.
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
