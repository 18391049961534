import { Model } from "@tailflow/laravel-orion/lib/model";

export class RolePermission extends Model<{
  resources: Array<any>;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "roles";
  }
}
