import { Model } from "@tailflow/laravel-orion/lib/model";

export class ProjectManufacturesQuotations extends Model<{
  name: string;
  project_id: any;
  project_manufactures_id: any;
  status: any;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "project-manufactures-quotations";
  }
}
