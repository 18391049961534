import React, { useEffect, useState } from "react";
import { useApi } from "../../../Controller/ApiController";
import { setToast } from "../../../Util/Toast";
import AddUpdateForm from "../../../Components/AddUpdateForm";
import { CounterPartyTags } from "../../../Model/BuyersAndSuppliers/CounterPartyTags/CounterPartyTags";
import CustomSkeleton from "../../../Components/CustomSkeleton";

import {
  COUNTER_PARTY_TAG_LIST,
  COUNTER_PARTY_TAG_TITLE,
} from "../../../Routes/Setting/CounterPartyTag";
import AddUpdateCompanyTags from "../../Setting/CounterPartyTag/AddUpdate";
interface CompanyTagsProps {
  id: any;
  addNewBtnStatus: any;
}

const CompanyTags: React.FC<CompanyTagsProps> = ({ id, addNewBtnStatus }) => {
  const { apiGet, apiPatch } = useApi();
  const [loading, setLoading] = useState(true);
  const [companyTagsFormData, setCompanyTagsFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [companyTags, setCompanyTags] = useState(false);

  /// Company Tags
  const companyTagsFormFields = [
    {
      id: "inputresources",
      label: "Company Tags",
      type: "select",
      col: 12,
      name: "resources",
      placeholder: "Select an Tags",
      searchField: "name",
      model: CounterPartyTags,
      addNewBtnStatus: addNewBtnStatus,
      isMultiSelect: true,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Resources is required",
        },
      ],
      onChangeModalStatus: (status: boolean) => setCompanyTags(status),
      modalStatus: companyTags,
      widget: (
        <AddUpdateCompanyTags
          title={COUNTER_PARTY_TAG_TITLE}
          listRoute={COUNTER_PARTY_TAG_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) => setCompanyTags(status)}
        />
      ),
    },
  ];

  const handlecompanyTagsCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    let inputData = Object.assign({}, formData);

    let multiPermission = inputData.resources;
    // Extracting values after underscore and converting to numbers for each item in the list
    const extractedNumbers: number[] = multiPermission.map((item: any) => {
      // Extracting number after underscore and converting to integer
      return parseInt(item.value);
    });

    inputData.resources = extractedNumbers;

    try {
      await apiPatch(`counter-party-companies/${id}/tags/sync`, inputData);
      fetchData();
    } catch (error: any) {
      if (error.response?.data?.errors) {
        setFormError(error.response.data.errors);
      } else if (error.response?.data?.message) {
        setFormError(error.response.data.message);
      } else {
        setToast("error", "An error occurred while processing your request");
      }
    } finally {
      setCustomSubmitting(false);
    }
  };

  const getFormData = async () => {
    const item = await apiGet(`counter-party-companies/${id}/tags`);
    return item;
  };

  const fetchData = async () => {
    if (id) {
      try {
        setLoading(true);
        const itemData = await getFormData();
        let data = itemData;
        const formattedList = data["data"].map(
          (item: { name: any; id: any }) => ({
            label: item.name,
            value: item.id,
          }),
        );
        let tagData = {
          resources: formattedList,
        };
        setCompanyTagsFormData(tagData);
        setLoading(false);
      } catch (error: any) {
        setToast("error", error.response.data.message);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
      <CustomSkeleton
        height={99}
        customOuterStyle={{ padding: "0" }}
        customInnerStyle={{ borderRadius: "0 0 9px 9px" }}
        containerClassName={"d-grid"}
      />
    );
  }

  return (
    <div style={{ padding: "0 20px" }}>
      <AddUpdateForm
        formFields={companyTagsFormFields}
        model={CounterPartyTags}
        title="Tags"
        customTitle=" "
        customFormSubmit={handlecompanyTagsCustomSubmit}
        dynamicFormData={companyTagsFormData}
        isBackBtnDisable={true}
        loadingStatus={false}
        dynamicFormError={formError}
        dynamicId={3}
        customSubmitting={customSubmitting}
        companyTagsCustomStyle={{
          padding: "10px",
          marginTop: "16px",
          width: "15%",
        }}
      />
    </div>
  );
};

export default CompanyTags;
