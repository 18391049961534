import { Model } from "@tailflow/laravel-orion/lib/model";

export class ProductionProcessType extends Model<{
  name: string;
  organization_id: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}> {
  public $resource(): string {
    return "production-process-types";
  }
}
