import { TableColumn } from "react-data-table-component";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomEditButton from "../../Components/Button/CustomEditButton";
import { fetchData } from "../../Util/OrionList";
import { setToast } from "../../Util/Toast";
import CustomDataTable from "../../Components/CustomDataTable";
import {
  BUYERS_AND_SUPPLIERS_ARCHIVE,
  BUYERS_AND_SUPPLIERS_TITLE,
} from "../../Routes/BuyersAndSuppliers/BuyersAndSuppliers";
import { Badge, Col, Row } from "react-bootstrap";
import CustomFilter, { InputValues } from "../../Components/CustomFilter";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import CustomButton from "../../Components/Button/CustomButton";
import CustomArchiveModal from "../../Components/CustomArchiveModal";
import CustomRestoreIconButton from "../../Components/Button/CustomRestoreIconButton";
import CustomRestoreModal from "../../Components/CustomRestoreModal";
import CustomViewButton from "../../Components/Button/CustomViewButton";
import {
  PROJECTS_ADD,
  PROJECTS_ARCHIVE,
  PROJECTS_EDIT,
  PROJECTS_TITLE,
  PROJECTS_VIEW,
} from "../../Routes/Project/Project";
import { Projects } from "../../Model/Projects/Projects";
import { CapitalizeAndRemoveUnderscore } from "../../Util/CapitalizeAndRemoveUnderscore";

export interface ProjectItem {
  id: number;
  title: string;
  company_type: string;
  project_status: string;
  sales_owner_id: string;
}

function List({ permissions }: any) {
  const location = useLocation();
  const { date } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState<InputValues>({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showArchiveModal, setshowArchiveModal] = useState(false);
  const [ArchiveItemId, setArchiveItemId] = useState<number | null>(null);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [isArchive, setIsArchive] = useState<boolean>(
    location.pathname === PROJECTS_ARCHIVE,
  );
  const [showRestoreModal, setshowRestoreModal] = useState(false);
  const [restoreItemId, setRestoreItemId] = useState<number | null>(null);
  const [includeWith, setincludeWith] = useState<any>([
    "buyer",
    "productManufactures",
    "products",
    "productManufactures.supplier",
    "organization",
    "salesOwner",
  ]);
  const [isFromDateAvailable, setIsFromDateAvailable] = useState(false);

  const handleRowEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PROJECTS_EDIT.replace(":id", id.toString()));
  };

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    navigate(PROJECTS_VIEW.replace(":id", id.toString()));
  };

  const handleRowCreateButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    navigate(PROJECTS_ADD);
  };

  const handleRowRestoreButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ) => {
    e.preventDefault();
    setRestoreItemId(id);
    setshowRestoreModal(true);
  };

  const columns: TableColumn<ProjectItem>[] = [
    {
      name: "Id",
      cell: (row) => row.id,
    },
    {
      name: "Title",
      cell: (row) => row.title,
    },
    {
      name: "Status",
      minWidth: "20%",
      cell: (row) => (
        <Badge
          pill
          bg={
            row.project_status === "draft"
              ? "secondary"
              : row.project_status === "close"
              ? "danger"
              : "success"
          }
        >
          {CapitalizeAndRemoveUnderscore(row.project_status)}
        </Badge>
      ),
    },
    {
      name: "No of Manufacturers",
      cell: (row: any) => row.product_manufactures.length,
    },
    {
      name: "Sales Owner",
      cell: (row: any) =>
        row.sales_owner.first_name + " " + row.sales_owner.last_name,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
    {
      name: "Actions",
      button: true,
      omit: !permissions.restore && !permissions.update && !permissions.delete,
      cell: (row) => (
        <div>
          {isArchive ? (
            permissions.restore && (
              <CustomRestoreIconButton
                id={row.id}
                onClick={handleRowRestoreButtonClick}
              />
            )
          ) : (
            <>
              {permissions.update && (
                <CustomEditButton
                  id={row.id}
                  onClick={handleRowEditButtonClick}
                />
              )}
              <CustomViewButton
                id={row.id}
                onClick={(e) => {
                  return handleRowViewButtonClick(e, row.id);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);

  // @ts-ignore
  const fetchProject = useCallback(async () => {
    try {
      setLoading(true);
      const params = date ? new URLSearchParams(date) : null;
      const fromDate = params?.get("fromDate");
      if (fromDate) {
        setIsFromDateAvailable(true);
      } else {
        setIsFromDateAvailable(false);
      }
      const filters = {
        ...(fromDate
          ? { created_at: { filter_operation: ">=", value: fromDate } }
          : {}),
        ...(inputValues ? inputValues : {}),
      };
      await fetchData(
        page,
        limit,
        filters,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new Projects(),
        sortField,
        sortOrder,
        isArchive,
        includeWith,
      );
    } catch (error: any) {
      setToast("error", error.response.data.message);
    } finally {
      setLoading(false);
    }
  }, [
    date,
    page,
    limit,
    inputValues,
    sortField,
    sortOrder,
    isArchive,
    includeWith,
    navigate,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]);

  useEffect(() => {
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProject]);

  useEffect(() => {
    setIsArchive(location.pathname === BUYERS_AND_SUPPLIERS_ARCHIVE);
  }, [location]);

  const filterOptions: any = [
    {
      id: "id",
      value: "id",
      label: "Id",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "title",
      value: "title",
      label: "Title",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "project_status",
      value: "project_status",
      label: "Status",
      fieldType: "select",
      fieldOption: {
        All: "",
        Draft: "draft",
        Close: "close",
        "Ready To Send For Estimate": "ready_to_send_for_estimate",
      },
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    setincludeWith([
      "buyer",
      "productManufactures",
      "products",
      "productManufactures.supplier",
      "organization",
      "salesOwner",
    ]);
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };
  const handlePageChange = (page: any) => {
    setPage(page);
  };
  useEffect(() => {
    fetchProject();
  }, [isArchive, limit, page]); // eslint-disable-line
  const handleGotoDashboard = () => {
    navigate("/");
  };
  return (
    <>
      <Row className="align-items-center">
        <Col className="col-10">
          <div className="d-flex justify-content-start">
            <div style={{ display: "inline-block" }}>
              <h6 className="mt-1">
                {PROJECTS_TITLE} {isArchive ? "Archive" : "List"}
              </h6>
            </div>
            <CustomFilter
              options={filterOptions}
              inputValues={inputValues}
              setInputValues={setInputValues}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              getData={fetchProject}
              setPage={setPage}
            />
          </div>
        </Col>
        <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
          {permissions.add && (
            <CustomButton
              label={`Add Project`}
              variant="success"
              onClick={handleRowCreateButtonClick}
            />
          )}
          {isFromDateAvailable && (
            <button
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={handleGotoDashboard}
            >
              Back
            </button>
          )}
        </Col>
      </Row>
      <div className="pb-4">
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={handleRowCreateButtonClick}
          currentPages={page}
          currentRowsPerPage={limit}
          module={"Projects"} // Pass optional fields to CustomDataTable
        />
      </div>
      <CustomArchiveModal
        title={BUYERS_AND_SUPPLIERS_TITLE}
        model={Projects}
        getData={fetchProject}
        ArchiveItemId={ArchiveItemId}
        setArchiveItemId={setArchiveItemId}
        showArchiveModal={showArchiveModal}
        setshowArchiveModal={setshowArchiveModal}
      />
      <CustomRestoreModal
        title={BUYERS_AND_SUPPLIERS_TITLE}
        model={Projects}
        getData={fetchProject}
        RestoreItemId={restoreItemId}
        showRestoreModal={showRestoreModal}
        setRestoreItemId={setRestoreItemId}
        setshowRestoreModal={setshowRestoreModal}
      />
    </>
  );
}

export default List;
