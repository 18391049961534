import { Model } from "@tailflow/laravel-orion/lib/model";

export class PublicCountry extends Model<{
  name: string;
  code: string;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "public/projects/country/:token";
  }
}
