import React, { useEffect, useState } from "react";
import CustomAsyncSelect from "./AsyncSelect";
import Select, { components } from "react-select";
import { Card, Dropdown, Form } from "react-bootstrap";
import { Download, PlusCircleFill } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DND from "./DND";
import { useDateFormat } from "../Util/InputDateFormate";
import { DateString } from "../Util/DateFormat";
import moment from "moment";
import { setToast } from "../Util/Toast";

interface FormTableFieldsPros {
  type: string;
  label: string;
  headerLabel: string;
  fieldShow?: boolean;
  modal?: any;
  customFilter?: any;
  options?: any;
  fieldName?: string;
  inputField?: string;
  isMenuPortalTarget?: boolean;
  isMultiSelect?: boolean;
  isNeedFullData?: boolean;
  fieldValue?: (item: any) => void;
  datePicker?: boolean;
  readMode?: boolean;
  onChangeItemValue?: (item: any, selectedValue: any, position?: any) => void;
  addNewBtnStatus?: boolean;
  widget?: any;
  onChangeModalStatus?: (status: boolean) => void;
  modalStatus?: boolean;
  disabled?: boolean;
}

interface CustomAsyncSelectProps {
  FormTableFields: FormTableFieldsPros[];
  modifieldFormTableFields?: FormTableFieldsPros[];
  initializedProduct: any;
  setCardsData: any;
  cardsData: any[];
  formErrors: any;
  setFormErrors: any;
  updateStatus?: boolean;
  hideShowField?: any;
  setHideShowField?: any;
  cardShowStatus: boolean;
  cardName: string;
  addButtonStatus?: boolean;
  removeButtonStatus?: boolean;
  insufficientErrorShow?: boolean;
}

type ItemType = {
  id: number;
  content: React.ReactNode;
};

const CreateRow: React.FC<CustomAsyncSelectProps> = ({
  FormTableFields,
  modifieldFormTableFields,
  setCardsData,
  cardsData,
  formErrors,
  updateStatus,
  initializedProduct,
  hideShowField,
  setHideShowField,
  cardShowStatus,
  cardName,
  setFormErrors,
  addButtonStatus,
  removeButtonStatus,
  insufficientErrorShow,
}) => {
  const [dndItems, setDNDItems] = useState<ItemType[]>([]);
  const [updatedFormTableFields, setUpdatedFormTableFields] =
    useState<any>(FormTableFields);
  const [cardError, setCardError] = useState(formErrors);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [customKey, setCustomKey] = useState(0);
  const dateFormat = useDateFormat();
  const Capitalization = (words: string) => {
    return words
      ?.split("_")
      ?.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  };
  const customThemeForProductdetails = (theme: any) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 20,
      baseUnit: 1,
    },
  });
  const handleOptionChanges = (index: number, field: string, option: any) => {
    handleProductChange(index, field, option?.data || option.value);
  };

  const handleProductChange = (index: any, field: any, value: any) => {
    setCardsData((prevState: any) =>
      prevState.map((product: any, i: any) => {
        if (product.position === index) {
          const updatedProduct = {
            ...product,
            [field]: value,
          };

          if (field === "quantity" || field === "batch") {
            const currentStock = updatedProduct.batch?.current_stock || 0;
            const quantity = Number(updatedProduct.quantity) || 0;
            if (
              insufficientErrorShow &&
              (currentStock < quantity || currentStock < 0)
            ) {
              setFormErrors((prevErrors: any) => ({
                ...prevErrors,
                [index]: {
                  ...prevErrors[index],
                  quantity: `Insufficient Current Stock. (${currentStock})`,
                },
              }));
            } else {
              setFormErrors((prevErrors: any) => ({
                ...prevErrors,
                [index]: {
                  ...prevErrors[index],
                  quantity: "",
                },
              }));
            }
          }

          return updatedProduct;
        }
        return product;
      }),
    );
  };
  const Option = (props: any) => (
    <div>
      <components.Option {...props}>
        <div>
          <p>{props.data?.label}</p>
        </div>
      </components.Option>
    </div>
  );
  const renderTableFields = (item: any, itemIindex: any) => {
    try {
      if (item.products_id) {
        item.batchFilter = [
          {
            field: "products_id",
            value: item.products_id,
          },
        ];
      }

      return updatedFormTableFields.map((field: any) => {
        if (field?.type === "CustomAsyncSelect") {
          field.customUpdate = true;
        }

        return (
          <>
            {field?.type === "CustomAsyncSelect" &&
            (field.fieldShow ?? true) ? (
              <td
                style={{
                  minWidth: "200px",
                  maxWidth: "250px",
                  fontSize: "12px",
                }}
              >
                <CustomAsyncSelect
                  componentKey={
                    field.label === "batch"
                      ? item.customKey
                      : field?.modalStatus
                      ? 0
                      : 1
                  }
                  customFilter={
                    field.label === "batch" && item.batchFilter
                      ? item.batchFilter
                      : field?.customFilter
                  }
                  customTheme={customThemeForProductdetails}
                  isMenuPortalTarget={field?.isMenuPortalTarget}
                  model={field?.modal}
                  inputfield={field?.inputField}
                  fieldName={field?.inputName}
                  isMultiSelect={field?.isMultiSelect}
                  formData={item}
                  label={field?.label}
                  isNeedFullData={field?.isNeedFullData}
                  onChangeModalStatus={(status: boolean) => {
                    field?.onChangeModalStatus(status);
                  }}
                  isdisabled={field?.disabled}
                  modalStatus={field?.modalStatus}
                  customValue={
                    (updateStatus || field?.customUpdate) && {
                      label:
                        item &&
                        item[field.label] &&
                        item[field.label][field.inputField],
                      value: item && item[field.label] && item[field.label]?.id,
                    }
                  }
                  addNewBtnStatus={field?.addNewBtnStatus ?? true}
                  widget={field?.widget}
                  onChange={(selectedOption: any, e: any) => {
                    if (field.label === "product") {
                      if (itemIindex === item.position) {
                        setCustomKey((prevKey) => prevKey + 1);
                        item.customKey = customKey;
                      } else {
                        delete item.customKey;
                      }
                      item.batch = null;
                      item.batches_id = null;
                    }
                    handleOptionChanges(
                      item.position,
                      field.label,
                      selectedOption,
                    );
                    handleProductChange(
                      item.position,
                      field.submitFieldName,
                      selectedOption?.value,
                    );
                    if (
                      field?.onChangeItemValue &&
                      typeof field.onChangeItemValue === "function"
                    ) {
                      field.onChangeItemValue(
                        item,
                        selectedOption,
                        item.position,
                      );
                    }
                  }}
                />
                {cardError[item.position]?.[`${field.submitFieldName}`] && (
                  <p className="text-danger">
                    {cardError[item.position]?.[`${field.submitFieldName}`]}
                  </p>
                )}
              </td>
            ) : field.type === "simpleSelect" && (field.fieldShow ?? true) ? (
              <td style={{ minWidth: "200px", fontSize: "12px" }}>
                <Select
                  options={field.options}
                  isSearchable
                  menuPosition={"fixed"}
                  placeholder="Select options..."
                  components={{ Option }}
                  theme={customThemeForProductdetails}
                  onChange={(selectedOption: any) => {
                    handleOptionChanges(
                      item.position,
                      field.label,
                      selectedOption,
                    );
                    field.onChangeItemValue(item, selectedOption);
                  }}
                  value={{
                    label: item && Capitalization(item[`${field.fieldName}`]),
                    value: item && item[`${field.fieldName}`],
                  }}
                  defaultValue={field.options && field.options[0]}
                />
                {cardError[item.position]?.[`${field.submitFieldName}`] && (
                  <p className="text-danger">
                    {cardError[item.position]?.[`${field.submitFieldName}`]}
                  </p>
                )}
              </td>
            ) : field.type === "readOnly" && (field.fieldShow ?? true) ? (
              <td style={{ minWidth: "200px", maxWidth: "100px" }}>
                <Form.Control
                  type={field.type}
                  className="form-control-bottom-border p-0 row-form-control"
                  value={field.fieldValue(item) ?? ""}
                  readOnly
                />
                {cardError[item.position]?.[`${field.submitFieldName}`] && (
                  <p className="text-danger">
                    {cardError[item.position]?.[`${field.submitFieldName}`]}
                  </p>
                )}
              </td>
            ) : (field.fieldShow ?? true) &&
              field.type === "datePicker" &&
              field.datePicker === true &&
              dateFormat ? (
              <td style={{ minWidth: "200px", maxWidth: "250px" }}>
                <DatePicker
                  selected={
                    item[field?.fieldName] &&
                    DateString(item[field?.fieldName], dateFormat.split(" ")[0])
                  }
                  showYearDropdown
                  placeholderText={dateFormat.replace("dd", "DD")}
                  minDate={field?.previousDate ?? ""}
                  onChange={(selectedDate) => {
                    handleProductChange(
                      item.position,
                      field.fieldName,
                      selectedDate,
                    );
                  }}
                  dateFormat={dateFormat?.replace("YYYY", "yyyy").split(" ")[0]}
                  className="form-control-bottom-border w-100 p-0 row-form-control"
                  onBlur={(e: any) => {
                    let enteredDate = e.target.value;
                    const dateFormatWithoutTime = dateFormat
                      .replace("dd", "DD")
                      .split(" ")[0];
                    enteredDate = enteredDate.replace(/\b(\d{1})\b/g, "0$1");
                    const isValid = moment(
                      enteredDate,
                      dateFormatWithoutTime,
                    ).isValid();
                    if (isValid) {
                      setFormErrors((prevErrors: any) => ({
                        ...prevErrors,
                        [item.position]: {
                          ...prevErrors[item.position],
                          [field.fieldName]: "",
                        },
                      }));
                    } else {
                      if (enteredDate.length > 0) {
                        handleProductChange(
                          item.position,
                          field.fieldName,
                          null,
                        );

                        setFormErrors((prevErrors: any) => ({
                          ...prevErrors,
                          [item.position]: {
                            ...prevErrors[item.position],
                            [field.fieldName]: `Please select a date in the correct format (${dateFormatWithoutTime}).`,
                          },
                        }));
                      } else {
                        setFormErrors((prevErrors: any) => ({
                          ...prevErrors,
                          [item.position]: {
                            ...prevErrors[item.position],
                            [field.fieldName]: "",
                          },
                        }));
                      }
                    }
                  }}
                />
                {cardError[item.position]?.[`${field.fieldName}`] && (
                  <p className="text-danger">
                    {cardError[item.position]?.[`${field.fieldName}`]}
                  </p>
                )}
              </td>
            ) : field.type === "image" && (field.fieldShow ?? true) ? (
              <td style={{ minWidth: "200px", fontSize: "12px" }}>
                <input
                  className="form-control"
                  type="file"
                  name={field.fieldName}
                  placeholder={field.placeholder}
                  onChange={(event: any) =>
                    handleProductChange(
                      item.position,
                      field.submitFieldName,
                      event.target.files[0],
                    )
                  }
                  disabled={field.disabled || false}
                  accept="image/*"
                />
                {item[`${field?.fieldName}`] && (
                  <span className="text-success">
                    Selected File: {item[`${field?.fieldName}`]}
                  </span>
                )}
                {cardError[item.position]?.[`${field.submitFieldName}`] && (
                  <p className="text-danger">
                    {cardError[item.position]?.[`${field.submitFieldName}`]}
                  </p>
                )}
              </td>
            ) : field.type === "file" && (field.fieldShow ?? true) ? (
              <td
                style={{
                  minWidth: "200px",
                  maxWidth: "250px",
                  fontSize: "12px",
                  paddingTop: "2px",
                }}
              >
                <span className="d-flex">
                  <input
                    className="form-control row-form-control border-0 border-bottom rounded-0"
                    type="file"
                    name={field.fieldName}
                    onChange={(event: any) =>
                      handleProductChange(
                        item.position,
                        field.submitFieldName,
                        event.target.files[0],
                      )
                    }
                    disabled={field.disabled || false}
                  />
                  <span
                    className="d-flex align-items-center px-2"
                    style={{ width: "25px" }}
                  >
                    {item[field.fieldName] && (
                      <a
                        href={item[field.fieldName]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Download style={{ fill: "green", fontSize: "15px" }} />
                      </a>
                    )}
                  </span>
                </span>
                {cardError[item.position]?.[`${field.submitFieldName}`] && (
                  <p className="text-danger text-justify">
                    {cardError[item.position]?.[`${field.submitFieldName}`]}
                  </p>
                )}
              </td>
            ) : (
              (field.fieldShow ?? true) && (
                <td style={{ minWidth: "200px", maxWidth: "100px" }}>
                  <Form.Control
                    type={field.type}
                    className="form-control-bottom-border p-0 row-form-control"
                    onChange={(e) => {
                      handleProductChange(
                        item.position,
                        field.fieldName,
                        e.target.value,
                      );
                    }}
                    value={
                      item[`${field.fieldName}`] ??
                      (typeof field.fieldValue === "function"
                        ? field.fieldValue(item)
                        : "") ??
                      ""
                    }
                    readOnly={field.readMode || field.disabled ? true : false}
                    required
                    onFocus={(e) => {
                      if (field.type === "number") {
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false },
                        );
                      }
                    }}
                  />
                  {cardError[item.position]?.[`${field.submitFieldName}`] && (
                    <p className="text-danger">
                      {cardError[item.position]?.[`${field.submitFieldName}`]}
                    </p>
                  )}
                </td>
              )
            )}
          </>
        );
      });
    } catch (error) {
      setToast("error", "An error occurred while processing your request");
    } finally {
      setLoadingStatus(false);
    }
  };

  const updateDNDItems = () => {
    if (
      dndItems &&
      cardsData.every((item: any) =>
        dndItems.some((dndItem) => dndItem.id === item.position),
      )
    ) {
      const updatedDNDItems: any = cardsData
        .map((item, index) => {
          const existingDNDItem = dndItems.find(
            (dndItem) => dndItem.id === item.position,
          );
          return existingDNDItem
            ? {
                id: item?.position,
                content: renderTableFields(item, index),
              }
            : null;
        })
        .filter(Boolean);
      setDNDItems(updatedDNDItems);
    } else if (
      cardsData &&
      cardsData.every(
        (item) =>
          !dndItems.some(
            (dndItem: { id: any }) => dndItem.id === item.position,
          ),
      )
    ) {
      const newDNDItems: any = cardsData.map((item: any, index: any) => ({
        id: item.position,
        content: renderTableFields(item, index),
      }));
      setDNDItems(newDNDItems);
    } else if (cardsData && dndItems) {
      cardsData.forEach((product: any, index: number) => {
        if (!dndItems.some((item: any) => item.id === product.position)) {
          setDNDItems((prevState: any) => [
            ...prevState,
            {
              id: product.position,
              content: renderTableFields(product, index),
            },
          ]);
        }
      });
    }
  };

  useEffect(() => {
    setCardError(formErrors);
  }, [formErrors]);

  useEffect(() => {
    if (modifieldFormTableFields && modifieldFormTableFields.length > 0) {
      setUpdatedFormTableFields(modifieldFormTableFields);
      updateDNDItems();
    } else {
      setUpdatedFormTableFields(FormTableFields);
      updateDNDItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardsData,
    cardError,
    hideShowField,
    updatedFormTableFields,
    FormTableFields,
    modifieldFormTableFields,
  ]);

  const onDragEnd = (result: any) => {
    setCustomKey((prevKey) => prevKey + 1);

    if (!result.destination) {
      return;
    }
    const newDNDItems = Array.from(dndItems);
    const [removed] = newDNDItems.splice(result.source.index, 1);
    newDNDItems.splice(result.destination.index, 0, removed);

    const newProductItems = Array.from(cardsData);
    const [productRemoved] = newProductItems.splice(result.source.index, 1);
    newProductItems.splice(result.destination.index, 0, productRemoved);

    // Update the positions in both newDNDItems and newProductItems
    newDNDItems.forEach((item, index) => {
      item.id = index;
      newProductItems[index].position = index;
      newProductItems[index].customKey = customKey;
    });

    setDNDItems(newDNDItems);
    setCardsData(newProductItems);
    errorPostion(result.source.index, result.destination.index);
  };
  const onRemoveItem = (index: number) => {
    if (removeButtonStatus ?? true) {
      setCardsData((prevProduct: any) => {
        return prevProduct.filter((item: any) => item.position !== index);
      });
    }
  };

  const errorPostion = (source: number, destination: number) => {
    const updatedDndData = { ...cardError };
    if (updatedDndData[source] && updatedDndData[destination]) {
      const temp = { ...updatedDndData[source] };
      updatedDndData[source] = { ...updatedDndData[destination] };
      updatedDndData[destination] = { ...temp };
      setCardError(updatedDndData);
      setFormErrors(updatedDndData);
    } else if (!updatedDndData[source] && updatedDndData[destination]) {
      const sourceValue = updatedDndData[destination];
      updatedDndData[source] = sourceValue;
      delete updatedDndData[destination];
      setFormErrors(updatedDndData);
      setCardError(updatedDndData);
    } else if (updatedDndData[source] && !updatedDndData[destination]) {
      const sourceValue = updatedDndData[source];
      updatedDndData[destination] = sourceValue;
      delete updatedDndData[source];
      setCardError(updatedDndData);
      setFormErrors(updatedDndData);
    }
  };

  const addItem = () => {
    setDNDItems((prevState) =>
      prevState.map((item, idx) => ({
        ...item,
        id: idx, // Set the id based on the index
      })),
    );
    setCardsData((prevState: any) => {
      const productData = [
        ...prevState,
        {
          ...initializedProduct,
          position: prevState.length,
        },
      ];
      return productData.map((item, idx) => ({
        ...item,
        position: idx, // Update positions starting from 1
      }));
    });
  };
  const toggleStatusById = (id: number) => {
    setHideShowField((prevStatus: any) =>
      prevStatus.map((item: any) =>
        item.id === id ? { ...item, fieldStatus: !item.fieldStatus } : item,
      ),
    );
  };

  return (
    <>
      <Card
        className="mb-4 mt-2"
        style={{
          display: cardShowStatus ? "block" : "none",
          fontSize: "small",
        }}
      >
        <span>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <span style={{ fontSize: "15px", fontWeight: "500" }}>
              {cardName}
            </span>
            {hideShowField && (
              <span className="d-flex justify-content-end">
                <Dropdown>
                  <Dropdown.Toggle
                    className="rounded-pill ms-3"
                    style={{ fontSize: "10px" }}
                    size={"sm"}
                    variant={
                      hideShowField.some((item: any) => item.fieldStatus)
                        ? "success"
                        : "secondary"
                    }
                    id="dropdown-basic"
                  >
                    Optional Columns
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {hideShowField?.map((item: any, index: number) => {
                      return (
                        <Dropdown.Item
                          key={index + item.id}
                          className={
                            item.fieldStatus
                              ? "bg-success text-light"
                              : "bg-light text-dark"
                          }
                          onClick={() => {
                            toggleStatusById(item.id);
                          }}
                        >
                          {Capitalization(item.label)}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            )}
          </Card.Header>
          <Card.Body
            className="p-0"
            style={{ overflowX: "scroll", overflowY: "hidden" }}
          >
            <DND
              items={dndItems}
              onDragEnd={onDragEnd}
              removeItem={onRemoveItem}
              removeButtonStatus={removeButtonStatus ?? true}
              loadingStatus={loadingStatus}
            >
              <th></th>
              {updatedFormTableFields &&
                updatedFormTableFields?.map((field: any, index: number) => {
                  return (
                    <React.Fragment key={index + "header"}>
                      {(field.fieldShow ?? true) && (
                        <th>{field.headerLabel}</th>
                      )}
                    </React.Fragment>
                  );
                })}
              <th></th>
            </DND>
            {((addButtonStatus && !loadingStatus) ?? true) && (
              <div className="d-flex" style={{ marginBottom: "-10px" }}>
                <div>
                  <footer style={{ cursor: "pointer", color: "blue" }}>
                    <span onClick={addItem} style={{ width: "maxContent" }}>
                      <p className="px-2">
                        Add Item <PlusCircleFill />
                      </p>
                    </span>
                  </footer>
                </div>
              </div>
            )}
          </Card.Body>
        </span>
      </Card>
    </>
  );
};

export default CreateRow;
