import React, { useEffect, useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { AuditLogs } from "../Model/Setting/AuditLogs";
import { formatDistanceToNowStrict } from "date-fns";
import { setToast } from "../Util/Toast";
import { CustomView } from "../Routes/Setting/CustomViewComponentRoute";
import CustomSkeleton from "./CustomSkeleton";
import { CapitalizeAndRemoveUnderscore } from "../Util/CapitalizeAndRemoveUnderscore";

function ActivityLogs({ auditable_type, id }: any) {
  const [logsByDate, setLogsByDate] = useState<{ [date: string]: any[] }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Track if there are more records available
  const [loading, setLoading] = useState(true); // Track if there are more records available

  const [showMoreStates, setShowMoreStates] = useState<{
    [date: string]: boolean;
  }>({});

  useEffect(() => {
    if (id) {
      getData(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, id]);

  const getData = async (page: number) => {
    try {
      const searchData: any = {
        auditable_type: auditable_type,
        auditable_id: id,
        page: page,
        limit: 10,
      };
      const record: any = await AuditLogs.$query().store(searchData);
      const logs = record.$attributes.data;
      const lastPage = record.$attributes.last_page;

      groupLogsByDate(logs);
      // If the fetched logs length is less than the limit, disable "Show More" button
      if (lastPage === currentPage) {
        setHasMore(false);
      }
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const groupLogsByDate = (newLogs: any[]) => {
    setLogsByDate((prevLogs) => {
      const updatedLogs = { ...prevLogs };
      newLogs.forEach((log) => {
        const date = new Date(log.created_at).toDateString();
        if (!updatedLogs[date]) {
          updatedLogs[date] = [];
        }
        if (log.event === "created" || log.event === "updated") {
          if (Object.keys(JSON.parse(log.new_values)).length > 0) {
            const newLog = {
              user: log.user.first_name + " " + log.user.last_name,
              log,
            };
            if (!updatedLogs[date].some((entry) => entry.log.id === log.id)) {
              updatedLogs[date].push(newLog);
            }
          }
        } else {
          if (!updatedLogs[date].some((entry) => entry.log.id === log.id)) {
            updatedLogs[date].push({
              user: log.user.first_name + " " + log.user.last_name,
              log,
            });
          }
        }
      });
      setLoading(false);
      return updatedLogs;
    });
  };

  const getRelativeTime = (dateString: string) => {
    const date = new Date(dateString);
    return formatDistanceToNowStrict(date, { addSuffix: true });
  };

  const navigateToEditPage = (key: any, value: any) => {
    const url = `${CustomView.replace(":model", auditable_type.toLowerCase())
      .replace(":field", key.replace("_id", ""))
      .replace(":id", value?.toString())}`;
    window.open(url, "_blank");
  };

  const renderTable = (
    values: any,
    variant: any,
    title?: any,
    date?: any,
    type?: any,
  ) => {
    const showMore =
      type === "old"
        ? showMoreStates[`${date}-old`] || false
        : showMoreStates[`${date}-new`] || false;
    const shouldShowMoreButton = Object.keys(values).length > 3;

    const handleShowMore = () => {
      if (type === "old") {
        setShowMoreStates((prevState) => ({
          ...prevState,
          [`${date}-old`]: true,
        }));
      } else {
        setShowMoreStates((prevState) => ({
          ...prevState,
          [`${date}-new`]: true,
        }));
      }
    };

    return (
      <Alert variant={variant} style={{ wordBreak: "break-word" }}>
        {title && (
          <th className="d-flex justify-content-center p-2">{title}</th>
        )}
        <table className="w-100">
          <tbody>
            {Object.entries(values)
              .map(([key, value]: any, index) => (
                <tr key={key}>
                  <th
                    className="p-1 w-50"
                    style={{ border: "1px solid black" }}
                  >
                    {CapitalizeAndRemoveUnderscore(key)}
                  </th>
                  <td
                    className="p-1 w-50"
                    style={{ border: "1px solid black" }}
                  >
                    {key.includes("_id") &&
                    typeof value === "number" &&
                    !(
                      (auditable_type === "MATERIALS" &&
                        key === "material_id") ||
                      (auditable_type === "PRODUCTS" && key === "products_id")
                    ) ? (
                      <span
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={() => navigateToEditPage(key, value)}
                      >
                        view
                      </span>
                    ) : typeof value === "object" ? (
                      JSON.stringify(value)
                    ) : (
                      `${value}`
                    )}
                  </td>
                </tr>
              ))
              .slice(0, showMore || !shouldShowMoreButton ? undefined : 3)}
          </tbody>
        </table>
        {shouldShowMoreButton && !showMore && (
          <p
            className="text-primary me-3"
            style={{ cursor: "pointer", textAlign: "end", margin: "5px 0 0" }}
            onClick={() => handleShowMore()}
          >
            more
          </p>
        )}
      </Alert>
    );
  };

  const getLogMessage = (log: any, index: number) => {
    const userName = log.user;
    const action = log.log.event;
    const newValues = JSON.parse(log.log.new_values);

    const oldValues = JSON.parse(log.log.old_values);
    const renderDetails = () => {
      switch (action) {
        case "created":
          return renderTable(
            newValues,
            "success",
            "New Values",
            log.log.created_at,
          );
        case "updated":
          return (
            <>
              <div className="d-flex justify-content-between w-100">
                <span style={{ width: "49%" }}>
                  {renderTable(
                    oldValues,
                    "danger",
                    "Old Values",
                    log.log.created_at,
                    "old",
                  )}
                </span>
                <span style={{ width: "49%" }}>
                  {renderTable(
                    newValues,
                    "success",
                    "New Values",
                    log.log.created_at,
                    "new",
                  )}
                </span>
              </div>
            </>
          );
        case "deleted":
        case "restored":
          return <span>the item</span>;
        default:
          return null;
      }
    };

    return (
      <div key={index}>
        {(action === "created" || action === "updated") && (
          <>
            <p className="d-flex">
              <span style={{ fontWeight: "bold" }} className="me-1">
                {userName}
              </span>
              <span style={{ color: "blue" }} className="me-1">
                {action}
              </span>
            </p>
            {renderDetails()}
            <p style={{ textAlign: "end", color: "#8c8c8c" }}>
              {getRelativeTime(log.log.created_at)}
            </p>{" "}
            {/* Display relative time */}
          </>
        )}
        {(action === "deleted" || action === "restored") && (
          <>
            <p className="d-flex">
              <span style={{ fontWeight: "bold" }} className="me-1">
                {userName}
              </span>
              <span style={{ color: "blue" }} className="me-1">
                {action}
              </span>
              {renderDetails()}
              <span className="ms-auto" style={{ color: "#8c8c8c" }}>
                {getRelativeTime(log.log.created_at)}
              </span>{" "}
              {/* Display relative time */}
            </p>
          </>
        )}
      </div>
    );
  };
  if (!loading && Object.keys(logsByDate).length === 0) {
    return (
      <div style={{ textAlign: "center", color: "gray", marginTop: "20px" }}>
        No activity logs available
      </div>
    );
  }

  if (loading) {
    return (
      <>
        <CustomSkeleton
          height={20}
          width={"20%"}
          customOuterStyle={{ padding: "0", paddingBottom: "20px" }}
          baseColor={"white"}
          highlightColor={"#f2f2f2"}
        />
        <CustomSkeleton
          height={100}
          width={"100%"}
          center={true}
          customOuterStyle={{ padding: "0" }}
          baseColor={"white"}
          highlightColor={"#f2f2f2"}
        />
      </>
    );
  }

  return (
    <div style={{ width: "100%", marginTop: "30px" }}>
      {Object.entries(logsByDate).map(([date, logs]: any) => (
        <div key={date}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <span
              className="mt-1"
              style={{ flex: 1, borderBottom: "1px solid #B4B5C0" }}
            ></span>
            <span
              style={{ padding: "0 10px", fontWeight: "500", color: "#B4B5C0" }}
            >
              {date}
            </span>
            <span
              className="mt-1"
              style={{ flex: 1, borderBottom: "1px solid #B4B5C0" }}
            ></span>
          </div>
          {logs.map((log: any, index: number) => (
            <React.Fragment key={index}>
              <Card
                className="mb-3"
                style={{ border: "0", background: "transparent" }}
              >
                <Card.Body
                  style={{
                    height:
                      log.log.event === "restored" ||
                      log.log.event === "deleted"
                        ? "20px"
                        : "auto",
                    padding: "0",
                  }}
                >
                  <Card.Text>{getLogMessage(log, index)}</Card.Text>
                </Card.Body>
              </Card>
              {index !== logs.length - 1 && <hr className="" />}
            </React.Fragment>
          ))}
        </div>
      ))}
      <div
        style={{ display: hasMore ? "flex" : "none", justifyContent: "end" }}
      >
        <p
          className="text-primary me-3"
          style={{ cursor: "pointer" }}
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
        >
          Show More...
        </p>
      </div>
    </div>
  );
}

export default ActivityLogs;
