import { Model } from "@tailflow/laravel-orion/lib/model";

export class StockInventory extends Model<{
  organization_id: number;
  products_id: number;
  batches_id: number;
  document_number: string;
  stock_action: string;
  change_quantity_by_product: string;
  new_quantity_by_product: number;
  old_quantity_by_batch: number;
  change_quantity_by_batch: string;
  new_quantity_by_batch: number;
  old_quantity_by_product: number;
  unit_of_measurement_id: number;
  document_id: number;
  document_type: string;
  document_number_format_type: string;
  comment: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}> {
  public $resource(): string {
    return "stock-inventories";
  }
}
