import { Model } from "@tailflow/laravel-orion/lib/model";

export class AttributesOption extends Model<{
  id: string;
  organization_id: string;
  name: any;
  sort_order: boolean;
  attribute_id: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}> {
  public $resource(): string {
    return "attribute-options";
  }
}
