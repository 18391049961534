import { Model } from "@tailflow/laravel-orion/lib/model";

export class Tax extends Model<{
  tax_name: string;
  rate: number;
  tax_type_id: any;
  country_id: any;
  created_at: string;
  updated_at: string;
  country?: any;
  tax_type?: any;
}> {
  public $resource(): string {
    return "taxes";
  }
}
