import { Model } from "@tailflow/laravel-orion/lib/model";

export class UserGroup extends Model<{
  title: string;
  description: string;
  organization_id?: any;
  created_at: string;
  updated_at: string;
}> {
  public $resource(): string {
    return "user-groups";
  }
}
