import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Organization } from "../../Model/Setting/Organization";
import { setToast } from "../../Util/Toast";
import AddUpdateForm from "../../Components/AddUpdateForm";
import {
  ORGANIZATION_TITLE,
  ORGANIZATION_VIEW,
} from "../../Routes/Organization/Organization";
import FieldDescription from "../../Components/FieldDescription";
import { Country } from "../../Model/Setting/Country";
import { useApi } from "../../Controller/ApiController";
import { Currency } from "../../Model/Setting/Currency";
import { getUser, setUser } from "../../Util/Auth";
import ActivityLogs from "../../Components/ActivityLogs";
import Alerts from "../../Components/Alerts";
import { CURRENCY_LIST, CURRENCY_TITLE } from "../../Routes/Setting/Currency";
import AddUpdateCurrency from "../Setting/Currency/AddUpdate";

const AddUpdate: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [formError, setFormError] = useState<string | null>(null);
  const [isShows, setIsShows] = useState<boolean>(false);
  const [timeZoneData, setTimeZoneData] = useState<any>();
  const [dateFormat, setDateFormat] = useState<any>();
  const [warning, setWarning] = useState<any>([]);
  const { apiGet, apiPut } = useApi();
  const user = getUser();
  const [customSubmitting, setCustomSubmitting] = useState(false); // State variable to track form submission status
  const [currencyModalStatus, setCurrencyModalStatus] =
    useState<boolean>(false); // State variable to track form submission status

  const formFields = [
    {
      id: "inputOrganizationName",
      label: "Organization Name*",
      type: "text",
      col: 6,
      name: "company_name",
      placeholder: "Enter Organization Name",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Organization Name is required",
        },
      ],
      description: "Name of your organization.",
    },
    {
      id: "inputCurrencyId",
      label: "Currency*",
      type: "select",
      col: 6,
      name: "currency_id",
      placeholder: "Select a Currency",
      searchField: "code",
      model: Currency,
      customFilter: [
        {
          field: "active",
          value: 1,
        },
      ],
      addNewBtnStatus: true,
      onChangeModalStatus: (status: boolean) => setCurrencyModalStatus(status),
      modalStatus: currencyModalStatus,
      widget: (
        <AddUpdateCurrency
          title={CURRENCY_TITLE}
          listRoute={CURRENCY_LIST}
          isNotUpdate={true}
          onChangeModalStatus={(status: boolean) =>
            setCurrencyModalStatus(status)
          }
        />
      ),
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Monetary unit used in transactions.",
    },
    {
      id: "inputcurrency_decimal_delimiter",
      label: "Currency Decimal Delimiter*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "currency_decimal_delimiter",
      options: [
        { label: ".", value: "." },
        { label: ",", value: "," },
        { label: "'", value: "'" },
      ],
      placeholder: "Select Currency Decimal Delimiter",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Currency Decimal Delimiter is required",
        },
      ],
      description: "Symbol separating whole and fractional parts of currency.",
    },
    {
      id: "inputdateformat",
      label: "Dateformat",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "dateFormat",
      options: dateFormat,
      placeholder: "Enter dateformat",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Date Format is required",
        },
      ],
      description: "Format for displaying dates.",
    },
    {
      id: "inputcurrency_delimiter",
      label: "Currency Delimiter*",
      type: "select",
      addNewBtnStatus: false,
      col: 6,
      name: "currency_delimiter",
      options: [
        { label: ".", value: "." },
        { label: ",", value: "," },
        { label: "'", value: "'" },
      ],
      placeholder: "Select Currency Decimal Delimiter",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Currency Delimiter is required",
        },
      ],
      description: "Symbol separating digits in large numbers.",
    },
    {
      id: "inputTimezone",
      label: "Timezone*",
      type: "select",
      col: 6,
      name: "timezone",
      options: timeZoneData,
      placeholder: "Enter Timezone",
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Timezone is required",
        },
      ],
      description: "Geographical region or time zone.",
      addNewBtnStatus: false,
    },
    {
      id: "inputCountryId",
      label: "Country*",
      type: "select",
      col: 6,
      name: "country_id",
      placeholder: "Select a Country",
      searchField: "name",
      model: Country,
      addNewBtnStatus: false,
      validate: [
        {
          rule: (value: any) => !!value,
          message: "Country is required",
        },
      ],
      description: "Country where the organization operates.",
    },
  ];

  const navigate = useNavigate();
  const handleCustomSubmit = async (formData: any) => {
    setCustomSubmitting(true);
    const {
      company_name,
      country_id,
      currency_decimal_delimiter,
      currency_delimiter,
      currency_id,
      dateFormat,
      timezone,
    } = formData;
    const selectedCurrencyDecimalDelimiter = currency_decimal_delimiter.value;
    const selecteDcurrencyDelimiter = currency_delimiter.value;
    const neededData: any = {
      company_name: company_name,
      country_id: country_id.value,
      currency_decimal_delimiter: selectedCurrencyDecimalDelimiter,
      currency_delimiter: selecteDcurrencyDelimiter,
      currency_id: currency_id.value,
      dateformat: dateFormat.value,
      timezone: timezone.value,
    };
    await apiPut(`edit-organization`, neededData).then((res) => {
      try {
        if (res) {
          const updatedUser = {
            ...user,
            organization: {
              ...user.organization,
              ...res.data,
            },
          };
          setUser(updatedUser, true);
          navigate("/");
          setToast("success", `Profile Organization Updated`);
        }
      } catch (error: any) {
        if (error.response?.data?.errors) {
          setFormError(error.response.data.errors);
        } else if (typeof error.response?.data?.message === "object") {
          const dynamicErrors = Object.keys(error.response.data.message)
            .map((key) => error.response.data.message[key])
            .filter((value) => typeof value === "string");

          setFormError(dynamicErrors.join(", "));
        } else if (error.response?.data?.message) {
          setFormError(error.response.data.message);
        } else {
          setToast("error", "An error occurred while processing your request");
        }
      } finally {
        setCustomSubmitting(false);
      }
    });
  };

  const getTimezone = async () => {
    const timezone: any[] = [];
    const timezones = await apiGet(`timezones-list`);
    Object.keys(timezones?.timezones).map(
      (key) =>
        Object.keys(timezones?.timezones[key])?.forEach((keys) => {
          timezone.push({ label: keys, value: keys });
        }),
    );
    setTimeZoneData(timezone);
  };

  const getDateFormat = async () => {
    const format: any[] = [];
    const formats = await apiGet(`dateformat-list`);
    Object.keys(formats?.data).map((key) =>
      format.push({ label: formats?.data[key], value: key }),
    );
    setDateFormat(format);
    return format;
  };

  const getFormData = async () => {
    const item: any = await Organization.$query()
      .with(["country", "currency"])
      .get();
    return item[0].$attributes;
  };

  const fetchData = async () => {
    try {
      const itemData = await getFormData();
      if (!itemData.currency) {
        setWarning([
          {
            fieldName: "currency",
            message: "Ensure Currency are filled in for organization creation",
            type: "warning",
          },
        ]);
      }
      const dateFormat = await getDateFormat();
      let data = itemData;
      if (data["currency_decimal_delimiter"]) {
        data["currency_decimal_delimiter"] = {
          label: itemData.currency_decimal_delimiter,
          value: itemData.currency_decimal_delimiter,
        };
      }
      if (data["currency_delimiter"]) {
        data["currency_delimiter"] = {
          label: itemData.currency_delimiter,
          value: itemData.currency_delimiter,
        };
      }
      if (data["currency_id"]) {
        data["currency_id"] = {
          label: itemData.currency["code"],
          value: itemData.currency["id"],
        };
      }
      if (data["country_id"]) {
        data["country_id"] = {
          label: itemData.country["name"],
          value: itemData.country["id"],
        };
      }
      if (data["timezone"]) {
        data["timezone"] = {
          label: itemData.timezone,
          value: itemData.timezone,
        };
      }
      if (data["dateformat"]) {
        data["dateFormat"] = dateFormat?.filter(
          (e: any) => e.value === itemData.dateformat,
        )[0];
      }
      setFormData(data);
      setLoading(false);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  useEffect(() => {
    getDateFormat();
    getTimezone();
    fetchData();
  }, []); // eslint-disable-line

  const handleHelpButtonClick = () => {
    setIsShows(!isShows);
    const formBottom = document.getElementById("form-bottom");
    if (formBottom) {
      formBottom.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <div>
        <Alerts data={warning} alertsType="warning" />
        <span style={{ float: "inline-end" }}>
          <Button variant="outline-secondary" onClick={handleHelpButtonClick}>
            Need Help?
          </Button>
        </span>
        <AddUpdateForm
          formFields={formFields}
          model={Organization}
          title={ORGANIZATION_TITLE}
          customFormSubmit={handleCustomSubmit}
          dynamicFormData={formData}
          listRoute={ORGANIZATION_VIEW}
          dynamicFormError={formError}
          customSubmitting={customSubmitting}
          loadingStatus={loading}
        />
      </div>
      <div id="form-bottom">
        <FieldDescription
          title={ORGANIZATION_TITLE}
          formFields={formFields}
          isShows={isShows}
        />
      </div>
      <div className="card mt-4" style={{ marginBottom: "30px" }}>
        <div className="card-header d-flex">
          <h5
            style={{ cursor: "pointer", fontSize: "17px" }}
            className="mb-0 me-3 text-primary"
          >
            Activity Logs
          </h5>
        </div>

        <div
          className="card-body"
          style={{
            height: "auto",
            overflow: "auto",
            backgroundColor: "#e4e4e4",
          }}
        >
          <ActivityLogs auditable_type={"ORGANIZATIONS"} id={formData.id} />
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
