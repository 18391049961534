import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomFilter, { InputValues } from "../../../Components/CustomFilter";
import { StockInventoryItem } from "../StockInventory/List";
import { STOCK_INVENTORY_ID_VIEW } from "../../../Routes/Inventory/StockInventory";
import CustomButton from "../../../Components/Button/CustomButton";
import { TableColumn } from "react-data-table-component";
import { FilterOperator } from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
import { PRODUCT_LIST, PRODUCT_TITLE } from "../../../Routes/Inventory/Product";
import pluralize from "pluralize";
import { Col, Row } from "react-bootstrap";
import CustomDataTable from "../../../Components/CustomDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { setToast } from "../../../Util/Toast";
import { fetchData } from "../../../Util/OrionList";
import { StockInventory } from "../../../Model/Inventory/StockInventory";

const ProductInventoryHistoryList = ({ permissions }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  let { id }: any = useParams();
  const searchParams = new URLSearchParams(location.search);
  const [inputValues, setInputValues] = useState<InputValues>({
    products_id: { value: id },
  });
  const [data, setData] = useState<StockInventoryItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sortField, setSortField] = useState<string>("id");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [includeWith] = useState(["product", "batch", "store"]);
  const [limit, setLimit] = useState(
    Number(searchParams.get("perPage")) === 0
      ? 10
      : Number(searchParams.get("perPage")),
  );
  const [page, setPage] = useState(
    Number(searchParams.get("page")) === 0
      ? 1
      : Number(searchParams.get("page")),
  );
  const [totalRows, setTotalRows] = useState(0);
  useEffect(() => {
    setSortField("id");
    setSortOrder("desc");
  }, []);
  useEffect(() => {
    if (id) {
      fetchProductInventoryHistory();
    }
  }, [id, limit, page]); // eslint-disable-line

  const handleRowViewButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    document_number: string,
  ) => {
    e.preventDefault();
    window.open(
      STOCK_INVENTORY_ID_VIEW.replace(
        ":document_number",
        document_number.toString(),
      ),
      "_blank",
    );
  };

  const fetchProductInventoryHistory = async () => {
    setLoading(true);
    try {
      setLoading(true);
      await fetchData(
        page,
        limit,
        inputValues,
        setLoading,
        setData,
        setTotalRows,
        navigate,
        new StockInventory(),
        sortField,
        sortOrder,
        false,
        includeWith,
      );
      setLimit(limit);
      setLoading(false);
    } catch (error: any) {
      setToast("error", error.response.data.message);
    }
  };

  const handlePerRowsChange = async (rowLimit: any, page: any) => {
    if (limit === rowLimit) {
      setLimit(rowLimit);
      setPage(page);
    } else {
      setLimit(rowLimit);
      setPage(1);
    }
  };
  const handlePageChange = (page: any) => {
    setPage(page);
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Document Number",
      cell: (row) => (
        <CustomButton
          label={
            <>
              {row.document_number}{" "}
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </>
          }
          variant="link"
          customeClass="text-decoration-none p-0 m-0"
          onClick={(e) =>
            handleRowViewButtonClick(e, row?.document_number?.toString())
          }
        />
      ),
    },
    {
      name: "Document Type",
      cell: (row) => <span> {row.document_type}</span>,
    },
    {
      name: "Product",
      cell: (row) => row.product?.product_name,
    },
    {
      name: "Product Id",
      cell: (row) => row.product?.product_id,
    },
    {
      name: "Batch Number",
      cell: (row) => row.batch?.batch_number,
    },
    {
      name: "Previous Quantity",
      cell: (row) => row.old_quantity_by_product,
    },
    {
      name: "Change Quantity",
      cell: (row: any) => (
        <span
          style={{ fontWeight: "500" }}
          className={
            row.change_quantity_by_product < 0 ? "text-danger" : "text-success"
          }
        >
          {row.change_quantity_by_product}
        </span>
      ),
    },
    {
      name: "New Quantity",
      cell: (row) => row.new_quantity_by_product,
    },
    {
      name: "Store",
      cell: (row) => row.store?.name,
    },
    {
      name: "Created At",
      cell: (row: any) => row.created_at,
    },
  ];

  const filterOptions: any = [
    {
      id: "document_number",
      value: "document_number",
      label: "Document Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "document_type",
      value: "document_type",
      label: "Document Type",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "product.product_name",
      value: "product.product_name",
      label: "Product Name",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "batch.batch_number",
      value: "batch.batch_number",
      label: "Batch Number",
      fieldType: "text",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
        Contains: FilterOperator.Like,
        NotContain: FilterOperator.NotLike,
      },
    },
    {
      id: "created_at",
      value: "created_at",
      label: "Created Date",
      fieldType: "date",
      allowedFilter: {
        Include: FilterOperator.Equal,
        NotInclude: FilterOperator.NotEqual,
      },
    },
  ];

  const handleGoBack = () => {
    navigate(PRODUCT_LIST);
  };

  return (
    <>
      <div className="mb-4">
        <div className="mb-2">
          <h2>{pluralize(PRODUCT_TITLE)} Inventory History</h2>
        </div>
        <Row>
          <CustomFilter
            options={filterOptions}
            inputValues={inputValues}
            inputInitialValues={id && { products_id: { value: id } }}
            setInputValues={setInputValues}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            getData={fetchProductInventoryHistory}
            setPage={setPage}
          />
          <Col className="text-xxl-end text-xl-end text-lg-end text-md-start text-sm-start text-xs-start">
            <CustomButton
              label={`Back`}
              variant="secondary"
              onClick={handleGoBack}
            />
          </Col>
        </Row>
        <CustomDataTable
          columns={columns}
          data={data}
          progressPending={loading}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          handleRowCreateButtonClick={() => {}}
          currentPages={page}
          currentRowsPerPage={limit}
        />
      </div>
    </>
  );
};

export default ProductInventoryHistoryList;
